import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Card, CardBody, CardHeader, CardText, Button, Input, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
    getApprovalWorkflow,
    submitActivity,
    recallActivity,
    appJect,
    getActivityDetails,
    getApprovalWorkflowCloseout,
    recallCloseout,
    appJectCloseout,
    getCurrentUser,
} from '../utils/api';
import Loading from '../pages/loading';
import { FormField } from '../components/forms';

const Approval = (props) => {
    const {
        activityData,
        canSubmitApproval,
        refreshActivity,
        setShowRequiredOutline,
        setSaveActivity,
        setSaveActivityCallback,
        updateActivitiesShortStop,
        refreshTab,
        closeoutIssueId,
        isLoading,
        submitRefresh,
        setSubmitRefresh,
        closeoutData,
        updateCloseout,
        taskStatus,
    } = props;
    const [approvalWorkflow, setApprovalWorkflow] = useState(null);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [commentSubmitting, setCommentSubmitting] = useState(false);
    const [fieldsByKey, setFieldsByKey] = useState({});
    const [canSubmit, setCanSubmit] = useState(false);
    const [showRequiredWarning, setShowRequiredWarning] = useState(false);
    const [checkedCanSubmit, setCheckedCanSubmit] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [closeoutApprovalWorkflow, setCloseoutApprovalWorkflow] = useState(null);
    const toggle = () => setPopoverOpen(!popoverOpen);

    const updateActivites = async () => {
        const refreshedActivity = await getActivityDetails(activityData.id);
        refreshTab('activities');
        refreshTab('approvals');
        updateActivitiesShortStop(null, refreshedActivity);
    };

    const checkTeam = (name) => {
        if (
            name
                ?.toString()
                .toLowerCase()
                .includes('team')
        ) {
            return name;
        } else {
            return name + ' Team';
        }
    };

    useEffect(() => {
        if (isLoading) {
            setCommentSubmitting(true);
            setSubmitting(true);
        } else {
            setCommentSubmitting(false);
            setSubmitting(false);
        }
    }, [isLoading]);

    useEffect(() => {
        if (
            activityData &&
            activityData.details &&
            activityData.details.forms &&
            activityData.details.forms[0] &&
            Object.keys(fieldsByKey).length === 0
        ) {
            const initFieldsByKey = {};
            activityData.details.forms[0].sections.map((section) => {
                section.rows.map((row) => {
                    row.fields.map((field) => {
                        if (field.value) {
                            // initFieldsByKey[field.name] = field.value;
                        }
                        initFieldsByKey[field.name] = {
                            ...field,
                            displayLogicRules: (field.displayLogicRules || []).concat(section.displayLogicRules || []),
                        };

                        return null;
                    });

                    return null;
                });

                return null;
            });
            setFieldsByKey(initFieldsByKey);
        }
    });

    const checkCanSubmit = (passedData) => {
        let canUserSubmit = true;
        const data = passedData || fieldsByKey;
        Object.keys(data).map((key) => {
            if (canUserSubmit) {
                canUserSubmit = data[key].value !== null;
            }

            return null;
        });
        setCanSubmit(canUserSubmit);
    };

    const [l2, setL2] = useState(false);

    const workflowCallback = useCallback(
        async () => {
            let data;
            if (closeoutIssueId) {
                setL2(true);
                data = await getApprovalWorkflowCloseout(activityData.id);
                setL2(false);
            } else {
                data = await getApprovalWorkflow(activityData.id);
            }
            const initFieldsByKey = {};
            if (data && data.approvalPaths) {
                data.approvalPaths.map((path) => {
                    path.approvalStages.map((stage) => {
                        stage.fields.map((field) => {
                            if (field.value) {
                                // initFieldsByKey[field.name] = field.value;
                            }
                            initFieldsByKey[field.name] = field;

                            return null;
                        });

                        return null;
                    });

                    return null;
                });
            }
            setWidgetLoading(false);
            checkCanSubmit(initFieldsByKey);
            setFieldsByKey(initFieldsByKey);
            setApprovalWorkflow(data);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setApprovalWorkflow, checkCanSubmit, setCloseoutApprovalWorkflow]
    );

    useEffect(
        () => {
            setSaveActivityCallback(async () => {
                // finish the save here
                window.setTimeout(async () => {
                    const currentUser = await getCurrentUser();
                    if (closeoutIssueId) {
                        //await submitActivity(activityData.id, currentUser.id);
                        //await workflowCallback();
                        setSubmitting(true);
                        setSaveActivity(true);
                        setCheckedCanSubmit(true);
                        
                        return;
                    }
                    await submitActivity(activityData.id, currentUser.id);
                    await workflowCallback();
                    await refreshActivity();
                    setSubmitting(false);
                    setSaveActivity(false);
                    setShowRequiredOutline(false);
                    setShowRequiredWarning(false);
                    setCheckedCanSubmit(false);
                    refreshTab('activities');
                    refreshTab('approvals');
                }, 100);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [closeoutIssueId, activityData]
    );

    const activate = () => {
        // state changes for loading

        if (canSubmitApproval) {
             setSubmitting(true);
            window.setTimeout(async () => {
                setSaveActivity(true);
            }, 100);
        } else {
            setShowRequiredWarning(true);
            setCheckedCanSubmit(true);
            setShowRequiredOutline(true);
            window.setTimeout(() => {
                toast.dismiss();
                toast.error('Required form fields are missing.');
                const errors = document.getElementsByClassName('is-invalid');
                if (errors.length) {
                    errors[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 100);
        }
    };

    useEffect(() => {
        if (submitRefresh) {
            workflowCallback();
            setSubmitRefresh(false);
        }
    }, [submitRefresh]);

    useEffect(
        () => {
            workflowCallback();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activityData, closeoutIssueId, closeoutData]
    );

    useEffect(() => {
        if (!isLoading && closeoutIssueId) {
            setSubmitting(false);
            setSaveActivity(false);
        }
    }, [isLoading]);

    const onFormChange = (updatedField) => {
        setFieldsByKey((prev) => {
            const updatedFormValues = { ...prev };
            updatedFormValues[updatedField.name] = updatedField;

            return updatedFormValues;
        });
        setTimeout(() => checkCanSubmit(), 100);
    };

    const commentText = useRef(null);

    const appJectThis = (type) => {
        setCommentSubmitting(true);
        window.setTimeout(async () => {
            const fieldData = {};
            Object.keys(fieldsByKey).map((key) => {
                fieldData[`DE:${key}`] = fieldsByKey[key].value;

                return null;
            });
            if (closeoutIssueId) {
                setSubmitting(true);
                await appJectCloseout(closeoutIssueId, commentText.current.value.trim(), fieldData, type);
                await workflowCallback();
                setSubmitting(false);
                setCommentSubmitting(false);
                return;
            } else {
                await appJect(activityData.id, commentText.current.value.trim(), fieldData, type);
            }
            await workflowCallback();
            await refreshActivity();
            await updateActivites();
            setCommentSubmitting(false);
        }, 100);
    };

    const recall = async () => {
        setSubmitting(true);
        window.setTimeout(async () => {
            if (closeoutIssueId) {
                await recallCloseout(closeoutIssueId);
                await workflowCallback();
                await updateCloseout();
                setSubmitting(false);
            } else {
                await recallActivity(activityData.id);
                await workflowCallback();
                await refreshActivity();
                await updateActivites();
                setSubmitting(false);
            }
        }, 100);
    };

    return (
        <>
            {taskStatus !== 'IFV' && (
                <Card>
                    {activityData.statusLabel?.includes('Cancellation') ? (
                        <CardHeader className="bg-danger text-white">
                            <h5 className="mb-0">Warning - Cancellation</h5>
                        </CardHeader>
                    ) : null}

                    <CardBody>
                        <div
                            className="d-flex justify-content-center align-items-center mb-3 clickable no-outline"
                            onClick={() => {
                                if (closeoutApprovalWorkflow) {
                                    window.open(`${process.env.REACT_APP_BASE_URL}/issue/${closeoutIssueId}/approvals`);
                                    return;
                                }
                                window.open(activityData.details.taskApprovalsTabUrl);
                            }}
                            onKeyDown={() => {
                                if (closeoutApprovalWorkflow) {
                                    window.open(`${process.env.REACT_APP_BASE_URL}/issue/${closeoutIssueId}/approvals`);
                                    return;
                                }
                                window.open(activityData.details.taskApprovalsTabUrl);
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            <div className="w-100">
                                <h4 className="mb-0">Approval Workflow</h4>
                            </div>
                            <div className="hover-primary">
                                <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                            </div>
                        </div>
    {
        widgetLoading ? (
            <div style={{ height: '150px', position: 'relative' }}>
                <Loading />
            </div>
        ) : null
    }

    {
        approvalWorkflow && approvalWorkflow.approvalPaths && approvalWorkflow.approvalPaths.length && !closeoutApprovalWorkflow ? (
            <div className="workflow">
                {approvalWorkflow.approvalPaths.map((path) => (
                    <div className="approval-path" key={`path:${path.name}`}>
                        <h6 className="mt-3">{path.name}</h6>
                        {path.approvalStages.map((stage) => (
                            <div className="d-flex approval-stage flex-column" key={`stage:${stage.name}`}>
                                <div className="d-flex">
                                    <div>
                                        <div className={`rounded-circle approval-circle status-${stage.approvalStatus}`} />
                                    </div>
                                    <div className="text-secondary pl-2 w-100">
                                        <h6 className="mb-0 pb-2">{stage.name}</h6>
                                        {stage.approvalType === 'AM' ?
                                            stage.approvers.map((approver) => (
                                                <div className="d-flex">
                                                    <div>
                                                        <div className={`rounded-circle approval-circle-small status-${approver.status}`} />
                                                    </div>
                                                    <small className="text-secondary pl-1 w-100">{approver.name}<br></br></small></div>
                                            ))
                                            :
                                            (
                                                <div className="d-flex"> <small className="text-secondary pl-1 w-100">{stage.approvers.map((approver) => (approver.name)).join(', ')}<br></br></small></div>
                                            )}
                                    </div>
                                </div>
                                {stage.approvableByUser ? (
                                    <div className="mt-2" style={{ position: 'relative' }}>
                                        {commentSubmitting ? (
                                            <Loading
                                                style={{
                                                    position: 'absolute',
                                                    backgroundColor: 'white',
                                                    opacity: '0.9',
                                                    zIndex: '2',
                                                }}
                                            />
                                        ) : null}
                                        <form>
                                            {stage.fields.map((field) => (
                                                <FormField
                                                    field={field}
                                                    key={`${field.id}`}
                                                    onChange={onFormChange}
                                                    fieldsByKey={fieldsByKey}
                                                />
                                            ))}
                                        </form>
                                        <Input
                                            type="textarea"
                                            innerRef={commentText}
                                            placeholder="Add a comment..."
                                            className="mb-2"
                                        />
                                        <Button
                                            disabled={!canSubmit}
                                            size="sm"
                                            className="mr-2 approve-btn mb-3"
                                            onClick={() => appJectThis('approve')}
                                        >
                                            Approve
                                        </Button>
                                        <Button size="sm" className="mr-2 reject-btn mb-3" onClick={() => appJectThis('reject')}>
                                            Reject
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                ))}
                {approvalWorkflow.submittableByUser ? (
                    <>
                        <Button
                            disabled={showRequiredWarning && checkedCanSubmit && !canSubmitApproval}
                            onClick={() => {
                                if (
                                    fieldsByKey &&
                                    fieldsByKey['Is Change Request'] &&
                                    fieldsByKey['Is Change Request'].value === 'Yes'
                                ) {
                                    toggle();
                                } else {
                                    activate();
                                }
                            }}
                            color="primary"
                            outline
                            size="sm"
                            id="SubmitForApprovalButton"
                            className={`mt-3 mr-2`}
                        >
                            <span>Submit</span>
                        </Button>
                        {fieldsByKey && fieldsByKey['Is Change Request'] && fieldsByKey['Is Change Request'].value === 'Yes' ? (
                            <Popover placement="bottom" isOpen={popoverOpen} target="SubmitForApprovalButton" toggle={toggle}>
                                <PopoverHeader>Are you sure?</PopoverHeader>
                                <PopoverBody>
                                    Have you entered the re-forcasted values?
                                    <div className="text-center my-3">
                                        <Button
                                            className="mr-5"
                                            color="success"
                                            onClick={() => {
                                                toggle();
                                                window.setTimeout(activate, 500);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={['fa', 'check']} />
                                            <span>Yes</span>
                                        </Button>
                                        <Button color="danger" onClick={toggle}>
                                            <FontAwesomeIcon icon={['fa', 'ban']} />
                                            <span>No</span>
                                        </Button>
                                    </div>
                                </PopoverBody>
                            </Popover>
                        ) : null}

                        {showRequiredWarning && !canSubmitApproval ? (
                            <CardText className="text-left text-danger mt-3">Required form fields are missing.</CardText>
                        ) : null}
                    </>
                ) : null}
                {approvalWorkflow.recallableByUser ? (
                    <Button onClick={recall} color="primary" outline size="sm" className={`mt-3`}>
                        <span>Recall</span>
                    </Button>
                ) : null}
            </div>
        ) : !!closeoutApprovalWorkflow ? (
            <div className="workflow">
                {closeoutApprovalWorkflow.approvalPaths.map((path) => (
                    <div className="approval-path" key={`path:${path.name}`}>
                        {/* <h6 className="mt-3">{path.name}</h6> */}
                        {path.approvalStages.map((stage) => (
                            <div className="d-flex approval-stage flex-column" key={`stage:${stage.name}`}>
                                <div className="d-flex">
                                    <div>
                                        <div className={`rounded-circle approval-circle status-${stage.approvalStatus}`} />
                                    </div>
                                    <div className="text-secondary pl-2 w-100">
                                     <h6 className="mb-0 ">{stage.name}</h6>
                                     {stage.approvalType === 'AM' ?
                                            stage.approvers.map((approver) => (
                                                <div className="d-flex">
                                                    <div>
                                                        <div className={`rounded-circle approval-circle-small status-${approver.status}`} />
                                                    </div>
                                                    <small className="text-secondary pl-1 w-100">{approver.name}<br></br></small></div>
                                            ))
                                            :
                                            (
                                                <div className="d-flex"> <small className="text-secondary pl-1 w-100">{stage.approvers.map((approver) => (approver.name)).join(', ')}<br></br></small></div>
                                            )}
                                    </div>
                                </div>
                                {stage.approvableByUser ? (
                                    <div className="mt-2" style={{ position: 'relative' }}>
                                        {commentSubmitting || l2 ? (
                                            <Loading
                                                style={{
                                                    position: 'absolute',
                                                    backgroundColor: 'white',
                                                    opacity: '0.9',
                                                    zIndex: '2',
                                                }}
                                            />
                                        ) : null}
                                        <form>
                                            {stage.fields.map((field) => (
                                                <FormField
                                                    field={field}
                                                    key={`${field.id}`}
                                                    onChange={onFormChange}
                                                    fieldsByKey={fieldsByKey}
                                                />
                                            ))}
                                        </form>
                                        <Input
                                            type="textarea"
                                            innerRef={commentText}
                                            placeholder="Add a comment..."
                                            className="mb-2"
                                        />
                                        <Button
                                            disabled={!canSubmit}
                                            size="sm"
                                            className="mr-2 approve-btn mb-3"
                                            onClick={() => appJectThis('approve')}
                                        >
                                            Approve
                                        </Button>
                                        <Button size="sm" className="mr-2 reject-btn mb-3" onClick={() => appJectThis('reject')}>
                                            Reject
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                ))}
                {closeoutApprovalWorkflow.submittableByUser && !l2 ? (
                    <>
                       <Button
                            disabled={showRequiredWarning && checkedCanSubmit && !canSubmitApproval}
                            onClick={() => {
                                if (
                                    fieldsByKey &&
                                    fieldsByKey['Is Change Request'] &&
                                    fieldsByKey['Is Change Request'].value === 'Yes'
                                ) {
                                    toggle();
                                } else {
                                    activate();
                                }
                            }}
                            color="primary"
                            outline
                            size="sm"
                            id="SubmitForApprovalButton"
                        > <span>Submit</span>
                        </Button>
                        {fieldsByKey && fieldsByKey['Is Change Request'] && fieldsByKey['Is Change Request'].value === 'Yes' ? (
                            <Popover placement="bottom" isOpen={popoverOpen} target="SubmitForApprovalButton" toggle={toggle}>
                                <PopoverHeader>Are you sure?</PopoverHeader>
                                <PopoverBody>
                                    Have you entered the re-forcasted values?
                                    <div className="text-center my-3">
                                        <Button
                                            className="mr-5"
                                            color="success"
                                            onClick={() => {
                                                toggle();
                                                window.setTimeout(activate, 500);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={['fa', 'check']} />
                                            <span>Yes</span>
                                        </Button>
                                        <Button color="danger" onClick={toggle}>
                                            <FontAwesomeIcon icon={['fa', 'ban']} />
                                            <span>No</span>
                                        </Button>
                                    </div>
                                </PopoverBody>
                            </Popover>
                        ) : null}
                        {showRequiredWarning && !canSubmitApproval ? (
                            <CardText className="text-left text-danger mt-3">Required form fields are missing.</CardText>
                        ) : null}
                    </>
                ) : null}
                {closeoutApprovalWorkflow.recallableByUser && !l2 ? (
                    <Button onClick={recall} color="primary" outline size="sm">
                        <span>Recall</span>
                    </Button>
                ) : null}
            </div>
        ) : null
    }
                    </CardBody >
                </Card >
            )}
        </>
    );
};

Approval.propTypes = {
    activityData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    canSubmitApproval: PropTypes.bool,
    refreshActivity: PropTypes.func,
    setShowRequiredOutline: PropTypes.func,
    setSaveActivity: PropTypes.func,
    setSaveActivityCallback: PropTypes.func,
    refreshTab: PropTypes.func,
    updateActivitiesShortStop: PropTypes.func.isRequired,
    closeoutIssueId: PropTypes.string,
    isLoading: PropTypes.bool,
    submitRefresh: PropTypes.bool,
    setSubmitRefresh: PropTypes.func,
    closeoutData: PropTypes.object,
    updateCloseout: PropTypes.func,
    setIsLoading: PropTypes.func,
    taskStatus: PropTypes.string,
};
Approval.defaultProps = {
    canSubmitApproval: false,
    refreshActivity: () => { },
    setShowRequiredOutline: () => { },
    setSaveActivity: () => { },
    refreshTab: () => { },
    setSaveActivityCallback: () => { },
};

export default Approval;