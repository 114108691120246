import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Form from '../components/forms/form';
import Loading from '../pages/loading';
import { getCampaignSubTab } from '../utils/api';
import { TaskApproval, TaskDocuments, CanUnlockButton, Comments } from '.';

const CampaignMenuItem = (props) => {
    const { campaign, fetchCampaigns, activityData, name, isEditable, refreshActivity, updateActivitiesShortStop, setActivityData } = props;

    const [loading, setLoading] = useState(true);
    const [subTab, setSubTab] = useState();

    const [showRequiredOutline, setShowRequiredOutline] = useState(false);
    const [canSubmitApproval, setCanSubmitApproval] = useState(true);
    const [saveActivityCallback, setSaveActivityCallback] = useState(() => {});
    const [saveActivity, setSaveActivity] = useState(false);

    const fetchSubTab = async () => {
        const response = await getCampaignSubTab(campaign.id, name);

        if (response) {
            setSubTab(response);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubTab();
    }, []);

    const setSaveActivityCallbackShortStop = (newFunc) => {
        setSaveActivityCallback(() => newFunc);
    };

    const setSaveActivityShortStop = (newBool) => {
        setSaveActivity(() => newBool);
    };

    return (
        <>
            {loading ? (
                <Row className="flex-grow-1" style={{ height: '400px', marginTop: '100px' }}>
                    <Col className="justify-content-center align-items-stretch flex-grow-1">
                        <Loading style={{ height: '400px', marginTop: '100px' }} height={' '} />
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col className="py-3">
                        {subTab && subTab.documents
                            ? subTab.documents.map((doc, index) =>
                                  doc.showInMainPanel ? (
                                      <Card key={`card:doc:${doc.name}:${index.toString()}`} style={{ backgroundColor: '#bdd5ec' }}>
                                          <CardBody className="text-center">
                                              <h3 className="mb-3">{doc.name}</h3>
                                              {doc.thumbnailUrl ? (
                                                  <img
                                                      src={doc.thumbnailUrl}
                                                      style={{ width: '100%', maxWidth: '150px' }}
                                                      className="mx-auto"
                                                      alt="Preview"
                                                      onError={(e) => {
                                                          e.target.style.display = 'none';
                                                      }}
                                                  />
                                              ) : null}
                                              {doc.downloadUrl ? (
                                                  <Button
                                                      className="d-flex align-items-center mx-auto my-4"
                                                      color="primary"
                                                      size="lg"
                                                      onClick={() => {
                                                          window.location.href = doc.downloadUrl;
                                                      }}
                                                  >
                                                      <FontAwesomeIcon icon={['fa', 'download']} size="xs" />
                                                      <span>Download</span>
                                                  </Button>
                                              ) : null}
                                              {doc.viewUrl ? (
                                                  <Button
                                                      className="p-0 d-flex align-items-center mx-auto"
                                                      style={{ color: '#7f7f7f' }}
                                                      size="lg"
                                                      color="link"
                                                      onClick={() => window.open(doc.viewUrl)}
                                                  >
                                                      <span>See in Workfront</span>
                                                      <FontAwesomeIcon icon={['fa', 'external-link-alt']} size="xs" />
                                                  </Button>
                                              ) : null}
                                          </CardBody>
                                      </Card>
                                  ) : null
                              )
                            : null}
                        {subTab && subTab.forms
                            ? subTab.forms.map((form, count) => (
                                  <Form
                                      key={form.name}
                                      form={form}
                                      activityId={activityData.id}
                                      campaignId={campaign.id}
                                      isEditable={isEditable}
                                      updateActivitiesShortStop={updateActivitiesShortStop}
                                      setActivityData={setActivityData}
                                      showRequiredOutline={showRequiredOutline}
                                      setCanSubmitApproval={setCanSubmitApproval}
                                      saveActivityCallback={saveActivityCallback}
                                      saveActivity={saveActivity}
                                      areIssuesEditable={false}
                                      travelPeriods={count === 0 ? subTab.travelPeriods : null}
                                      subActivities={count === 0 ? subTab.subActivities : null}
                                      marketBudgetAllocations={count === 0 ? subTab.marketBudgetAllocations : null}
                                      marketBudgetAllocationsForCampaign={count === 0 ? subTab.marketBudgetAllocationsForCampaign : null}
                                      embeddedContent={subTab.embeddedContent || []}
                                      subEmbeddedContentEditable={subTab.isEditable}
                                  />
                              ))
                            : null}
                    </Col>
                    <Col id="rightColumn" className="my-3" style={{ flex: '0 0 345px' }}>
                        {subTab && subTab.isLocked && subTab.name === 'Campaign Strategy' ? (
                            <>
                                <CanUnlockButton
                                    fetchSubTab={fetchSubTab}
                                    campaignId={campaign.id}
                                    fetchCampaigns={fetchCampaigns}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                                <div className="mt-4" />
                            </>
                        ) : null}

                        {subTab && subTab.primaryTaskID && subTab.hasApprovals ? (
                            <>
                                <TaskApproval
                                    campaignTabData={subTab}
                                    setShowRequiredOutline={setShowRequiredOutline}
                                    activityData={activityData}
                                    approvalTaskID={subTab.primaryTaskID}
                                    canSubmitApproval={canSubmitApproval}
                                    fetchSubTab={fetchSubTab}
                                    taskApprovalsTabUrl={subTab.taskApprovalsTabUrl}
                                    setCanSubmitApproval={setCanSubmitApproval}
                                    refreshActivity={refreshActivity}
                                    setSaveActivity={setSaveActivityShortStop}
                                    setSaveActivityCallback={setSaveActivityCallbackShortStop}
                                    updateActivitiesShortStop={updateActivitiesShortStop}
                                />
                                <div className="mt-4" />
                            </>
                        ) : null}

                        <Comments commentObj={subTab} commentTaskID={subTab.primaryTaskID} commentTaskLink={subTab.taskUpdatesTabUrl} />
                        <div className="mt-4" />

                        <TaskDocuments campaignTabData={subTab} fetchSubTab={fetchSubTab} />
                    </Col>
                </Row>
            )}
        </>
    );
};

CampaignMenuItem.propTypes = {
    isEditable: PropTypes.bool,
    campaign: PropTypes.objectOf(PropTypes.any).isRequired,
    activityData: PropTypes.objectOf(PropTypes.any).isRequired,
    name: PropTypes.string.isRequired,
    refreshActivity: PropTypes.func,
    updateActivitiesShortStop: PropTypes.func,
    setActivityData: PropTypes.func,
};

CampaignMenuItem.defaultProps = {
    isEditable: false,
    refreshActivity: () => {},
    updateActivitiesShortStop: () => {},
    setActivityData: () => {},
};

export default CampaignMenuItem;
