import React, { useContext, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import keyBy from 'lodash.keyby';
import PropTypes from 'prop-types';
import { Row, Col, Label, Input, Button, FormGroup } from 'reactstrap';
import { AppContext } from '../context';
import Loading from './loading';
import DataTable from '../components/Table';
import { searchActivities } from '../utils/api';

const Search = (props) => {
    const { marketPlanId } = props;
    const regions = ['Africa', 'Americas', 'Asia Pac', 'Europe', 'Proximity Markets'];
    const defaultFilters = {
        activityName: '',
        activityRegion: '',
        activitySourceMarket: '',
        activityFunctionalArea: '',
        activityType: '',
        activityStatus: '',
    };
    const [activitySelected, setActivitySelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activities, setActivites] = useState([]);
    const [filters, setFilters] = useState(defaultFilters);
    const appData = useContext(AppContext);

    const updateFilter = (name, value) => {
        setFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            newFilters[name] = value;
            if (name === 'activityFunctionalArea') {
                newFilters.activityType = '';
            }

            return newFilters;
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Activity Name',
                accessor: 'name',
            },
            {
                Header: 'Region',
                accessor: 'region',
            },
            {
                Header: 'Source Market',
                accessor: 'sourceMarket',
            },
            {
                Header: 'Functional Area',
                accessor: 'functionalArea',
            },
            {
                Header: 'Activity Type',
                accessor: 'activityType',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
        ],
        []
    );

    const goSearch = () => {
        setLoading(true);
        window.setTimeout(async () => {
            const searchParams = {
                marketPlanId,
                name: encodeURIComponent(filters.activityName),
                region: encodeURIComponent(filters.activityRegion),
                sourceMarket: encodeURIComponent(filters.activitySourceMarket),
                functionalArea: encodeURIComponent(filters.activityFunctionalArea),
                activityType: encodeURIComponent(filters.activityType),
                status: encodeURIComponent(filters.activityStatus),
            };
            const results = await searchActivities(searchParams);
            setActivites(results);
            setLoading(false);
        }, 100);
    };

    let tableData = [];
    if (activities.length) {
        tableData = activities.map((activity) => ({
            id: activity.id,
            name: activity.name,
            region: activity.regions.join(', '),
            sourceMarket: activity.sourceMarketLabel,
            functionalArea: activity.activityOwner,
            activityType: activity.activityType,
            status: activity.statusLabel,
        }));
    }

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'Find Activities',
                }}
            />
            <Row className="py-3 activity-form">
                <Col className="flex-nowrap d-flex flex-column">
                    <h5 className="mt-3 form-header">FILTERS</h5>
                    <FormGroup>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="activityName">Activity Name</Label>
                                    <Input
                                        type="text"
                                        name="activityName"
                                        id="activityName"
                                        placeholder="Search for name"
                                        onChange={(e) => updateFilter('activityName', e.target.value)}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }
                                        }}
                                        value={filters.activityName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="activityRegion">Region</Label>
                                    <Input
                                        type="select"
                                        name="activityRegion"
                                        id="activityRegion"
                                        onChange={(e) => updateFilter('activityRegion', e.target.value)}
                                        value={filters.activityRegion}
                                    >
                                        <option value=""> </option>
                                        {regions.map((region) => (
                                            <option key={region} value={region}>
                                                {region}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="activitySourceMarket">Source Market</Label>
                                    <Input
                                        type="select"
                                        name="activitySourceMarket"
                                        id="activitySourceMarket"
                                        onChange={(e) => updateFilter('activitySourceMarket', e.target.value)}
                                        value={filters.activitySourceMarket}
                                    >
                                        <option value=""> </option>
                                        {appData.sourceMarkets.map((sourceMarket) => (
                                            <option key={sourceMarket.name} value={sourceMarket.name}>
                                                {sourceMarket.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="activityFunctionalArea">Functional Area</Label>
                                    <Input
                                        type="select"
                                        name="activityFunctionalArea"
                                        id="activityFunctionalArea"
                                        onChange={(e) => updateFilter('activityFunctionalArea', e.target.value)}
                                        value={filters.activityFunctionalArea}
                                    >
                                        <option value=""> </option>
                                        {appData.functionalAreas.map((functionalArea) => (
                                            <option key={functionalArea.name} value={functionalArea.name}>
                                                {functionalArea.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="activityType">Activity Type</Label>
                                    <Input
                                        disabled={filters.activityFunctionalArea === ''}
                                        type="select"
                                        name="activityType"
                                        id="activityType"
                                        onChange={(e) => updateFilter('activityType', e.target.value)}
                                        value={filters.activityType}
                                    >
                                        <option value=""> </option>
                                        {filters.activityFunctionalArea !== ''
                                            ? keyBy(appData.functionalAreas, 'name')[filters.activityFunctionalArea].activityTypes.map(
                                                  (activityType) => (
                                                      <option key={activityType.name} value={activityType.name}>
                                                          {activityType.name}
                                                      </option>
                                                  )
                                              )
                                            : null}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="activityStatus">Status</Label>
                                    <Input
                                        type="select"
                                        name="activityStatus"
                                        id="activityStatus"
                                        onChange={(e) => updateFilter('activityStatus', e.target.value)}
                                        value={filters.activityStatus}
                                    >
                                        <option value=""> </option>
                                        {appData.statusList.map((filterStatus) => (
                                            <option key={filterStatus.id} value={filterStatus.id}>
                                                {filterStatus.label}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col />
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={goSearch}>Search Activities</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className="mt-3 form-header">ACTIVITIES</h5>
                    {!loading && activities.length === 0 ? <h5>No activites found.</h5> : null}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col sm={12} className="text-center" style={{ height: '500px' }}>
                    {loading ? <Loading style={{ position: 'absolute', zIndex: '1', opacity: '0.6', backgroundColor: 'white' }} /> : null}
                    {!loading && activities.length ? (
                        <DataTable
                            selectedActivityIds={[activitySelected]}
                            columns={columns}
                            data={tableData}
                            onToggle={(selectedId) => {
                                setActivitySelected(selectedId);
                            }}
                            canToggleAll={false}
                        />
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

Search.propTypes = {
    marketPlanId: PropTypes.string.isRequired,
};

export default Search;
