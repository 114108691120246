import Axios from 'axios';
import { toast } from 'react-toastify';
import store from 'store2';

// https://app.swaggerhub.com/apis/capabilitysource/dtcm/1.0.0

const apiUrl = process.env.REACT_APP_API_URL;
const username = process.env.REACT_APP_AUTH_USERNAME;
const password = process.env.REACT_APP_AUTH_PASSWORD;

Axios.interceptors.request.use(
    (config) => {
        const newConfig = { ...config };
        const token = store.session('X-WF-SESSION-ID');
        if (token && token.length) {
            newConfig.headers['X-WF-SESSION-ID'] = token;
        }

        return newConfig;
    },
    (error) => Promise.reject(error)
);

let attempts = 0;

const getErrorMessage = (passedError) => {
    let error = 'An error has occurred, please refresh the page and try again.';

    if (passedError) {
        if (passedError.response && passedError.response.data && passedError.response.data.errorMessage) {
            error = passedError.response.data.errorMessage;
        } else if (error.request) {
            // eslint-disable-next-line no-console
            console.error('Request error: ', error.request);
        } else if (passedError.message) {
            error = passedError.message;
        }
    }

    return error;
};

export async function getToken() {
    let token = store.session('X-WF-SESSION-ID');
    if (!token) {
        await Axios.post(`/attask/api/v9.0/login?username=${username}&password=${password}`, {
            username,
            password,
        })
            .then((response) => {
                token = response.data.data.sessionID;
                store.session('X-WF-SESSION-ID', token);
                return true;
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error('getToken', error);
                return false;
            });
    }

    return token;
}

export async function getAppData() {
    const appData = await Axios.get(`${apiUrl}/getAppData`)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getAppData', error);
            if (attempts < 2) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getAppData();
                }
            } else {
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '/error';
            }

            return false;
        });

    return appData;
}

export async function getCurrentUser() {
    //Added for client testing in production
    const appData = await Axios.get(`${apiUrl}/getCurrentUser`)
        .then((response) => response.data)
        .catch(async (error) => {
            // eslint-disable-next-line no-console
            console.error('getCurrentUser', error);
            if (attempts < 2) {
                attempts++;
                const result = await getToken();
                if (result) {
                    return getCurrentUser();
                }
            } else {
                toast.error(getErrorMessage(error), { autoClose: false });
                // window.location.href = '/error';
            }

            return false;
        });

    return appData;
}

export async function createCampaign(newCampaign) {
    let respondWith = null;
    await Axios.post(`${apiUrl}/createCampaign`, newCampaign)
        .then((response) => {
            toast.success('Campaign created.');
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('createCampaign', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function getMarketPlanDetails(marketPlanID, shouldFilter) {
    let marketPlan = {};
    await Axios.get(`${apiUrl}/getMarketPlanDetails`, {
        params: {
            id: marketPlanID,
            filterByManageAccess: shouldFilter || null,
        },
    })
        .then((response) => {
            marketPlan = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getMarketPlanDetails', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return marketPlan;
}

export async function getActivityDetails(activityId) {
    let activity = {};
    await Axios.get(`${apiUrl}/getActivityDetails`, {
        params: {
            id: activityId,
        },
    })
        .then((response) => {
            activity = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getActivityDetails', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            window.location.href = '/error';
        });

    return activity;
}

export async function getTaskMPIssue(taskId) {
    let task = {};
    await Axios.get(`${apiUrl}/getTaskMPIssue`, {
        params: {
            id: taskId,
        },
    })
        .then((response) => {
            task = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getTaskMPIssue', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            window.location.href = '/error';
        });

    return task;
}

export async function deleteActivity(activityId) {
    let activity = {};
    await Axios.put(`${apiUrl}/deleteActivity?id=${activityId}`, {})
        .then((response) => {
            activity = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('deleteActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            window.location.href = '/error';
        });

    return activity;
}

export async function cancelActivity(activityId, cancellationReason) {
    let activity = {};

    await Axios.put(`${apiUrl}/cancelActivity?id=${activityId}&reason=${cancellationReason}`, {})
        .then((response) => {
            activity = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('cancelActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            window.location.href = '/error';
        });

    return activity;
}

export async function getCloseoutForm(activityId) {
    let returnWith = {};
    await Axios.get(`${apiUrl}/getCloseoutForm`, {
        params: {
            id: activityId,
        },
    })
        .then((response) => {
            returnWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getCloseoutForm', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return returnWith;
}

export async function updateCloseoutForm(id, closeoutForm) {
    let respondWith = null;
    await Axios.put(`${apiUrl}/updateCloseoutForm?id=${id}`, closeoutForm)
        .then((response) => {
            toast.success('Closeout form updated.');
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('updateCloseoutForm', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function downloadActivities(activityIds) {
    // window.location.href = `${apiUrl}/downloadActivities?ids=${activityIds.join(',')}`;

    let download = null;
    await Axios.get(`${apiUrl}/downloadActivities`, {
        params: {
            ids: activityIds.join(','),
        },
        responseType: 'blob',
        timeout: 30000,
    })
        .then((response) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function() {
                const a = document.createElement('a');
                a.style = 'display: none';
                a.download = 'Activity.pdf';
                document.body.appendChild(a);
                const url = reader.result;
                a.href = url;
                a.click();
                window.URL.revokeObjectURL(url);
                download = true;
            };
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('downloadActivities', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return download;
}

export async function updateActivity(id, activity) {
    let respondWith = null;
    await Axios.put(`${apiUrl}/updateActivity?id=${id}`, activity)
        .then((response) => {
            toast.success('Activity updated.');
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('updateActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function updateCampaign(id, campaign) {
    let respondWith = null;
    await Axios.put(`${apiUrl}/updateCampaign?id=${id}`, campaign)
        .then((response) => {
            toast.success('Campaign updated.');
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('updateCampaign', error);
            toast.error(getErrorMessage(error), { autoClose: false });

            return false;
        });

    return respondWith;
}

export async function createActivity(newActivity) {
    let respondWith = null;
    await Axios.post(`${apiUrl}/createActivity`, newActivity)
        .then((response) => {
            toast.success('Activity created.');
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('createActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function createActivityVersion(activityId) {
    let respondWith = null;
    await Axios.post(`${apiUrl}/createActivityVersion?id=${activityId}`)
        .then((response) => {
            toast.success('New version created.');
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('createActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function copyActivities(marketPlanId, sourceMarkets, activities) {
    let respondWith = null;
    await Axios.post(`${apiUrl}/copyActivities`, {
        marketPlanId,
        sourceMarkets,
        activities,
    })
        .then((response) => {
            toast.success('Activities copied.');
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('copyActivities', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function createComment(activityId, noteText, parentNoteID, fromDecision) {
    let respondWith = null;
    await Axios.post(`${apiUrl}/createComment?id=${activityId}`, {
        noteText,
        parentNoteID,
        fromDecision,
    })
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('createComment', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function getComments(activityId) {
    let respondWith = [];
    await Axios.get(`${apiUrl}/getComments?id=${activityId}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getComments', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function getApprovalWorkflow(activityId) {
    let respondWith = [];

    await Axios.get(`${apiUrl}/getApprovalWorkflow?id=${activityId}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getApprovalWorkflow', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function getApprovalWorkflowCloseout(activityId) {
    let respondWith = [];

    await Axios.get(`${apiUrl}/getApprovalWorkflow?id=${activityId}&isCloseout=${true}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getApprovalWorkflow', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function getActivitiesAwaitingMyApproval(marketPlanId) {
    let respondWith = [];
    await Axios.get(`${apiUrl}/getActivitiesAwaitingMyApproval?portfolioID=${marketPlanId}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getActivitiesAwaitingMyApproval', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function submitActivity(activityId, userId) {
    //userId was added for client to test in production
    let respondWith = [];
    await Axios.put(`${apiUrl}/submitActivity?id=${activityId}&userId=${userId}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('submitActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function submitCloseout(activityId, userId) {
    let respondWith = [];
    await Axios.put(`${apiUrl}/submitActivity?id=${activityId}&isCloseout=${true}&userId=${userId}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('submitActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function submitTaskApproval(taskId) {
    let respondWith = [];
    await Axios.put(`${apiUrl}/submitTaskApproval?id=${taskId}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('submitTaskApproval', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function recallActivity(activityId) {
    let respondWith = [];
    await Axios.put(`${apiUrl}/recallActivity?id=${activityId}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('recallActivity', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function recallCloseout(closeoutId) {
    let respondWith = [];
    await Axios.put(`${apiUrl}/recallActivity?id=${closeoutId}&isCloseout=${true}`)
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('recallCloseout', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function appJect(activityId, comment, fieldValues, type) {
    let respondWith = [];
    await Axios.put(`${apiUrl}/${type}Activity?id=${activityId}`, {
        comment,
        fieldValues,
    })
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('appJect', error);
            toast.error(getErrorMessage(error), { autoClose: false });
        });

    return respondWith;
}

export async function appJectCloseout(activityId, comment, fieldValues, type) {
    let respondWith = [];
    await Axios.put(`${apiUrl}/${type}Activity?id=${activityId}&isCloseout=${true}`, {
        comment,
        fieldValues,
    })
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('appJect', error);
            toast.error(getErrorMessage(error), { autoClose: false });
        });

    return respondWith;
}

export async function createBudgetTransfer(budget) {
    let respondWith = false;
    await Axios.post(`${apiUrl}/createBudgetTransfer`, budget)
        .then(() => {
            respondWith = true;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('createBudgetTransfer', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';

            return false;
        });

    return respondWith;
}

export async function searchActivities(searchParams) {
    let respondWith = [];
    await Axios.get(`${apiUrl}/searchActivities`, {
        params: {
            ...searchParams,
        },
    })
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getActivitiesAwaitingMyApproval', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function getCampaigns(activityId) {
    let respondWith = [];
    await Axios.get(`${apiUrl}/getCampaigns`, {
        params: {
            id: activityId,
        },
    })
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getCampaigns', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function getCampaignSubTab(campaignId, subTabName) {
    let respondWith = false;
    await Axios.get(`${apiUrl}/getCampaignSubTab`, {
        params: {
            campaignId,
            subTabName,
        },
    })
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getCampaignSubTab', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function generateDocument(placeholderDocumentId, docName) {
    let download = false;
    await Axios.get(`${apiUrl}/generateDocument`, {
        params: {
            placeholderDocumentId,
        },
        responseType: 'blob',
        timeout: 30000,
    })
        .then((response) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(response.data);
            reader.onload = function() {
                const a = document.createElement('a');
                a.style = 'display: none';
                a.download = docName;
                document.body.appendChild(a);
                const url = reader.result;
                a.href = url;
                a.click();
                window.URL.revokeObjectURL(url);
                download = true;
            };
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('generateDocument', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return download;
}

export async function uploadDocument(file, primaryTaskID, documentId, onUploadProgress) {
    const respondWith = {
        error: false,
    };

    const formData = new FormData();
    formData.append('file', file);

    await Axios.post(`${apiUrl}/upload`, formData, {
        params: {
            taskId: primaryTaskID,
            documentId,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    })
        .then((response) => {
            respondWith.upload = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('generateDocument', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
            respondWith.error = true;
        });

    return respondWith;
}

export async function createAct(promotionId, axiosConfig) {
    return Axios.get(`${apiUrl}/image-promotions/${promotionId}`, axiosConfig);
}

export async function getRedirectUrl(taskId) {
    let respondWith = false;
    await Axios.get(`${apiUrl}/getRedirectUrl`, {
        params: {
            taskId,
        },
    })
        .then((response) => {
            respondWith = response.data;
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('getRedirectUrl', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            // window.location.href = '/error';
        });

    return respondWith;
}

export async function unlockCampaign(campaignId) {
    await Axios.put(`${apiUrl}/unlockCampaign?id=${campaignId}`, {})
        .then(() => {
            toast.success('Campaign unlocked.');
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('unlockCampaign', error);
            toast.error(getErrorMessage(error), { autoClose: false });
            window.location.href = '/error';
        });
}
