import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import { withCookies } from 'react-cookie';
import { Container, Row, Col } from 'reactstrap';
import queryString from 'query-string';
import store from 'store2';
import get from 'lodash.get';
import find from 'lodash.find';
import keyBy from 'lodash.keyby';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

/* Font Icons */
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPlus,
    faExternalLinkAlt,
    faDownload,
    faFilter,
    faChevronLeft,
    faChevronRight,
    faCheckSquare,
    faMinusSquare,
    faFile,
    faCaretRight,
    faCaretDown,
    faCaretUp,
    faDollarSign,
    faBars,
    faSearch,
    faCheck,
    faBan,
    faTrashAlt,
    faRedo,
    faFileUpload,
    faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCalendar, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { ActivityNew, Activity, PageNotFound, Search, CampaignNew } from './pages';
import Loading from './pages/loading';
import Error from './pages/error';
import { AppContext } from './context';
import { getToken, getAppData, getMarketPlanDetails, getActivityDetails, getActivitiesAwaitingMyApproval, getRedirectUrl } from './utils/api';
import { ScrollTop } from './utils';
import { Toolbar, ActivitiesList } from './layout';

library.add(
    faPlus,
    faExternalLinkAlt,
    faDownload,
    faFilter,
    faChevronLeft,
    faChevronRight,
    faCheckSquare,
    faSquare,
    faMinusSquare,
    faFile,
    faCaretRight,
    faCaretDown,
    faCaretUp,
    faCalendar,
    faTimesCircle,
    faDollarSign,
    faBars,
    faSearch,
    faCheck,
    faBan,
    faTrashAlt,
    faRedo,
    faFileUpload,
    faUpload
);

toast.configure();

const App = (props) => {
    const { location, history, cookies } = props;

    const pathParts = location.pathname?.split('/');
    const parseQS = queryString.parse(location.search);

    if (parseQS['X-WF-SESSION-ID']) {
        store.session('X-WF-SESSION-ID', parseQS['X-WF-SESSION-ID']);
    }

    useEffect(() => {
        const goToUrl = async () => {
            const goTo = await getRedirectUrl(parseQS['X-REDIRECT-ID']);
            if (goTo && goTo !== location.pathname) {
                history.replace(goTo);
            }
        };
        if (parseQS['X-REDIRECT-ID']) {
            goToUrl();
        }
    }, [parseQS['X-REDIRECT-ID']]);

    // entire page loading indicator
    const [pageLoading, setPageLoading] = useState(true);
    const [pageLoadingError, setPageLoadingError] = useState(false);
    const [authToken, setAuthToken] = useState(parseQS['X-WF-SESSION-ID'] || store.session('X-WF-SESSION-ID') || null);
    const [appData, setAppData] = useState(null);
    const [marketPlanLoading, setMarketPlanLoading] = useState(false);
    const [marketPlanId, setMarketPlanId] = useState(pathParts[2] || null);
    const [marketPlanData, setMarketPlanData] = useState(null);
    const [activityLoading, setActivityLoading] = useState(true);
    const [activityId, setActivityId] = useState(pathParts[4] || null);
    const [activityData, setActivityData] = useState(null);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [newActivities, setNewActivities] = useState([]);
    const [approvals, setApprovals] = useState([]);
    const [budgetTransfer, setBudgetTransfer] = useState(false);
    const [activitiesTab, setActivitiesTab] = useState('activities');
    const [activeTab, setActiveTab] = useState('details');

    const pageError = pathParts[1] === 'error';

    // look for URL changes
    if (marketPlanId !== pathParts[2]) {
        setMarketPlanId(pathParts[2]);
    }
    if (activityId !== pathParts[4]) {
        setActivityId(pathParts[4]);
    }

    // make sure we have a token
    useEffect(() => {
        const fetchToken = async () => {
            const token = await getToken();
            setAuthToken(token);
        };
        if (process.env.NODE_ENV !== 'production' && !authToken) {
            fetchToken();
        }
    }, [authToken]);

    useEffect(() => {
        const fetchApprovals = async () => {
            const fetchedApprovals = await getActivitiesAwaitingMyApproval(marketPlanId);
            setApprovals(fetchedApprovals);
        };
        if (marketPlanId && !pageError) {
            fetchApprovals();
        }
    }, [marketPlanId, pageError]);

    const refreshTab = async (tab) => {
        if (tab === 'activities') {
            const fetchedMarketPlan = await getMarketPlanDetails(marketPlanId);
            setMarketPlanData(fetchedMarketPlan);
        }
        if (tab === 'approvals') {
            const fetchedApprovals = await getActivitiesAwaitingMyApproval(marketPlanId);
            setApprovals(fetchedApprovals);
        }
    };

    useEffect(() => {
        if (!pageLoading) {
            const url = window.location != window.parent.location ? document.referrer : document.location.href;
            let taskId;
            if (url) {
                taskId = url.split('=')[1];
            } else {
                taskId = window.location.search?.split('=')[1];
            }
            if (taskId && taskId !== activityId) {
                const selected = [];
                selected.push(taskId);
                setSelectedActivities(selected);
                setActivityId(taskId);
                const link = `/marketplan/${marketPlanId}/activity/${taskId}`;
                props.history.push(link);
            }
        }
    }, [pageLoading]);

    const refreshActivity = async () => {
        const data = await getActivityDetails(activityId);
        setActivityData(data);
    };

    const removeActivity = (removeId) => {
        setNewActivities((prevActivites) => {
            const newRecent = prevActivites.filter((activity) => activity.id !== removeId);

            return newRecent;
        });
    };

    const updateActivitiesShortStop = (brandNewActivities, newActivityData) => {
        let copiedActivityData = newActivities.slice();

        if (brandNewActivities) {
            if (Array.isArray(brandNewActivities)) {
                copiedActivityData = copiedActivityData.concat(brandNewActivities);
            } else {
                copiedActivityData.push(brandNewActivities);
            }
            setNewActivities(copiedActivityData);
        }
        if (newActivityData) {
            // loop through all of the Ids and replace in NEW
            const newActivitiesData = copiedActivityData.map((newActivity) => {
                if (newActivity.id === newActivityData.id) {
                    return newActivityData;
                }

                return newActivity;
            });
            setNewActivities(newActivitiesData);
        }
        refreshTab('activities');
        refreshTab('approvals');
    };

    // load appData
    useEffect(() => {
        const fetchAppData = async () => {
            if (!appData) {
                const data = await getAppData();

                if (!data) {
                    setPageLoadingError(true);
                } else {
                    // there is no marketPlan or the marketPlan requested is not in the list of available marketPlans
                    const allMarketPlans = keyBy(data.marketPlans, 'id');
                    if (!parseQS['X-REDIRECT-ID'] && !allMarketPlans[marketPlanId] && data && data.defaultMarketPlanID) {
                        history.replace(`/marketplan/${data.defaultMarketPlanID}`);
                        setMarketPlanId(data.defaultMarketPlanID);
                    }
                }
                setAppData(data);
            }
            setPageLoading(false);
        };
        if (!pageError) {
            fetchAppData();
        }
    }, [appData, marketPlanId, history, pageError]);

    // load market plan details
    useEffect(() => {
        if (!marketPlanLoading && appData && marketPlanId && get(marketPlanData, 'id', {}) !== marketPlanId) {
            
            setMarketPlanLoading(true);
            setSelectedActivities([]);
            const fetchmarketPlanData = async () => {
                const data = await getMarketPlanDetails(marketPlanId);
                setMarketPlanData(data);
                // if an activityId was requested but it is NOT in the list of activities in the marketPlan
                if (activityId && activityId !== 'new' && activityId !== 'search' && get(data, 'activities')) {
                    const foundActivity = get(data, 'activities', []).find((obj) => obj.id === activityId);

                    if (!foundActivity && !parseQS['X-REDIRECT-ID']) {
                        history.replace(`/marketplan/${marketPlanId}`);
                    }
                }

                setMarketPlanLoading(false);
            };
            if (!pageError) {
                fetchmarketPlanData();
            }
        }
    }, [marketPlanId, activityId, marketPlanData, history, appData, pageError]);

    // load activity data
    useEffect(() => {
        if (
            appData &&
            activityId &&
            activityId !== 'new' &&
            activityId !== 'search' &&
            get(activityData, 'id', null) !== activityId &&
            marketPlanData &&
            marketPlanData.id === marketPlanId
        ) {
            setActivityLoading(true);
            const fetchActivityData = async () => {
                // check to see if we can get aditional details
                let activityFromPlan = find(marketPlanData.activities, (o) => o.id === activityId);
                if (!activityFromPlan) {
                    activityFromPlan = find(newActivities.activities, (o) => o.id === activityId);
                }

                if (!activityFromPlan) {
                    return;
                }

                let data = { ...activityFromPlan, details: {} };

                if (activityFromPlan.isViewable) {
                    data = await getActivityDetails(activityId);
                }

                setActivityData(data);
                // check if activity is in the current marketPlan? if not go to that marketPlan?

                if (marketPlanId && data.details && marketPlanId !== data.details.marketPlanId) {
                    // history.replace(`/marketplan/${marketPlanId}`);
                }

                setActivityLoading(false);
            };
            if (!pageError) {
                fetchActivityData();
            }
        }
    }, [appData, activityId, activityData, marketPlanId, history, pageError, marketPlanData, marketPlanLoading]);

    return (
        <ScrollTop>
            <Helmet
                htmlAttributes={{
                    'data-page': 'activity',
                    lang: 'en',
                }}
                defaultTitle="Capability Source"
                titleTemplate="%s - Capability Source"
            >
                <meta charSet="utf-8" />
            </Helmet>
            <AppContext.Provider value={appData}>
                <Container
                    id="main-container"
                    className="d-flex flex-grow-1 border flex-column position-fixed"
                    style={{ maxWidth: '100%', height: '100vh' }}
                >
                    <Row id="det-logo">
                        <Col>
                            <img src="/img/DET_Logo_1_2024.png" style={{ maxWidth: '200px' }} alt="DET" />
                        </Col>
                    </Row>
                    {pageError ? (
                        <Error />
                    ) : pageLoading ? (
                        <Row className="flex-grow-1">
                            <Col className="flex-column d-flex justify-content-center align-items-stretch">
                                <Loading />
                            </Col>
                        </Row>
                    ) : pageLoadingError ? (
                        <Error />
                    ) : (
                        <>
                            <Route
                                render={(toolbarProps) => (
                                    <Toolbar
                                        {...toolbarProps}
                                        cookies={toolbarProps.cookies}
                                        marketPlans={appData.marketPlans}
                                        marketPlanData={marketPlanData}
                                        marketPlanId={marketPlanId}
                                        activityId={activityId}
                                        activityData={activityData}
                                        setActivityLoading={setActivityLoading}
                                        loading={activityLoading}
                                        selectedActivities={selectedActivities}
                                        setSelectedActivities={setSelectedActivities}
                                        activitiesTab={activitiesTab}
                                        refreshTab={refreshTab}
                                        activeTab={activeTab}
                                        externalLinks={appData.externalLinks}
                                        setBudgetTransfer={setBudgetTransfer}
                                        budgetTransfer={budgetTransfer}
                                        removeActivity={removeActivity}
                                    />
                                )}
                            />
                            {marketPlanLoading ? (
                                <Row className="flex-grow-1 border-top">
                                    <Col className="flex-column d-flex justify-content-center align-items-stretch">
                                        <Loading />
                                    </Col>
                                </Row>
                            ) : (
                                <Row
                                    id="scrollable-area"
                                    className="border-top position-relative h-100"
                                    style={{ overflowY: 'auto', overflowX: 'hidden' }}
                                >
                                    <Col
                                        className="border-right p-0 flex-column d-flex col-auto h-100"
                                        id="sideLists"
                                        style={{ flex: '0 0 335px', position: 'sticky', top: '0', overflow: 'hidden' }}
                                    >
                                        {marketPlanData ? (
                                            <ActivitiesList
                                                {...props}
                                                cookies={cookies}
                                                marketPlanId={marketPlanId}
                                                marketPlanData={marketPlanData}
                                                activityId={activityId}
                                                setActivityId={setActivityId}
                                                setSelectedActivities={setSelectedActivities}
                                                selectedActivities={selectedActivities}
                                                newActivities={newActivities}
                                                approvals={approvals}
                                                refreshTab={refreshTab}
                                                tab={activitiesTab}
                                            />
                                        ) : null}
                                    </Col>
                                    <Col className="flex-grow d-flex flex-column" style={{ backgroundColor: '#fff' }}>
                                        <Switch>
                                            <Route exact path="/loading" component={Loading} />
                                            <Route exact path="/error" component={Error} />
                                            <Route
                                                exact
                                                path="/marketplan/:marketPlanId/activity/search"
                                                render={(SearchNewProps) => <Search {...SearchNewProps} marketPlanId={marketPlanId} />}
                                            />
                                            <Route
                                                exact
                                                path="/marketplan/:marketPlanId/activity/new"
                                                render={(ActivityNewProps) => (
                                                    <ActivityNew
                                                        {...ActivityNewProps}
                                                        updateActivitiesShortStop={updateActivitiesShortStop}
                                                        cookies={ActivityNewProps.cookies}
                                                        appData={appData}
                                                        marketPlanId={marketPlanId}
                                                        setActivitiesTab={setActivitiesTab}
                                                        refreshTab={refreshTab}
                                                        selectedActivities={selectedActivities}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path="/marketplan/:marketPlanId/activity/:activityId/campaign/new"
                                                render={(CampaignNewProps) => (
                                                    <CampaignNew
                                                        {...CampaignNewProps}
                                                        cookies={CampaignNew.cookies}
                                                        appData={appData}
                                                        marketPlanId={marketPlanId}
                                                        activityId={activityId}
                                                        activityData={activityData}
                                                        setActivityData={setActivityData}
                                                        refreshActivity={refreshActivity}
                                                        setActivitiesTab={setActivitiesTab}
                                                    />
                                                )}
                                            />
                                            <Route exact path={['/', '/marketplan', '/marketplan/:marketPlanId']}>
                                                {!selectedActivities.length ? (
                                                    <Row className="flex-grow-1" style={{ marginTop: '100px' }}>
                                                        <Col className="justify-content-center align-items-stretch flex-grow-1">
                                                            <h5 className="mt-4 text-center">No activities selected</h5>
                                                        </Col>
                                                    </Row>
                                                ) : selectedActivities.length ? (
                                                    <Row className="flex-grow-1" style={{ marginTop: '100px' }}>
                                                        <Col className="justify-content-center align-items-stretch flex-grow-1">
                                                            <h5 className="mt-4 text-center">
                                                                You have{' '}
                                                                {`${selectedActivities.length} activit${selectedActivities.length > 1 ? 'ies' : 'y'}`}{' '}
                                                                selected
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                ) : null}
                                            </Route>
                                            <Route
                                                exact
                                                path={[
                                                    '/marketplan/:marketPlanId/activity/:activityId',
                                                    '/marketplan/:marketPlanId/activity/:activityId/:campaignId',
                                                    '/marketplan/:marketPlanId/activity/:activityId/:campaignId/:subTab',
                                                ]}
                                                render={(ActivityProps) => (
                                                    <Activity
                                                        {...ActivityProps}
                                                        selectedActivities={selectedActivities}
                                                        setActivityLoading={setActivityLoading}
                                                        removeActivity={removeActivity}
                                                        cookies={ActivityProps.cookies}
                                                        loading={activityLoading}
                                                        marketPlanId={marketPlanId}
                                                        marketPlanData={marketPlanData}
                                                        activityId={activityId}
                                                        activityData={activityData}
                                                        setActivityData={setActivityData}
                                                        budgetTransfer={budgetTransfer}
                                                        setBudgetTransfer={setBudgetTransfer}
                                                        refreshActivity={refreshActivity}
                                                        updateActivitiesShortStop={updateActivitiesShortStop}
                                                        refreshTab={refreshTab}
                                                        activeTab={activeTab}
                                                        setActiveTab={setActiveTab}
                                                        saveActivity
                                                    />
                                                )}
                                            />
                                            <Route component={PageNotFound} />
                                        </Switch>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </Container>
            </AppContext.Provider>
        </ScrollTop>
    );
};

App.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    cookies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

export default withCookies(withRouter(App));
