import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import CampaignNav from './campaignNav';

const Campaign = (props) => {
    const { campaign, fetchCampaigns, refreshTab, activityData, updateActivitiesShortStop, setActivityData, history, match } = props;

    return (
        <CampaignNav
            campaign={campaign}
            activityData={activityData}
            updateActivitiesShortStop={updateActivitiesShortStop}
            setActivityData={setActivityData}
            history={history}
            fetchCampaigns={fetchCampaigns}
            refreshTab={refreshTab}
            match={match}
        />
    );
};

Campaign.propTypes = {
    campaign: PropTypes.objectOf(PropTypes.any).isRequired,
    activityData: PropTypes.objectOf(PropTypes.any).isRequired,
    updateActivitiesShortStop: PropTypes.func.isRequired,
    setActivityData: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};

export default Campaign;
