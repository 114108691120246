import React, { useState, useContext, useMemo } from 'react';
import Helmet from 'react-helmet';
import {
    Row,
    Col,
    Card,
    CardText,
    CardBody,
    Button,
    UncontrolledButtonDropdown,
    FormGroup,
    Label,
    Input,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import find from 'lodash.find';
import PropTypes from 'prop-types';
import { AppContext } from '../context';
import { getMarketPlanDetails, createActivity, copyActivities } from '../utils/api';
import DataTable from '../components/Table';
import Loading from './loading';

const NewActivity = (props) => {
    const { marketPlanId, updateActivitiesShortStop, history, setActivitiesTab } = props;
    const [step, setStep] = useState('start');
    const [newType, setNewType] = useState(null);
    const appData = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [sortedColumn, setSortedColumn] = useState({});

    const defaultData = {
        single: {
            values: {
                sourceMarkets: [],
                functionalArea: null,
                activityType: null,
                activity: {
                    name: '',
                    objective: '',
                },
            },
            stepOrder: ['sourceMarkets', 'functionalAreas', 'activityTypes', 'activityDetails'],
        },
        multiple: {
            values: {
                sourceMarkets: [],
                marketPlan: null,
                selectedActivityIds: [],
                newActivities: [],
            },
            stepOrder: ['pickActivities', 'sourceMarkets', 'newActivities'],
        },
    };
    const [data, setData] = useState(defaultData);

    const createSingle = async () => {
        const newActivity = {
            name: data.single.values.activity.name,
            marketPlanId,
            formFieldValues: {
                'DE:Activity Objective_': data.single.values.activity.objective,
            },
            sourceMarkets: data.single.values.sourceMarkets,
            functionalArea: data.single.values.functionalArea,
            activityType: data.single.values.activityType,
        };

        setLoading(true);
        const createdActivity = await createActivity(newActivity);

        if (createdActivity) {
            updateActivitiesShortStop(createdActivity);
            history.push(`/marketPlan/${marketPlanId}/activity/${createdActivity.id}`);
            setActivitiesTab('new');
        }

        setLoading(false);
    };

    const createMultiple = async () => {
        const { sourceMarkets, marketPlan, newActivities } = data.multiple.values;

        setLoading(true);
        const newCopiedActivities = await copyActivities(marketPlan.id, sourceMarkets, newActivities);

        if (newCopiedActivities && newCopiedActivities[0]) {
            updateActivitiesShortStop(newCopiedActivities);
            history.push(`/marketPlan/${marketPlan.id}/activity/${newCopiedActivities[0].id}`);
            setActivitiesTab('new');
        }

        setLoading(false);
    };

    const shortStopSetSortedColumn = (column) => {
        const newSorted = {
            id: column.id,
            isSortedDesc:
                column.id === sortedColumn.id
                    ? sortedColumn.isSortedDesc === undefined
                        ? false
                        : sortedColumn.isSortedDesc === false
                        ? true
                        : undefined
                    : false,
        };

        setSortedColumn(newSorted);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Activity Name',
                accessor: 'name',
                isSorted: sortedColumn.id === 'name',
                isSortedDesc: sortedColumn.isSortedDesc,
            },
            {
                Header: 'Source Market',
                accessor: 'sourceMarketLabel',
                isSorted: sortedColumn.id === 'sourceMarketLabel',
                isSortedDesc: sortedColumn.isSortedDesc,
            },
            {
                Header: 'Functional Area',
                accessor: 'activityOwner',
                isSorted: sortedColumn.id === 'activityOwner',
                isSortedDesc: sortedColumn.isSortedDesc,
            },
            {
                Header: 'Activity Type',
                accessor: 'activityType',
                isSorted: sortedColumn.id === 'activityType',
                isSortedDesc: sortedColumn.isSortedDesc,
            },
        ],
        [sortedColumn]
    );

    const toggleSingleMarket = (marketName) => {
        const { sourceMarkets } = data.single.values;

        if (sourceMarkets.indexOf(marketName) === -1) {
            sourceMarkets.push(marketName);
        } else {
            sourceMarkets.splice(sourceMarkets.indexOf(marketName), 1);
        }
        setData((prev) => {
            const newData = { ...prev };
            newData.single.values.sourceMarkets = sourceMarkets;

            return newData;
        });
    };

    const toggleMultipleMarket = (marketName) => {
        const { sourceMarkets } = data.multiple.values;

        if (sourceMarkets.indexOf(marketName) === -1) {
            sourceMarkets.push(marketName);
        } else {
            sourceMarkets.splice(sourceMarkets.indexOf(marketName), 1);
        }
        setData((prev) => {
            const newData = { ...prev };
            newData.multiple.values.sourceMarkets = sourceMarkets;

            return newData;
        });
    };

    let backButton = null;
    let nextButton = null;

    if (newType === 'single') {
        switch (step) {
            case 'sourceMarkets':
                backButton = {
                    label: 'New Activity',
                    onClick: () => {
                        setData((prev) => {
                            const newData = { ...prev };
                            newData.single.values.sourceMarkets = defaultData.single.values.sourceMarkets;

                            return newData;
                        });
                        setStep('start');
                        setNewType(null);
                    },
                };
                nextButton = {
                    label: 'Next',
                    disabled: !data.single.values.sourceMarkets.length,
                    onClick: () => setStep('functionalAreas'),
                    hasNext: true,
                };
                break;
            case 'functionalAreas':
                backButton = {
                    label: 'Source Markets',
                    onClick: () => {
                        setData((prev) => {
                            const newData = { ...prev };
                            newData.single.values.functionalArea = defaultData.single.values.functionalArea;

                            return newData;
                        });
                        setStep('sourceMarkets');
                    },
                };
                nextButton = {
                    label: 'Next',
                    disabled: !data.single.values.functionalArea,
                    onClick: () => setStep('activityTypes'),
                    hasNext: true,
                };
                break;
            case 'activityTypes':
                backButton = {
                    label: 'Functional Areas',
                    onClick: () => {
                        setData((prev) => {
                            const newData = { ...prev };
                            newData.single.values.activityType = defaultData.single.values.activityType;

                            return newData;
                        });
                        setStep('functionalAreas');
                    },
                };
                nextButton = {
                    label: 'Next',
                    disabled: !data.single.values.activityType,
                    onClick: () => setStep('activityDetails'),
                    hasNext: true,
                };
                break;
            case 'activityDetails':
                backButton = {
                    label: 'Activity Type',
                    onClick: () => {
                        setData((prev) => {
                            const newData = { ...prev };
                            newData.single.values.activity = defaultData.single.values.activity;

                            return newData;
                        });
                        setStep('activityTypes');
                    },
                };
                nextButton = {
                    label: 'Create',
                    disabled: data.single.values.activity.name.length === 0 || data.single.values.activity.objective.length === 0,
                    onClick: createSingle,
                };
                break;
            default:
        }
    } else {
        switch (step) {
            case 'pickActivities':
                backButton = {
                    label: 'New Activity',
                    onClick: () => {
                        setData((prev) => {
                            const newData = { ...prev };
                            newData.multiple.values.marketPlanId = defaultData.multiple.values.marketPlanId;
                            newData.multiple.values.marketPlan = defaultData.multiple.values.marketPlan;
                            newData.multiple.values.selectedActivityIds = defaultData.multiple.values.selectedActivityIds;
                            newData.multiple.values.newActivities = defaultData.multiple.values.newActivities;

                            return newData;
                        });
                        setStep('start');
                        setNewType(null);
                    },
                };
                nextButton = {
                    label: 'Next',
                    disabled: data.multiple.values.selectedActivityIds.length === 0,
                    onClick: () => {
                        const newActivities = [];
                        data.multiple.values.marketPlan.activities.map((activity) => {
                            if (data.multiple.values.selectedActivityIds.indexOf(activity.id) > -1) {
                                newActivities.push({
                                    id: activity.id,
                                    name: `Copy of ${activity.name}`,
                                    'DE:Activity Objective_': ``,
                                });
                            }

                            return null;
                        });
                        setData((prev) => {
                            const newData = { ...prev };
                            newData.multiple.values.newActivities = newActivities;

                            return newData;
                        });
                        setStep('sourceMarkets');
                    },
                    hasNext: true,
                };
                break;
            case 'sourceMarkets':
                backButton = {
                    label: 'Pick Activities',
                    onClick: () => {
                        setData((prev) => {
                            const newData = { ...prev };
                            newData.multiple.values.sourceMarkets = defaultData.multiple.values.sourceMarkets;
                            newData.multiple.values.newActivities = defaultData.multiple.values.newActivities;

                            return newData;
                        });
                        setStep('pickActivities');
                    },
                };
                nextButton = {
                    label: 'Next',
                    disabled: data.multiple.values.sourceMarkets.length === 0,
                    onClick: () => setStep('newActivities'),
                    hasNext: true,
                };
                break;
            case 'newActivities':
                backButton = {
                    label: 'Source Markets',
                    onClick: () => {
                        // setData((prev) => {
                        //     const newData = { ...prev };
                        //     newData.multiple.values.newActivities = defaultData.multiple.values.newActivities;
                        //
                        //     return newData;
                        // });
                        setStep('sourceMarkets');
                    },
                };
                nextButton = {
                    label: 'Create Copies',
                    onClick: createMultiple,
                };
                break;
            default:
        }
    }

    let tableData = (data.multiple.values.marketPlan && data.multiple.values.marketPlan.activities
        ? data.multiple.values.marketPlan.activities
        : []
    ).slice();

    if (newType === 'multiple' && step === 'pickActivities' && data.multiple.values.marketPlan && data.multiple.values.marketPlan.activities) {
        tableData = data.multiple.values.marketPlan.activities.map((activity) => {
            const alteredActivity = { ...activity };
            alteredActivity.isSelected = data.multiple.values.selectedActivityIds.indexOf(activity.id) > -1;

            return alteredActivity;
        });
    }

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'Select an Activity',
                }}
            />
            <div className="d-flex flex-column" style={{ position: 'relative', minHeight: '500px' }}>
                {loading ? <Loading style={{ position: 'absolute', zIndex: '1', opacity: '0.6', backgroundColor: 'white' }} /> : null}
                <Row
                    className="justify-content-center pt-2"
                    style={{
                        paddingBottom: '3.2rem',
                    }}
                >
                    <Col className="col-6 text-left">
                        {backButton ? (
                            <Button className="align-self-center border-0" color="secondary" outline onClick={backButton.onClick}>
                                <FontAwesomeIcon icon={['fa', 'chevron-left']} />
                                <span>{backButton.label}</span>
                            </Button>
                        ) : null}
                    </Col>
                    <Col className="col-6 text-right">
                        {nextButton ? (
                            <Button
                                className="align-self-center border-0"
                                color="primary"
                                onClick={nextButton.onClick}
                                disabled={nextButton.disabled}
                            >
                                <span>{nextButton.label}</span>
                                {nextButton.hasNext ? <FontAwesomeIcon icon={['fa', 'chevron-right']} /> : null}
                            </Button>
                        ) : null}
                    </Col>
                </Row>
                {step === 'start' ? (
                    <>
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <h2 className="mt-0">Create a New Activity</h2>
                                <p />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col sm={1} />
                            <Col sm={5}>
                                <Card
                                    className="text-center clickable"
                                    onClick={() => {
                                        setStep('sourceMarkets');
                                        setNewType('single');
                                    }}
                                >
                                    <div className="mx-auto mt-4 text-center">
                                        <FontAwesomeIcon icon={['fa', 'file']} size="4x" />
                                    </div>
                                    <CardBody>
                                        <h3>Create New</h3>
                                        <CardText>Create a single activity</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={5}>
                                <Card
                                    className="text-center clickable"
                                    onClick={() => {
                                        setStep('pickActivities');
                                        setNewType('multiple');
                                    }}
                                >
                                    <div className="mx-auto mt-4 text-center">
                                        <FontAwesomeIcon icon={['fa', 'file']} size="4x" className="d-inline" />
                                        <FontAwesomeIcon icon={['fa', 'chevron-right']} size="2x" className="d-inline align-text-bottom mx-3" />
                                        <FontAwesomeIcon icon={['fa', 'file']} size="4x" className="d-inline" />
                                    </div>
                                    <CardBody>
                                        <h3>Copy Existing</h3>
                                        <CardText>Create multiple Activities by searching for and making copies of past Activities</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={1} />
                        </Row>
                    </>
                ) : newType === 'single' ? (
                    step === 'sourceMarkets' ? (
                        <>
                            <Row className="justify-content-center">
                                <Col className="text-center">
                                    <h2 className="mt-0">Choose Source Markets</h2>
                                    <p>Pick one or more Source Markets</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={1} />
                                <Col sm={10} className="text-center">
                                    {appData.sourceMarkets.map((market) => (
                                        <Button
                                            style={{ width: 200 }}
                                            key={market.name}
                                            size="sm"
                                            value={market.name}
                                            disabled={!market.userCanCreateActivities}
                                            outline={data.single.values.sourceMarkets.indexOf(market.name) === -1}
                                            className={`toggle-button m-3 px-3 py-2 border-1 ${
                                                data.single.values.sourceMarkets.indexOf(market.name) !== -1 ? ' active' : ''
                                            }`}
                                            onClick={() => toggleSingleMarket(market.name)}
                                        >
                                            {market.name}
                                        </Button>
                                    ))}
                                </Col>
                                <Col sm={1} />
                            </Row>
                        </>
                    ) : step === 'functionalAreas' ? (
                        <>
                            <Row className="justify-content-center">
                                <Col className="text-center">
                                    <h2 className="mt-0">Choose a Functional Area</h2>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={1} />
                                <Col sm={10} className="text-center">
                                    {appData.functionalAreas.map((area) => (
                                        <Button
                                            style={{ width: 200 }}
                                            key={area.name}
                                            size="sm"
                                            value={area.name}
                                            outline={data.single.values.functionalArea !== area.name}
                                            disabled={!area.userCanCreateActivities}
                                            className={`toggle-button m-3 px-3 py-2 border-1 ${
                                                data.single.values.functionalArea === area.name ? ' active' : ''
                                            }`}
                                            onClick={() => {
                                                setData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.single.values.functionalArea = area.name;

                                                    return newData;
                                                });
                                            }}
                                        >
                                            {area.name}
                                        </Button>
                                    ))}
                                </Col>
                                <Col sm={1} />
                            </Row>
                        </>
                    ) : step === 'activityTypes' ? (
                        <>
                            <Row className="justify-content-center">
                                <Col className="text-center">
                                    <h2 className="mt-0">Choose an Activity Type</h2>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={1} />
                                <Col sm={10} className="text-center">
                                    {find(appData.functionalAreas, {
                                        name: data.single.values.functionalArea,
                                    }).activityTypes.map((activity) => (
                                        <Button
                                            style={{ width: 200 }}
                                            key={activity.name}
                                            size="sm"
                                            value={activity.name}
                                            outline={data.single.values.activityType !== activity.name}
                                            className={`toggle-button m-3 px-3 py-2 border-1 ${
                                                data.single.values.activityType === activity.name ? ' active' : ''
                                            }`}
                                            onClick={() => {
                                                setData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.single.values.activityType = activity.name;

                                                    return newData;
                                                });
                                            }}
                                        >
                                            {activity.name}
                                        </Button>
                                    ))}
                                </Col>
                                <Col sm={1} />
                            </Row>
                        </>
                    ) : step === 'activityDetails' ? (
                        <>
                            <Row className="justify-content-center">
                                <Col className="text-center">
                                    <h2 className="mt-0">Enter Activity Details</h2>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={1} />
                                <Col sm={10} className="text-left">
                                    <FormGroup>
                                        <Label for="ActivityName">Name</Label>
                                        <Input
                                            type="text"
                                            name="ActivityName"
                                            placeholder="Activity Name"
                                            defaultValue={data.single.values.activity.name || ''}
                                            onBlur={(e) => {
                                                const value = e.target.value.trim();
                                                setData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.single.values.activity.name = value;

                                                    return newData;
                                                });
                                            }}
                                            autoFocus
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="ActivityObjective">Activity Objective</Label>
                                        <Input
                                            type="textarea"
                                            name="ActivityObjective"
                                            defaultValue={data.single.values.activity.objective || ''}
                                            placeholder="Activity Objective"
                                            onBlur={(e) => {
                                                const value = e.target.value.trim();
                                                setData((prev) => {
                                                    const newData = { ...prev };
                                                    newData.single.values.activity.objective = value;

                                                    return newData;
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={1} />
                            </Row>
                        </>
                    ) : null
                ) : newType === 'multiple' ? (
                    step === 'pickActivities' ? (
                        <>
                            <Row className="justify-content-center">
                                <Col className="text-center">
                                    <h2 className="mt-0">Select Existing Activities</h2>
                                    <p>First, select a source marketing planning cycle.</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={1} />
                                <Col sm={10} className="text-center">
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color="secondary" outline className="border-0">
                                            {data.multiple.values.marketPlan
                                                ? data.multiple.values.marketPlan.name
                                                : 'Select a Market Plan to see Activities'}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {appData.marketPlans.map((marketPlan) => (
                                                <DropdownItem
                                                    key={`${marketPlan.id}`}
                                                    className={`list-group-item-action${
                                                        data.multiple.values.marketPlanId && data.multiple.values.marketPlanId === marketPlan.id
                                                            ? ' active'
                                                            : ''
                                                    }`}
                                                    onClick={async () => {
                                                        setLoading(true);
                                                        const result = await getMarketPlanDetails(marketPlan.id, 'yes');
                                                        setData((prev) => {
                                                            const newData = { ...prev };
                                                            newData.multiple.values.marketPlan = result;

                                                            return newData;
                                                        });
                                                        setLoading(false);
                                                    }}
                                                >
                                                    {marketPlan.name}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col sm={1} />
                            </Row>
                            <Row className="justify-content-center mt-5">
                                <Col sm={1} />
                                <Col sm={10} className="text-center">
                                    {data.multiple.values.marketPlan &&
                                    data.multiple.values.marketPlan.activities &&
                                    data.multiple.values.marketPlan.activities.length ? (
                                        <DataTable
                                            selectedActivityIds={data.multiple.values.selectedActivityIds}
                                            columns={columns}
                                            data={tableData}
                                            sortedColumn={sortedColumn}
                                            setSortedColumn={shortStopSetSortedColumn}
                                            onToggle={(selectedId, selected) => {
                                                setData((prev) => {
                                                    const newData = { ...prev };
                                                    if (selected) {
                                                        newData.multiple.values.selectedActivityIds.push(selectedId);
                                                    } else {
                                                        newData.multiple.values.selectedActivityIds.splice(
                                                            newData.multiple.values.selectedActivityIds.indexOf(selectedId),
                                                            1
                                                        );
                                                    }

                                                    return newData;
                                                });
                                            }}
                                            onToggleAll={() =>
                                                setData((prev) => {
                                                    const newData = { ...prev };
                                                    if (newData.multiple.values.selectedActivityIds.length > 0) {
                                                        newData.multiple.values.selectedActivityIds = [];
                                                    } else {
                                                        newData.multiple.values.selectedActivityIds = data.multiple.values.marketPlan.activities.map(
                                                            (activity) => activity.id
                                                        );
                                                    }

                                                    return newData;
                                                })
                                            }
                                            allToggleState={() => data.multiple.values.selectedActivityIds.length}
                                        />
                                    ) : data.multiple.values.marketPlan &&
                                      data.multiple.values.marketPlan.activities &&
                                      data.multiple.values.marketPlan.activities.length === 0 ? (
                                        <h5>No activites to select.</h5>
                                    ) : null}
                                </Col>
                                <Col sm={1} />
                            </Row>
                        </>
                    ) : step === 'sourceMarkets' ? (
                        <>
                            <Row className="justify-content-center">
                                <Col className="text-center">
                                    <h2 className="mt-0">Choose Source Markets</h2>
                                    <p>Pick one or more Source Markets for the copied activities.</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={1} />
                                <Col sm={10} className="text-center">
                                    {appData.sourceMarkets.map((market) => (
                                        <Button
                                            style={{ width: 200 }}
                                            key={market.name}
                                            size="sm"
                                            value={market.name}
                                            disabled={!market.userCanCreateActivities}
                                            outline={data.multiple.values.sourceMarkets.indexOf(market.name) === -1}
                                            className={`toggle-button m-3 px-3 py-2 border-1 ${
                                                data.multiple.values.sourceMarkets.indexOf(market.name) !== -1 ? ' active' : ''
                                            }`}
                                            onClick={() => toggleMultipleMarket(market.name)}
                                        >
                                            {market.name}
                                        </Button>
                                    ))}
                                </Col>
                                <Col sm={1} />
                            </Row>
                        </>
                    ) : step === 'newActivities' ? (
                        <>
                            <Row className="justify-content-center">
                                <Col className="text-center">
                                    <h2 className="mt-0">Update Details for Copied Activities</h2>
                                    <p>Rename and replace objectives for copied activities below.</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={1} />
                                <Col sm={10} className="text-center">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Objective</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.multiple.values.newActivities.map((activity, count) => (
                                                <tr key={activity.id}>
                                                    <td>
                                                        <Input
                                                            type="text"
                                                            name="ActivityName"
                                                            placeholder="Copied Activity Name"
                                                            defaultValue={activity.name}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                setData((prev) => {
                                                                    const newData = { ...prev };
                                                                    newData.multiple.values.newActivities[count].name = value;

                                                                    return newData;
                                                                });
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type="textarea"
                                                            name="ActivityObjective"
                                                            defaultValue={activity['DE:Activity Objective_']}
                                                            placeholder="Activity Objective"
                                                            onBlur={(e) => {
                                                                const value = e.target.value.trim();
                                                                setData((prev) => {
                                                                    const newData = { ...prev };
                                                                    newData.multiple.values.newActivities[count]['DE:Activity Objective_'] = value;

                                                                    return newData;
                                                                });
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col sm={1} />
                            </Row>
                        </>
                    ) : null
                ) : null}
            </div>
        </>
    );
};

NewActivity.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
    marketPlanId: PropTypes.string.isRequired,
    updateActivitiesShortStop: PropTypes.func.isRequired,
    setActivitiesTab: PropTypes.func,
};
NewActivity.defaultProps = {
    setActivitiesTab: () => {},
};

export default NewActivity;
