import React, { Fragment, useContext } from 'react';
import { Row, Col, Input, Button, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../context';

const FormKPIs = (props) => {
    const { kpis, onChange, title, isEditable } = props;
    const appData = useContext(AppContext);

    const addRow = () => {
        kpis.push({
            description: '',
            kpiMetric: '',
            actualKpi: 0,
            targetKpi: 0,
            kpiMetricVariance: 0,
            targetBudget: 0,
            actualBudget: 0,
            budgetVariance: 0,
            kpiWeightage: 0,
            kpiScore: 1,
            kpiWeightedAvgScore: 0,
        });
        onChange(kpis);
    };

    const removeRow = (count) => {
        kpis.splice(count, 1);
        onChange(kpis);
    };

    if (kpis.length === 0 && isEditable) {
        addRow();

        return null;
    }

    return (
        <Row id="kpis">
            <Col>
                <fieldset>
                    <h5 className="mt-3 mb-2 form-header">{title.toUpperCase()}</h5>
                    <FormGroup>
                        {kpis.map((kpi, count) => (
                            <Fragment key={`kpi:${kpi.id || kpi.renderId}:${count.toString()}`}>
                                <Row>
                                    <Col>
                                        <h6 className="text-secondary">KPI #{count + 1}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-3">
                                        <Row form className="mb-3">
                                            <Col>
                                                <Row className="mb-2">
                                                    <Col className="flex-grow-1 pr-0">
                                                        <Label className="mb-0 text-nowrap">Description</Label>
                                                        {isEditable ? (
                                                            <Input
                                                                type="text"
                                                                defaultValue={kpi.description}
                                                                onBlur={(e) => {
                                                                    const newKpis = [...kpis];
                                                                    const newKpi = { ...kpi };
                                                                    newKpi.description = e.target.value.trim();
                                                                    newKpis.splice(count, 1, newKpi);
                                                                    onChange(newKpis);
                                                                }}
                                                            />
                                                        ) : (
                                                            <div>{kpi.description}</div>
                                                        )}
                                                    </Col>
                                                    <Col className="flex-grow-1 pr-3">
                                                        <Row>
                                                            <Col className="pr-2">
                                                                <Label className="mb-0 text-nowrap">Metric</Label>
                                                                {isEditable ? (
                                                                    <Select
                                                                        onChange={(selected) => {
                                                                            const newKpis = [...kpis];
                                                                            const newKpi = { ...kpi };
                                                                            newKpi.kpiMetric = selected.value;
                                                                            newKpis.splice(count, 1, newKpi);
                                                                            onChange(newKpis);
                                                                        }}
                                                                        className="input-select d-flex"
                                                                        classNamePrefix="react-select"
                                                                        name={kpi.description}
                                                                        options={appData.kpiMetricOptions.map((option) => ({
                                                                            value: option,
                                                                            label: option,
                                                                        }))}
                                                                        value={{ value: kpi.kpiMetric, label: kpi.kpiMetric }}
                                                                    />
                                                                ) : (
                                                                    <div>{kpi.kpiMetric}</div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col className="flex-grow-1">
                                                        <Row>
                                                            <Col className="pr-0">
                                                                <Label className="mb-0 text-nowrap">Target KPI</Label>
                                                                {isEditable ? (
                                                                    <Input
                                                                        type="number"
                                                                        defaultValue={kpi.targetKpi}
                                                                        onBlur={(e) => {
                                                                            const newKpis = [...kpis];
                                                                            const newKpi = { ...kpi };
                                                                            newKpi.targetKpi = e.target.value.trim();
                                                                            newKpis.splice(count, 1, newKpi);
                                                                            onChange(newKpis);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div>{kpi.targetKpi}</div>
                                                                )}
                                                            </Col>
                                                            <Col className="pr-0">
                                                                <Label className="mb-0 text-nowrap">Actual KPI</Label>
                                                                {isEditable ? (
                                                                    <Input
                                                                        type="number"
                                                                        defaultValue={kpi.actualKpi}
                                                                        onBlur={(e) => {
                                                                            const newKpis = [...kpis];
                                                                            const newKpi = { ...kpi };
                                                                            newKpi.actualKpi = e.target.value.trim();
                                                                            newKpis.splice(count, 1, newKpi);
                                                                            onChange(newKpis);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div>{kpi.actualKpi}</div>
                                                                )}
                                                            </Col>
                                                            <Col className="pr-0 col-auto">
                                                                <Label className="mb-0 text-nowrap">Variance</Label>
                                                                <div className="mt-2">
                                                                    {kpi.actualKpi && kpi.targetKpi ? (kpi.actualKpi - kpi.targetKpi).toFixed(2) : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="flex-grow-1">
                                                        <Row>
                                                            <Col className="pr-0">
                                                                <Label className="mb-0 text-nowrap">Weight %</Label>
                                                                {isEditable ? (
                                                                    <Input
                                                                        type="number"
                                                                        defaultValue={kpi.kpiWeightage}
                                                                        onKeyUp={(e) => {
                                                                            let val = e.target.value.trim();
                                                                            val = !val ? 0 : parseFloat(val);
                                                                            if (val !== 0 && (val < 1 || val > 100)) {
                                                                                e.target.value = val < 1 ? 1 : 100;
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const newKpis = [...kpis];
                                                                            const newKpi = { ...kpi };
                                                                            newKpi.kpiWeightage = e.target.value.trim();
                                                                            newKpis.splice(count, 1, newKpi);
                                                                            onChange(newKpis);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div>{kpi.kpiWeightage}%</div>
                                                                )}
                                                            </Col>
                                                            <Col className="pr-0">
                                                                <Label className="mb-0 text-nowrap">Score</Label>
                                                                {isEditable ? (
                                                                    <Input
                                                                        type="number"
                                                                        defaultValue={kpi.kpiScore}
                                                                        onBlur={(e) => {
                                                                            const newKpis = [...kpis];
                                                                            const newKpi = { ...kpi };
                                                                            newKpi.kpiScore = Math.min(
                                                                                Math.max(parseInt(e.target.value.trim(), 10), 1),
                                                                                5
                                                                            );
                                                                            e.target.value = newKpi.kpiScore;
                                                                            newKpis.splice(count, 1, newKpi);
                                                                            onChange(newKpis);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div>{kpi.kpiScore}</div>
                                                                )}
                                                            </Col>
                                                            <Col className="pr-0">
                                                                <Label className="mb-0 text-nowrap">Weighted Avg Score</Label>
                                                                <div className="mt-2">
                                                                    {kpi.kpiScore && kpi.kpiWeightage
                                                                        ? ((kpi.kpiScore * kpi.kpiWeightage) / 100).toFixed(2)
                                                                        : 0}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {isEditable ? (
                                                <Col className="align-items-center d-flex flex-grow-0 pl-4 pr-0 flex-column">
                                                    <Row>
                                                        <Col>
                                                            <Label className="mb-0 invisible text-nowrap">R</Label>
                                                        </Col>
                                                    </Row>
                                                    <Row className="flex-grow-1">
                                                        <Col className="d-flex align-items-center">
                                                            <FontAwesomeIcon
                                                                icon={['far', 'times-circle']}
                                                                className="clickable hover-primary"
                                                                type="button"
                                                                onClick={() => removeRow(count)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </Col>
                                </Row>
                            </Fragment>
                        ))}
                        {kpis.length === 0 ? (
                            <div>
                                <em className="invisible">Empty</em>
                            </div>
                        ) : null}
                        {isEditable ? (
                            <Row>
                                <Col>
                                    <Button color="secondary" outline size="sm" onClick={addRow} className="mt-2">
                                        + Add
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                    </FormGroup>
                </fieldset>
            </Col>
        </Row>
    );
};

FormKPIs.propTypes = {
    onChange: PropTypes.func,
    kpis: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    isEditable: PropTypes.bool,
};
FormKPIs.defaultProps = {
    onChange: () => {},
    kpis: [],
    title: null,
    isEditable: true,
};

export default FormKPIs;
