import React, { Fragment, useContext } from 'react';
import { Row, Col, Input, Button, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';
import Select from 'react-select';
import keyBy from 'lodash.keyby';
import NumberFormat from 'react-number-format';
import { AppContext } from '../../context';

const Allocations = (props) => {
    const { allocations, onChange, title, isEditable } = props;
    const appData = useContext(AppContext);

    const addRow = () => {
        allocations.push({
            renderId: shortid.generate(),
            functionalAreas: [],
            marketingRegion: null,
            sourceMarkets: [],
            targetMarkets: [],
            estimatedBudgetAed: 0,
            marketAllocationBudgetPercent: '',
            notes: '',
        });
        onChange(allocations);
    };

    const removeRow = (count) => {
        allocations.splice(count, 1);
        onChange(allocations);
    };

    if (allocations.length === 0 && isEditable) {
        addRow();

        return null;
    }

    const createTargetMarketOptions = (smChoices) => {
        const sourceMarkets = keyBy(appData.sourceMarkets, 'name');
        const options = [];
        (smChoices || []).map((source) => {
            (sourceMarkets[source]?.targetMarkets || []).map((target) => {
                options.push({ label: target.name, value: target.name });

                return null;
            });

            return null;
        });

        return options;
    };

    const validateTargetMarkets = (tmValues, smChoices) => {
        const sourceMarkets = keyBy(appData.sourceMarkets, 'name');
        const values = [];
        (smChoices || []).map((option) => {
            (sourceMarkets[option].targetMarkets || []).map((targetMarket) => {
                (tmValues || []).map((value) => {
                    if (value === targetMarket.name) {
                        values.push(value);
                    }

                    return null;
                });

                return null;
            });

            return null;
        });

        return values;
    };

    return (
        <Row>
            <Col>
                <fieldset>
                    <h5 className="mt-3 mb-2 form-header">{title.toUpperCase()}</h5>
                    <FormGroup>
                        {allocations.map((allocation, count) => (
                            <Fragment key={`allocation:${allocation.id || allocation.renderId}:${count.toString()}`}>
                                <Row>
                                    <Col>
                                        <h6 className="text-secondary">Allocation #{count + 1}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-3">
                                        <Row form className="mb-3">
                                            <Col>
                                                <Row form className="mb-2 align-items-start justify-content-start">
                                                    <Col className="pr-0 d-flex flex-column">
                                                        <Label className="mb-0">Functional Areas</Label>
                                                        {isEditable ? (
                                                            <Select
                                                                onChange={(selections) => {
                                                                    const newAllocations = [...allocations];
                                                                    const newAllocation = { ...allocation };
                                                                    newAllocation.functionalAreas =
                                                                        selections && selections.length
                                                                            ? selections.map((selection) => selection.value)
                                                                            : [];
                                                                    newAllocations.splice(count, 1, newAllocation);
                                                                    onChange(newAllocations);
                                                                }}
                                                                className="input-select d-flex"
                                                                classNamePrefix="react-select"
                                                                isMulti
                                                                name="functionalAreas"
                                                                options={appData.functionalAreas.map((option) => ({
                                                                    value: option.name,
                                                                    label: option.name,
                                                                }))}
                                                                value={allocation.functionalAreas.map((value) => ({ value, label: value }))}
                                                            />
                                                        ) : (
                                                            <div>{allocation.functionalAreas.join(' ,')}</div>
                                                        )}
                                                    </Col>
                                                    <Col className="pr-0 d-flex flex-column">
                                                        <Label className="mb-0">Marketing Region</Label>
                                                        {isEditable ? (
                                                            <Select
                                                                onChange={(selected) => {
                                                                    const newAllocations = [...allocations];
                                                                    const newAllocation = { ...allocation };
                                                                    newAllocation.marketingRegion = selected.value;
                                                                    newAllocations.splice(count, 1, newAllocation);
                                                                    onChange(newAllocations);
                                                                }}
                                                                className="input-select d-flex"
                                                                classNamePrefix="react-select"
                                                                name="marketingRegion"
                                                                options={appData.regions.map((option) => ({
                                                                    value: option,
                                                                    label: option,
                                                                }))}
                                                                value={{
                                                                    value: allocations.marketingRegion,
                                                                    label: allocation.marketingRegion,
                                                                }}
                                                            />
                                                        ) : (
                                                            <div>{allocation.marketingRegion}</div>
                                                        )}
                                                    </Col>
                                                    <Col className="pr-0 d-flex flex-column">
                                                        <Label className="mb-0">Source Markets</Label>
                                                        {isEditable ? (
                                                            <Select
                                                                onChange={(selections) => {
                                                                    const newAllocations = [...allocations];
                                                                    const newAllocation = { ...allocation };
                                                                    const sourceMarketsValue =
                                                                        selections && selections.length
                                                                            ? selections.map((selection) => selection.value)
                                                                            : [];
                                                                    newAllocation.sourceMarkets = sourceMarketsValue;
                                                                    newAllocation.targetMarkets = validateTargetMarkets(
                                                                        allocation.targetMarkets,
                                                                        sourceMarketsValue
                                                                    );
                                                                    newAllocations.splice(count, 1, newAllocation);
                                                                    onChange(newAllocations);
                                                                }}
                                                                className="input-select d-flex"
                                                                classNamePrefix="react-select"
                                                                isMulti
                                                                name="sourceMarkets"
                                                                options={appData.sourceMarkets.map((option) => ({
                                                                    value: option.name,
                                                                    label: option.name,
                                                                }))}
                                                                value={allocation.sourceMarkets.map((value) => ({ value, label: value }))}
                                                            />
                                                        ) : (
                                                            <div>{allocation.functionalAreas.join(' ,')}</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col className="pr-0 d-flex flex-column">
                                                        <Label className="mb-0">Target Markets</Label>
                                                        {isEditable ? (
                                                            <Select
                                                                onChange={(selections) => {
                                                                    const newAllocations = [...allocations];
                                                                    const newAllocation = { ...allocation };
                                                                    newAllocation.targetMarkets =
                                                                        selections && selections.length
                                                                            ? selections.map((selection) => selection.value)
                                                                            : [];
                                                                    newAllocations.splice(count, 1, newAllocation);
                                                                    onChange(newAllocations);
                                                                }}
                                                                className="input-select d-flex"
                                                                classNamePrefix="react-select"
                                                                name="marketingRegion"
                                                                options={createTargetMarketOptions(allocation.sourceMarkets)}
                                                                isMulti
                                                                value={allocation.targetMarkets.map((value) => ({ value, label: value }))}
                                                            />
                                                        ) : (
                                                            <div>{allocation.targetMarkets.join(', ')}</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col className="pr-0 d-flex flex-column">
                                                        <Label className="mb-0">Estimated Budget AED Market</Label>
                                                        {isEditable ? (
                                                            <NumberFormat
                                                                style={{ width: 'auto' }}
                                                                name="estimatedBudgetAed"
                                                                onBlur={(e) => {
                                                                    const newAllocations = [...allocations];
                                                                    const newAllocation = { ...allocation };
                                                                    newAllocation.estimatedBudgetAed = e.target.value.trim();
                                                                    newAllocations.splice(count, 1, newAllocation);
                                                                    onChange(newAllocations);
                                                                }}
                                                                thousandSeparator
                                                                inputMode="numeric"
                                                                isNumericString
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                                value={allocation.estimatedBudgetAed}
                                                                defaultValue={allocation.estimatedBudgetAed}
                                                                className="form-control"
                                                            />
                                                        ) : (
                                                            <div>{allocation.estimatedBudgetAed}</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col className="pr-0">
                                                        {isEditable ? (
                                                            <Input
                                                                defaultValue={allocation.notes}
                                                                onBlur={(e) => {
                                                                    const newAllocations = [...allocations];
                                                                    const newAllocation = { ...allocation };
                                                                    newAllocation.notes = e.target.value.trim();
                                                                    newAllocations.splice(count, 1, newAllocation);
                                                                    onChange(newAllocations);
                                                                }}
                                                                type="TextArea"
                                                                placeholder="Add Note..."
                                                            />
                                                        ) : (
                                                            <p>{allocation.notes}</p>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {isEditable ? (
                                                <Col className="align-items-center d-flex flex-grow-0 pl-4 pr-0 flex-column">
                                                    <Row>
                                                        <Col>
                                                            <Label className="mb-2 invisible">R</Label>
                                                        </Col>
                                                    </Row>
                                                    <Row className="flex-grow-1">
                                                        <Col className="d-flex align-items-center">
                                                            <FontAwesomeIcon
                                                                icon={['far', 'times-circle']}
                                                                className="clickable hover-primary"
                                                                type="button"
                                                                onClick={() => removeRow(count)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </Col>
                                </Row>
                            </Fragment>
                        ))}
                        {allocations.length === 0 ? (
                            <div>
                                <em className="invisible">Empty</em>
                            </div>
                        ) : null}
                        {isEditable ? (
                            <Row>
                                <Col>
                                    <Button color="secondary" outline size="sm" onClick={addRow} className="mt-2">
                                        + Add
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                    </FormGroup>
                </fieldset>
            </Col>
        </Row>
    );
};

Allocations.propTypes = {
    onChange: PropTypes.func,
    allocations: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    isEditable: PropTypes.bool,
};
Allocations.defaultProps = {
    onChange: () => {},
    allocations: [],
    title: null,
    isEditable: true,
};

export default Allocations;
