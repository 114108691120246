import React from 'react';
import { Row, Col, Label, Input, FormGroup, FormText } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Linkify from 'linkifyjs/react';
import NumberFormat from 'react-number-format';
import DateTime from './datetime';

const FormField = (props) => {
    const { field, onChange, fieldsByKey, isEditable, showRequiredOutline, shouldDisplay } = props;
    const refTextArea = React.useRef();
    const [textAreaHeight, setTextAreaHeight] = React.useState();

    const refTextAreaCurrent = refTextArea.current;

    const resizeTextArea = () => {
        if (refTextAreaCurrent) {
            setTextAreaHeight(refTextAreaCurrent.scrollHeight);
        }
    };

    React.useEffect(() => {
        resizeTextArea();
    }, [refTextAreaCurrent]);

    const makeOptions = (options) => options.filter((option) => !option.isHidden).map((option) => ({ value: option.value, label: option.label }));

    const checkRequired = () => {
        let className = '';
        const { value, isDirty } = fieldsByKey[field.name] || { value: '' };
        if (field.isRequired && isDirty && !value && showRequiredOutline) {
            className = ' is-invalid';
        }

        return className;
    };

    const thisOnChange = (newValue) => {
        const updatedField = field;
        updatedField.value = newValue;
        updatedField.isDirty = true;
        setTimeout(() => onChange(updatedField), 0);
    };

    const convertSelects = (values) => {
        let returnValues = Array.isArray(values) ? values : typeof value === 'string' && values.indexOf(',') > -1 ? values.split(',') : [values];

        returnValues = returnValues.map((value) => {
            if (value && typeof value === 'object' && value.value) {
                return value.value;
            }

            return value;
        });

        if (returnValues && returnValues[0] === null) {
            returnValues = '';
        }

        return returnValues;
    };

    const makeSelects = (value, options) => {
        if (!value) {
            return;
        }

        let values = Array.isArray(value) ? value : typeof value === 'string' && value.indexOf(',') > -1 ? value.split(',') : [value];
        values = values.map((thisValue) => {
            let returnValue = thisValue;
            if (typeof thisValue === 'string') {
                returnValue = thisValue.trim();
                if (options) {
                    options.map((option) => {
                        if (returnValue === option.value) {
                            returnValue = {
                                value: option.value,
                                label: option.label,
                            };
                        }

                        return null;
                    });
                }
            } else if (thisValue && typeof thisValue === 'object' && thisValue.value) {
                returnValue = thisValue.value.trim();
            }

            return returnValue;
        });

        return values;
    };

    const fieldValue = fieldsByKey[field.name] ? fieldsByKey[field.name].value : '';

    return field.displayLogicRules.length === 0 || shouldDisplay(field.displayLogicRules, fieldsByKey) ? (
        <Col className="pr-0">
            <FormGroup>
                <Label className="mb-0">
                    {field.label || field.name}
                    {field.isRequired ? <span className="text-danger ml-1">*</span> : ''}
                </Label>
                {!isEditable || !field.isEditable || field.displayType === 'NMBR' ? (
                    <div>
                        <Linkify options={{ target: '_blank', nl2br: 'true' }}>{fieldValue}</Linkify>
                    </div>
                ) : !isEditable || !field.isEditable || field.displayType === 'CALC' ? (
                    <div>
                        <Linkify options={{ target: '_blank', nl2br: 'true' }}>
                            {fieldValue ? Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue : <em className="invisible">Empty</em>}
                        </Linkify>
                    </div>
                ) : field.dataType === 'DTTM' || field.dataType === 'DATE' ? (
                    <DateTime
                        data-form-id={`input:${field.id}`}
                        value={field.value}
                        onChange={thisOnChange}
                        isClearable={!field.isRequired}
                        className={`input-datetime ${checkRequired()}`}
                        dateFormat={field.dataType}
                    />
                ) : field.displayType === 'SLCT' || field.displayType === 'MULT' ? (
                    <Select
                        data-form-id={`input:${field.id}`}
                        onChange={(selected) => {
                            thisOnChange(convertSelects(selected));
                        }}
                        isClearable={!field.isRequired}
                        className={`input-select d-flex ${checkRequired()}`}
                        classNamePrefix="react-select"
                        name={field.name}
                        isMulti={field.displayType === 'MULT'}
                        options={makeOptions(field.options)}
                        value={makeSelects(fieldValue, field.options)}
                    />
                ) : field.displayType === 'CHCK' || field.displayType === 'RDIO' ? (
                    <Row className="mx-1">
                        {field.options.map((option) => (
                            <Col key={option.value} className="align-items-center d-flex">
                                <Input
                                    data-form-id={`input:${field.id}:${field.displayType === 'CHCK' ? option.label : field.name}`}
                                    className={`mr-2 ${checkRequired()}`}
                                    type={field.displayType === 'CHCK' ? 'checkbox' : 'radio'}
                                    name={field.displayType === 'CHCK' ? option.label : field.name}
                                    defaultValue={option.value}
                                    onChange={(e) => {
                                        const { target } = e;
                                        if (field.displayType === 'CHCK') {
                                            const existingValues = Array.isArray(field.value)
                                                ? field.value
                                                : typeof field.value === 'string'
                                                ? [field.value]
                                                : [];
                                            if (target.checked) {
                                                existingValues.push(target.value);
                                            } else {
                                                existingValues.splice(existingValues.indexOf(target.value), 1);
                                            }
                                            thisOnChange(existingValues);
                                        } else {
                                            thisOnChange(target.value);
                                        }
                                    }}
                                    checked={
                                        field.displayType === 'CHCK' ? (field.value || []).indexOf(option.value) > -1 : field.value === option.value
                                    }
                                />
                                <Label className={`mb-0 d-flex align-self-start ${checkRequired()}`}>{option.label}</Label>
                            </Col>
                        ))}
                    </Row>
                ) : field.dataType === 'CURC' ? (
                    <NumberFormat
                        data-form-id={`input:${field.id}`}
                        style={{ width: field.displaySize ? 'auto' : null }}
                        onBlur={(e) => thisOnChange(e.target.value)}
                        name={field.name}
                        thousandSeparator
                        inputMode="numeric"
                        isNumericString
                        decimalScale={2}
                        fixedDecimalScale
                        value={fieldValue}
                        defaultValue={fieldValue}
                        className={`form-control ${checkRequired()}`}
                    />
                ) : (
                    <Input
                        data-form-id={`input:${field.id}`}
                        style={{ width: field.displaySize ? 'auto' : null, ...(textAreaHeight ? { height: `${textAreaHeight}px` } : {}) }}
                        size={field.displayType !== 'TXTA' && field.displaySize ? field.displaySize.toString() : null}
                        cols={field.displayType === 'TXTA' && field.displaySize ? field.displaySize.toString() : null}
                        onBlur={(e) => thisOnChange(e.target.value)}
                        type={field.displayType === 'TXTA' ? 'textarea' : field.dataType === 'NMBR' ? 'number' : 'text'}
                        name={field.name}
                        defaultValue={fieldValue}
                        className={checkRequired()}
                        onChange={resizeTextArea}
                        innerRef={refTextArea}
                    />
                )}
            </FormGroup>
        </Col>
    ) : null;
};

FormField.propTypes = {
    field: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    onChange: PropTypes.func,
    shouldDisplay: PropTypes.func,
    fieldsByKey: PropTypes.objectOf(PropTypes.any),
    isEditable: PropTypes.bool,
    showRequiredOutline: PropTypes.bool,
};
FormField.defaultProps = {
    onChange: () => {},
    shouldDisplay: () => {},
    fieldsByKey: {},
    isEditable: true,
    showRequiredOutline: true,
};

export default FormField;
