import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem, Card, CardBody, Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { generateDocument, uploadDocument } from '../utils/api';

const TaskDocuments = (props) => {
    const { campaignTabData, fetchSubTab } = props;
    const [campaignDocuments, setCampaignDocuments] = useState([]);
    const [generatingDocument, setGeneratingDocument] = useState('');
    const [uploadFile, setUploadFile] = useState([]);
    const [replacingDocId, setReplacingDocId] = useState(null);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');

    useEffect(() => {
        let parsedDcouments = [];
        if (campaignTabData && campaignTabData.documents) {
            parsedDcouments = campaignTabData.documents.slice();
            parsedDcouments.reverse();
        }
        setCampaignDocuments(parsedDcouments);
    }, [campaignTabData]);

    const upload = () => {
        setProgress(0);

        uploadDocument(uploadFile[0], campaignTabData.primaryTaskID, replacingDocId, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((response) => {
                document.getElementById('campDocUpload').value = '';
                fetchSubTab();
                setProgress(0);
                setMessage(response.error ? 'Could not upload the file!' : '');
                setUploadFile([]);
                setReplacingDocId(null);
                fetchSubTab();
            })
            .catch(() => {
                document.getElementById('campDocUpload').value = '';
                fetchSubTab();
                setProgress(0);
                setMessage('Could not upload the file!');
                setUploadFile([]);
                setReplacingDocId(null);
            });

        // setUploadFile(undefined);
    };

    useEffect(() => {
        if (uploadFile && uploadFile.length) {
            setMessage('');
            window.setTimeout(() => {
                upload();
            }, 1);
        }
    }, [uploadFile]);

    return (
        <Card>
            <CardBody>
                <div
                    className="d-flex justify-content-center align-items-center mb-3 clickable no-outline"
                    onClick={() => window.open(campaignTabData.documentsTabUrl)}
                    onKeyDown={() => window.open(campaignTabData.documentsTabUrl)}
                    role="button"
                    tabIndex={0}
                >
                    <div className="w-100">
                        <h4 className="mb-0 d-inline-block mr-2">Documents</h4>
                        <Badge color="primary" className="align-text-top">
                            {campaignDocuments.length}
                        </Badge>
                    </div>
                    <div className="hover-primary">
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                    </div>
                </div>

                <ListGroup>
                    {campaignDocuments
                        ? campaignDocuments.map((doc) => (
                              <ListGroupItem key={`document:${doc.id}`} className="d-flex px-0 justify-content-center align-items-center document">
                                  <div className="d-flex justify-content-center mr-auto flex-fill">
                                      <div>
                                          <FontAwesomeIcon
                                              icon={['fa', 'file']}
                                              size="2x"
                                              className={`no-outline${doc.downloadUrl ? ' hover-primary clickable' : ' doc-icon'}`}
                                              onKeyDown={() => {
                                                  if (doc.downloadUrl) {
                                                      window.location.href = doc.downloadUrl;
                                                  }
                                              }}
                                              onClick={() => {
                                                  if (doc.downloadUrl) {
                                                      window.location.href = doc.downloadUrl;
                                                  }
                                              }}
                                          />
                                      </div>
                                      <div className="w-100 pl-2 flex-column" style={{ lineHeight: 1.25 }}>
                                          <Button
                                              className="p-0 mt-1 d-flex align-items-center text-dark text-left"
                                              size="sm"
                                              color="link"
                                              onClick={() => {
                                                  if (doc.downloadUrl) {
                                                      window.location.href = doc.downloadUrl;
                                                  }
                                              }}
                                              style={{
                                                  cursor: doc.downloadUrl ? 'pointer' : 'text',
                                                  textDecoration: doc.downloadUrl ? 'inherit' : 'none',
                                              }}
                                          >
                                              <span>
                                                  {doc.name}
                                                  {doc.isRequired ? <span className="text-danger ml-1">*</span> : ''}
                                              </span>
                                          </Button>
                                          {uploadFile.length === 0 || (uploadFile.length > 0 && replacingDocId !== doc.id) ? (
                                              <Button
                                                  className={`${
                                                      doc.containsUploadedFile ? 'p-0' : 'py-1 px-2'
                                                  } d-flex align-items-center mt-1 text-left`}
                                                  style={{ color: doc.containsUploadedFile ? '#7f7f7f' : 'inherit' }}
                                                  size="sm"
                                                  outline={!doc.containsUploadedFile}
                                                  color={doc.containsUploadedFile ? 'link' : 'primary'}
                                                  disabled={!!(uploadFile && uploadFile.length)}
                                                  onClick={() => {
                                                      setReplacingDocId(doc.id);
                                                      window.setTimeout(() => {
                                                          document.getElementById('campDocUpload').click();
                                                      }, 1);
                                                  }}
                                              >
                                                  <FontAwesomeIcon icon={['fa', 'upload']} size="xs" />
                                                  <span>Upload {doc.containsUploadedFile ? 'Version' : 'File'}</span>
                                              </Button>
                                          ) : null}
                                          {uploadFile && uploadFile.length && replacingDocId === doc.id ? (
                                              <div
                                                  className="progress-bar progress-bar-info progress-bar-striped my-3"
                                                  role="progressbar"
                                                  aria-valuenow={progress}
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                  style={{ width: `${progress}%` }}
                                              >
                                                  {progress}%
                                              </div>
                                          ) : null}
                                          {doc.containsUploadedFile && doc.viewUrl ? (
                                              <Button
                                                  className="p-0 mt-1 d-flex align-items-center text-left"
                                                  style={{ color: '#7f7f7f' }}
                                                  size="sm"
                                                  color="link"
                                                  onClick={() => window.open(doc.viewUrl)}
                                              >
                                                  <FontAwesomeIcon icon={['fa', 'external-link-alt']} size="xs" />
                                                  <span>See in Workfront</span>
                                              </Button>
                                          ) : null}
                                          {doc.canGenerateDocument ? (
                                              <Button
                                                  className={`text-left p-0 mt-1 d-flex align-items-center ${generatingDocument === doc.id &&
                                                      ' thinking'}`}
                                                  style={{ color: '#7f7f7f' }}
                                                  size="sm"
                                                  color="link"
                                                  onClick={() => {
                                                      setGeneratingDocument(doc.id);
                                                      window.setTimeout(async () => {
                                                          await generateDocument(doc.id, doc.name);
                                                          setGeneratingDocument('');
                                                      }, 1);
                                                  }}
                                              >
                                                  <FontAwesomeIcon icon={['fa', 'redo']} size="xs" />
                                                  <span>Generate Document</span>
                                              </Button>
                                          ) : null}
                                      </div>
                                  </div>
                              </ListGroupItem>
                          ))
                        : null}
                    {uploadFile && uploadFile.length && !replacingDocId ? (
                        <ListGroupItem className="d-flex px-0 align-items-center document">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <div className="mr-auto">
                                    <FontAwesomeIcon icon={['fa', 'file']} size="2x" />
                                </div>
                                <div className="w-100 pl-2" style={{ lineHeight: 1.25 }}>
                                    <small>{uploadFile[0].name}</small>
                                    <div className="progress mt-1">
                                        <div
                                            className="progress-bar progress-bar-info progress-bar-striped"
                                            role="progressbar"
                                            aria-valuenow={progress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            style={{ width: `${progress}%` }}
                                        >
                                            {progress}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListGroupItem>
                    ) : null}
                    <ListGroupItem className="px-0 document pb-0 flex-column">
                        {message && message.length ? <small className="text-danger mb-2">{message}</small> : null}
                        <Button
                            size="sm"
                            color="primary"
                            outline
                            onClick={() => {
                                setReplacingDocId(null);
                                document.getElementById('campDocUpload').click();
                            }}
                            disabled={!!(uploadFile && uploadFile.length)}
                        >
                            <FontAwesomeIcon icon={['fa', 'file-upload']} size="1x" className="mr-2" />
                            Add Document
                        </Button>
                        <input
                            type="file"
                            onChange={(e) => {
                                setUploadFile(e.target.files);
                            }}
                            id="campDocUpload"
                            className="d-none"
                        />
                    </ListGroupItem>
                </ListGroup>
            </CardBody>
        </Card>
    );
};

TaskDocuments.propTypes = {
    campaignTabData: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]).isRequired,
    fetchSubTab: PropTypes.func.isRequired,
};

export default TaskDocuments;
