import React, { useState, useEffect, useCallback } from 'react';
import Helmet from 'react-helmet';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getCloseoutForm, updateCloseoutForm, getTaskMPIssue, submitCloseout, getCurrentUser } from '../utils/api';
import Loading from './loading';
import Fieldset from '../components/forms/fieldSet';
import FormKPIs from '../components/forms/formKPIs';
import Approval from '../layout/approval';

const Closeout = (props) => {
    const { activityData, saveActivity, setSaveActivityCallback, taskStatus } = props;
    const [isLoading, setLoading] = useState(true);
    const [closeoutData, setCloseoutData] = useState(null);
    const [fieldsByKey, setFieldsByKey] = useState({});
    const [kpis, setKPIs] = useState([]);
    const [issueId, setIssueId] = useState('');
    const [submitRefresh, setSubmitRefresh] = useState(false);

    const fetchIssueId = async () => {
        const res = await getTaskMPIssue(activityData.id);
        console.log(`MP Task Issue ID: ${res.ID}`);
        setIssueId(res.ID);
    };

    const fetchCloseout = async () => {
        const data = await getCloseoutForm(activityData.id);
        setCloseoutData(data);
        setLoading(false);
        const initFieldsByKey = {};

        data.form.sections.map((section) => {
            section.rows.map((row) => {
                row.fields.map((field) => {
                    if (field.value) {
                        // initFieldsByKey[field.name] = field.value;
                    }
                    initFieldsByKey[field.name] = field;

                    return null;
                });

                return null;
            });

            return null;
        });
        setFieldsByKey(initFieldsByKey);
        setKPIs(data.kpis);
    };

    useEffect(() => {
        if (activityData) {
            fetchIssueId();
            fetchCloseout();
        }
    }, [activityData]);

    const updateFormValue = (updatedField) => {
        setFieldsByKey((prev) => {
            const updatedFormValues = { ...prev };
            updatedFormValues[updatedField.name] = updatedField;

            return updatedFormValues;
        });
    };

    const updateCloseout = async () => {
        document.getElementById('kpis').style.border = 'none';
        setLoading(true);
        const submitFormValues = {};
        window.setTimeout(async () => {
            Object.keys(fieldsByKey).map((fieldName) => {
                const field = fieldsByKey[fieldName];
                field.isDirty = true;

                const { value } = field;
                submitFormValues[`DE:${fieldName}`] = value;

                return null;
            });
            await updateCloseoutForm(activityData.id, {
                formFieldValues: submitFormValues,
                kpis,
            });
            await fetchCloseout();
            setLoading(false);
        }, 100);
    };

    const updateKPIs = (newKPIs) => {
        const swapped = newKPIs.slice(0);
        setKPIs(swapped);
    };

    const checkForm = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setLoading(true);
        setTimeout(async () => {
            document.getElementById('scrollable-area').scrollTo(0, 0);
            const submittable = checkRequired();

            if (submittable) {
                setTimeout(async () => {
                    const currentUser = await getCurrentUser();
                    if (saveActivity) {
                        setLoading(true);
                        await submitCloseout(issueId, currentUser.id);
                        await updateCloseout();
                        setSaveActivityCallback(true);
                        setSubmitRefresh(true);
                        // setLoading(false);
                    }
                }, 1000);
            } else {
                toast.dismiss();
                toast.error('Required form fields are missing.');
                const errors = document.getElementById('kpis');
                document.getElementById('kpis').style.border = 'thick solid red';

                if (errors.length) {
                    errors[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                window.setTimeout(() => setLoading(false), 100);
            }
        }, 100);
    };

    const checkRequired = useCallback(() => {
        if (kpis) {
            let submittable = false;
            kpis.map((kpi) => {
                if (!kpi.empty && kpi.kpiMetric !== null && kpi.kpiMetric !== '' && kpi.description !== '') {
                    submittable = true;
                }
            });

            return submittable;
        }
    }, [kpis]);

    useEffect(() => {
        if (saveActivity) {
            checkForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveActivity]);

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'Budget Transfer',
                }}
            />
            <Row className="py-3">
                <Col style={{ minHeight: '300px' }}>
                    {isLoading ? <Loading style={{ position: 'absolute', zIndex: '1', opacity: '0.6', backgroundColor: 'white' }} /> : null}
                    {closeoutData && closeoutData.form ? (
                        <form className="activity-form">
                            {closeoutData.form.sections.map((section, count) => (
                                <Fieldset
                                    key={`section:${closeoutData.form.name}:${section.id}:${section.name}:${count.toString()}`}
                                    section={section}
                                    isEditable={activityData.isEditable}
                                    fieldsByKey={fieldsByKey}
                                    onChange={updateFormValue}
                                />
                            ))}
                            {kpis ? (
                                <FormKPIs isEditable={activityData.isEditable} onChange={updateKPIs} title="KPI Performance" kpis={kpis} />
                            ) : null}
                            {activityData.isEditable ? (
                                <Button className="mt-5" outline color="primary" disabled={!activityData.isEditable} onClick={updateCloseout}>
                                    Save Changes
                                </Button>
                            ) : null}
                        </form>
                    ) : null}
                </Col>
                <Col id="rightColumn" style={{ flex: '0 0 345px' }} className="my-3">
                    {activityData.status === 'APF' && !!issueId ? (
                        <Approval
                            setSubmitRefresh={setSubmitRefresh}
                            submitRefresh={submitRefresh}
                            closeoutIssueId={issueId}
                            isLoading={isLoading}
                            setIsLoading={setLoading}
                            setShowRequiredOutline={props.setShowRequiredOutline}
                            activityData={activityData}
                            canSubmitApproval={props.canSubmitApproval}
                            refreshActivity={props.refreshActivity}
                            setSaveActivity={props.setSaveActivity}
                            setSaveActivityCallback={props.setSaveActivityCallbackShortStop}
                            updateActivitiesShortStop={props.updateActivitiesShortStop}
                            refreshTab={props.refreshTab}
                            closeoutData={closeoutData}
                            updateCloseout={updateCloseout}
                            taskStatus={taskStatus}
                        />
                    ) : null}
                </Col>
            </Row>
        </>
    );
};

Closeout.propTypes = {
    activityData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    setShowRequiredOutline: PropTypes.func,
    canSubmitApproval: PropTypes.bool,
    refreshActivity: PropTypes.func,
    setSaveActivity: PropTypes.func,
    setSaveActivityCallback: PropTypes.func,
    updateActivitiesShortStop: PropTypes.func,
    refreshTab: PropTypes.func,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
};
Closeout.defaultProps = {
    activityData: {},
};

export default Closeout;
