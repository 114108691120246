import React from 'react';
import { FormText, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormField } from './index';

const Fieldset = (props) => {
    const { section, onChange, fieldsByKey, isEditable, showRequiredOutline, shouldDisplay } = props;

    return (
        <fieldset>
            <h5 className="mt-3 form-header">{section.name.toUpperCase()}</h5>
            {section.description ? <FormText>{section.description}</FormText> : null}
            {section.rows.map((row) => (
                <Row form key={`formgroup:${row.fields.map((field) => `${field.name}:`)}`}>
                    {row.fields.map((field) => (
                        <FormField
                            field={field}
                            key={`${field.id}`}
                            onChange={onChange}
                            fieldsByKey={fieldsByKey}
                            isEditable={isEditable}
                            showRequiredOutline={showRequiredOutline}
                            shouldDisplay={shouldDisplay}
                        />
                    ))}
                </Row>
            ))}
        </fieldset>
    );
};
Fieldset.propTypes = {
    onChange: PropTypes.func,
    shouldDisplay: PropTypes.func,
    section: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    fieldsByKey: PropTypes.objectOf(PropTypes.any),
    isEditable: PropTypes.bool,
    showRequiredOutline: PropTypes.bool,
};
Fieldset.defaultProps = {
    onChange: () => {},
    shouldDisplay: () => {},
    section: {
        name: '',
        rows: [],
    },
    fieldsByKey: {},
    isEditable: true,
    showRequiredOutline: true,
};
export default Fieldset;
