import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Periods, Allocations, FormField } from '.';
import Fieldset from './fieldSet';
import Loading from '../../pages/loading';
import EmbeddedContent from './embeddedContent';
import { updateActivity, updateCampaign } from '../../utils/api';

const CSForm = (props) => {
    const {
        form,
        activityId,
        campaignId,
        travelPeriods,
        subActivities,
        marketBudgetAllocations,
        setActivityData,
        isEditable,
        areIssuesEditable,
        subEmbeddedContentEditable,
        setCanSubmitApproval,
        setFormChanged,
        showRequiredOutline,
        saveActivity,
        saveActivityCallback,
        updateActivitiesShortStop,
        embeddedContent,
    } = props;
    const [fieldsByKey, setFieldsByKey] = useState({});
    const [loading, setLoading] = useState(false);
    const [travel, setTravel] = useState([]);
    const [subs, setSubs] = useState([]);
    const [allocations, setAllocations] = useState(marketBudgetAllocations);
    const [localEmbeddedContent, setLocalEmbeddedContent] = useState(embeddedContent);

    useEffect(() => {
        const initFieldsByKey = {};
        form.sections.map((section) => {
            section.rows.map((row) => {
                row.fields.map((field) => {
                    if (field.value) {
                        // initFieldsByKey[field.name] = field.value;
                    }
                    initFieldsByKey[field.name] = {
                        ...field,
                        displayLogicRules: (field.displayLogicRules || []).concat(section.displayLogicRules || []),
                    };

                    return null;
                });

                return null;
            });

            return null;
        });

        setFieldsByKey(initFieldsByKey);
    }, [form]);

    useEffect(() => {
        setTravel(travelPeriods);
    }, [travelPeriods]);

    useEffect(() => {
        setSubs(subActivities);
    }, [subActivities]);

    useEffect(() => {
        setAllocations(marketBudgetAllocations);
    }, [marketBudgetAllocations]);

    const updateFormValue = (updatedField) => {
        setFieldsByKey((prev) => {
            const updatedFormValues = { ...prev };
            updatedFormValues[updatedField.name] = updatedField;
            setFormChanged(true);

            return updatedFormValues;
        });
    };

    const updateTravel = (newTravel) => {
        const swapped = newTravel.slice(0);
        setTravel(swapped);
    };

    const updateSubs = (newSubs) => {
        const swapped = newSubs.slice(0);
        setSubs(swapped);
    };

    const updateEmbeddedContent = (newEmbeddedContent) => {
        const swapped = newEmbeddedContent.slice(0);
        setLocalEmbeddedContent(swapped);
    };

    const updateAllocations = (newAllocations) => {
        const swapped = newAllocations.slice(0);
        setAllocations(swapped);
    };

    const shouldDisplay = (rules, formValues) => {
        let display = false;

        rules.map((rule) => {
            if (!display) {
                let matched = true;

                rule.matches.map((match) => {
                    const matchValue = formValues[match.fieldName] ? formValues[match.fieldName].value : '';
                    if (matched) {
                        matched =
                            (match.matchType === 'EXIST' &&
                                (Array.isArray(matchValue) ? matchValue.indexOf(match.value) > -1 : match.value === matchValue)) ||
                            (match.matchType === 'NOTEXIST' &&
                                (Array.isArray(matchValue) ? matchValue.indexOf(match.value) === -1 : match.value !== matchValue));
                    }

                    return null;
                });
                if (matched) {
                    display = true;
                }
            }

            return null;
        });

        return display;
    };

    const checkRequired = useCallback(() => {
        const submitFormValues = {};
        const newFieldsByKey = {};
        let canSubmit = true;
        if (!isEditable) {
            return {
                newFieldsByKey,
                canSubmit,
                submitFormValues,
            };
        }

        Object.keys(fieldsByKey).map((fieldName) => {
            const field = fieldsByKey[fieldName];
            field.isDirty = true;
            newFieldsByKey[fieldName] = field;

            let { value } = field;
            let empty = true;
            if (typeof value === 'boolean') {
                empty = false;
            } else if (Array.isArray(value)) {
                if (value.length && value[0] !== null) {
                    empty = false;
                } else {
                    empty = true;
                    value = '';
                }
            } else if (field.dataType === 'CURC' || field.dataType === 'NMBR') {
                if (value === null || !value.toString().length) {
                    empty = true;
                } else {
                    empty = Number.isNaN(value);
                }
            } else if (value) {
                empty = false;
            }

            if (
                field.isRequired &&
                field.isEditable &&
                empty &&
                (!field.displayLogicRules.length || shouldDisplay(field.displayLogicRules, fieldsByKey))
            ) {
                canSubmit = false;
            }

            if (value && field.displayType !== 'CALC') {
                submitFormValues[fieldName] = value;
            }

            return null;
        });

        // the check for at least ONE subactivity
        // subs.map((activity) => {
        //     if (!oneValidSubActivity) {
        //         if (activity.name && activity.startDate && activity.endDate) {
        //             oneValidSubActivity = true;
        //         }
        //     }

        //     return null;
        // });

        // if (!oneValidSubActivity) {
        //     canSubmit = false;
        // }

        return {
            newFieldsByKey,
            canSubmit,
            submitFormValues,
        };
    }, [fieldsByKey, subs]);

    const workflowCallback = useCallback(() => {
        const response = checkRequired();
        setCanSubmitApproval(response.canSubmit);
    }, [checkRequired, setCanSubmitApproval]);

    useEffect(() => {
        workflowCallback();
    }, [fieldsByKey, workflowCallback]);

    const checkForm = (e, doRequiredCheck) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setLoading(true);
        setTimeout(async () => {
            document.getElementById('scrollable-area').scrollTo(0, 0);
            const requiredValues = checkRequired();
            setFieldsByKey(requiredValues.newFieldsByKey);

            if (requiredValues.canSubmit || !doRequiredCheck) {
                const id = campaignId || activityId;

                if (!campaignId) {
                    const response = await updateActivity(id, {
                        formFieldValues: requiredValues.submitFormValues,
                        travelPeriods: travel,
                        subActivities: subs,
                        marketingBudgetAllocations: allocations,
                        embeddedContent: embeddedContent ? localEmbeddedContent : [],
                    });
                    setActivityData(response);
                    updateActivitiesShortStop(null, response);
                } else {
                    await updateCampaign(id, {
                        formFieldValues: requiredValues.submitFormValues,
                        travelPeriods: travel,
                        subActivities: subs,
                        marketingBudgetAllocations: allocations,
                        embeddedContent: embeddedContent ? localEmbeddedContent : [],
                    });
                }

                setTimeout(() => {
                    setLoading(false);
                    if (saveActivity) {
                        saveActivityCallback(true);
                    }
                }, 100);
            } else {
                toast.dismiss();
                toast.error('Required form fields are missing.');
                const errors = document.getElementsByClassName('is-invalid');
                if (errors.length) {
                    errors[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

                window.setTimeout(() => setLoading(false), 100);
            }
            setFormChanged(false);
        }, 100);
    };

    useEffect(() => {
        if (saveActivity) {
            checkForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveActivity]);

    checkRequired();

    return (
        <Row>
            <Col>
                {loading ? <Loading style={{ position: 'absolute', zIndex: '1', opacity: '0.6', backgroundColor: 'white' }} /> : null}
                {form.sections.length ? (
                    <div className="activity-form">
                        {form.name && 1 === 2 ? <legend>{form.name}</legend> : null}
                        {form.description ? <p className="mt-3">{form.description}</p> : null}
                        {form.sections.map((section, count) =>
                            shouldDisplay(section.displayLogicRules, fieldsByKey) || section.displayLogicRules.length === 0 ? (
                                <Fieldset
                                    key={`section:${form.name}:${section.id}:${section.name}:${count.toString()}`}
                                    section={section}
                                    onChange={updateFormValue}
                                    fieldsByKey={fieldsByKey}
                                    isEditable={isEditable}
                                    showRequiredOutline={showRequiredOutline}
                                    shouldDisplay={shouldDisplay}
                                />
                            ) : null
                        )}
                        {embeddedContent ? (
                            <EmbeddedContent
                                embeddedContent={embeddedContent}
                                onChange={updateEmbeddedContent}
                                isEditable={subEmbeddedContentEditable}
                            />
                        ) : null}
                        {subActivities ? (
                            <Periods
                                title="Sub Activity Timing"
                                periods={subs}
                                onChange={updateSubs}
                                isEditable={areIssuesEditable}
                                showRequiredOutline={false}
                                showRequiredStars={false}
                            />
                        ) : null}

                        {marketBudgetAllocations ? (
                            <Allocations
                                title="Market Budget Allocations"
                                allocations={allocations}
                                onChange={updateAllocations}
                                isEditable={areIssuesEditable}
                            />
                        ) : null}
                        {isEditable ? (
                            <Button className="mt-5" disabled={!isEditable} outline color="primary" onClick={() => checkForm(null, true)}>
                                Save Changes
                            </Button>
                        ) : null}
                    </div>
                ) : null}
            </Col>
        </Row>
    );
};

CSForm.propTypes = {
    form: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    activityId: PropTypes.string.isRequired,
    travelPeriods: PropTypes.oneOfType([PropTypes.array]),
    subActivities: PropTypes.oneOfType([PropTypes.array]),
    marketBudgetAllocations: PropTypes.oneOfType([PropTypes.array]),
    setActivityData: PropTypes.func.isRequired,
    isEditable: PropTypes.bool,
    areIssuesEditable: PropTypes.bool,
    subEmbeddedContentEditable: PropTypes.bool,
    setCanSubmitApproval: PropTypes.func,
    setFormChanged: PropTypes.func,
    showRequiredOutline: PropTypes.bool,
    saveActivity: PropTypes.bool,
    saveActivityCallback: PropTypes.func,
    updateActivitiesShortStop: PropTypes.func.isRequired,
    embeddedContent: PropTypes.arrayOf(PropTypes.object),
};
CSForm.defaultProps = {
    form: {
        sections: [],
    },
    travelPeriods: null,
    subActivities: null,
    marketBudgetAllocations: null,
    isEditable: true,
    areIssuesEditable: true,
    subEmbeddedContentEditable: true,
    embeddedContent: undefined,
    showRequiredOutline: true,
    saveActivity: false,
    setCanSubmitApproval: () => {},
    setFormChanged: () => {},
    saveActivityCallback: () => {},
};

export default CSForm;
