import React, { useContext, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { Row, Col, Label, Input, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getMarketPlanDetails, createBudgetTransfer } from '../utils/api';
import { AppContext } from '../context';
import Loading from './loading';
import DataTable from '../components/Table';

const Budget = (props) => {
    const { activityData, setBudgetTransfer } = props;
    const [budget, setBudget] = useState(0);
    const [activitySelected, setActivitySelected] = useState(null);
    const [marketPlan, setMarketPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const appData = useContext(AppContext);

    const submitBudget = () => {
        setSubmitting(true);
        window.setTimeout(async () => {
            const succeeded = await createBudgetTransfer({
                sourceActivityId: activityData.id,
                destinationActivityId: activitySelected,
                transferAmount: budget,
            });
            setSubmitting(false);
            if (succeeded) {
                setBudgetTransfer(false);
                toast.success('Budget transferred.');
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Activity Name',
                accessor: 'name',
            },
            {
                Header: 'Budget Planned',
                accessor: 'budgetPlanned',
            },
            {
                Header: 'Budget Re-Forcasted Detail',
                accessor: 'budgetReforcasted',
            },
            {
                Header: 'Budget Actual',
                accessor: 'budgetActual',
            },
            {
                Header: 'Budget Variance',
                accessor: 'budgetVariance',
            },
            {
                Header: 'Source Markets',
                accessor: 'sourceMarkets',
            },
            {
                Header: 'Functional Area',
                accessor: 'functionalArea',
            },
            {
                Header: 'Activity TYpe',
                accessor: 'activityType',
            },
        ],
        []
    );

    let tableData = [];
    if (marketPlan) {
        tableData = marketPlan.activities.map((activity) => ({
            id: activity.id,
            isSelected: activity.id === activitySelected,
            name: activity.name,
            sourceMarkets: activity.sourceMarkets.join(', '),
            activityType: activity.activityType,
        }));
    }

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'Budget Transfer',
                }}
            />
            <Row className="py-3 activity-form">
                <Col className="flex-nowrap d-flex flex-column">
                    <Label className="mb-3" style={{ whiteSpace: 'nowrap' }}>
                        Budget Planned
                    </Label>
                    {activityData.budgetPlanned || '--'}
                </Col>
                <Col className="flex-nowrap d-flex flex-column">
                    <Label className="mb-3" style={{ whiteSpace: 'nowrap' }}>
                        Budget Re-forecasted Detail
                    </Label>
                    {activityData.budgetReforcasted || '--'}
                </Col>
                <Col className="flex-nowrap d-flex flex-column">
                    <Label className="mb-3" style={{ whiteSpace: 'nowrap' }}>
                        Budget Actual
                    </Label>
                    {activityData.budgetActual || '--'}
                </Col>
                <Col className="flex-nowrap d-flex flex-column">
                    <Label className="mb-3" style={{ whiteSpace: 'nowrap' }}>
                        Budget Variance
                    </Label>
                    {activityData.budgetVariance || '--'}
                </Col>
                <Col className="flex-nowrap d-flex flex-column">
                    <Label className="mb-2" style={{ whiteSpace: 'nowrap' }}>
                        Enter Amount to Transfer
                    </Label>
                    <div className="d-flex flex-row position-relative">
                        {submitting ? <Loading style={{ position: 'absolute', zIndex: '1', opacity: '0.6', backgroundColor: 'white' }} /> : null}
                        <Input type="number" defaultValue={budget} onBlur={(e) => setBudget(e.target.value.trim().replace(/\D/g, ''))} />
                        <Button className="ml-3" color="primary" onClick={submitBudget} disabled={!activitySelected || budget === 0 || !budget}>
                            Submit
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="text-center">
                    <h4 className="mt-0">Marketing Planning Cycle</h4>
                </Col>
            </Row>
            <Row className="justify-content-center mb-3">
                <Col sm={1} />
                <Col sm={10} className="text-center">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="secondary" outline className="border-0">
                            {marketPlan ? marketPlan.name : 'Select a Market Plan to see Activities'}
                        </DropdownToggle>
                        <DropdownMenu right>
                            {appData.marketPlan.map((mappedMarketPlan) => (
                                <DropdownItem
                                    key={`${mappedMarketPlan.id}`}
                                    className={`list-group-item-action${marketPlan && marketPlan.id === mappedMarketPlan.id ? ' active' : ''}`}
                                    onClick={async () => {
                                        setLoading(true);
                                        window.setTimeout(async () => {
                                            const result = await getMarketPlanDetails(mappedMarketPlan.id);
                                            setMarketPlan(result);
                                            setLoading(false);
                                        }, 100);
                                    }}
                                >
                                    {mappedMarketPlan.name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </Col>
                <Col sm={1} />
            </Row>
            <Row className="justify-content-center">
                <Col sm={12} className="text-center" style={{ height: '500px' }}>
                    {loading ? <Loading style={{ position: 'absolute', zIndex: '1', opacity: '0.6', backgroundColor: 'white' }} /> : null}
                    {!loading && marketPlan && marketPlan.activities.length ? (
                        <DataTable
                            selectedActivityIds={[activitySelected]}
                            columns={columns}
                            data={tableData}
                            onToggle={(selectedId) => {
                                setActivitySelected(selectedId);
                            }}
                            canToggleAll={false}
                        />
                    ) : null}
                    {!loading && marketPlan && marketPlan.activities.length === 0 ? <h5>No activites to select.</h5> : null}
                </Col>
            </Row>
        </>
    );
};

Budget.propTypes = {
    activityData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    setBudgetTransfer: PropTypes.func,
};
Budget.defaultProps = {
    activityData: {},
    setBudgetTransfer: () => {},
};

export default Budget;
