import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { Prompt } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useBeforeunload } from 'react-beforeunload';
import {
    Row,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    NavItem,
    NavLink,
    Nav,
    Card,
    CardBody,
    Button,
    Popover,
    PopoverHeader,
    PopoverBody,
    Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from '../components/forms/form';
import Loading from './loading';
import Budget from './budget';
import Closeout from './closeout';
import { Documents, Approval, Comments, Campaign } from '../layout';
import { activityName } from '../utils/common';
import {
    getCampaigns,
    downloadActivities,
    createActivityVersion,
    deleteActivity,
    cancelActivity,
    getActivityDetails,
    getCurrentUser,
} from '../utils/api';

const Activity = (props) => {
    const {
        loading,
        selectedActivities,
        setActivityLoading,
        activityData,
        setActivityData,
        budgetTransfer,
        setBudgetTransfer,
        refreshActivity,
        updateActivitiesShortStop,
        refreshTab,
        activeTab,
        setActiveTab,
        match,
        history,
        marketPlanId,
        removeActivity,
        activityId,
        marketPlanData,
    } = props;

    const [downloading, setDownloading] = useState(false);
    const [activeCampaign, setActiveCampaign] = useState({});
    const [canSubmitApproval, setCanSubmitApproval] = useState(true);
    const [formChanged, setFormChanged] = useState(false);
    const [showRequiredOutline, setShowRequiredOutline] = useState(false);
    const [saveActivity, setSaveActivity] = useState(false);
    const [saveActivityCallback, setSaveActivityCallback] = useState(() => {});
    const [innerLoading, setInnerLoading] = useState(true);
    const [canSubmitCancellation, setCanSubmitCancellation] = useState(false);
    const cancellationReason = useRef(null);

    const [campaigns, setCampaigns] = useState([]);

    const activeCampaignId = match && match.params && match.params.campaignId ? match.params.campaignId : null;

    const [popoverDeleteOpen, setPopoverDeleteOpen] = useState(false);
    const toggleDelete = () => setPopoverDeleteOpen(!popoverDeleteOpen);

    const [popoverCancelOpen, setPopoverCancelOpen] = useState(false);
    const toggleCancel = () => setPopoverCancelOpen(!popoverCancelOpen);

    const [creatingNewVersion, setCreatingNewVersion] = useState(false);
    const [popoverNewVersionOpen, setPopoverNewVersionOpen] = useState(false);
    const toggleNewVersion = () => setPopoverNewVersionOpen(!popoverNewVersionOpen);

    const downloadActivitiesClick = async () => {
        setDownloading(true);
        window.setTimeout(async () => {
            await downloadActivities(selectedActivities.length ? selectedActivities : [activityData.id]);
            setDownloading(false);
        }, 100);
    };

    const findCampaignById = (matchCampaignId) => {
        const foundCampaign = campaigns.filter((campaign) => campaign.id === matchCampaignId);

        return foundCampaign.length ? foundCampaign[0] : false;
    };

    useEffect(() => {
        const foundCampaign = findCampaignById(activeCampaignId);
        setActiveCampaign(foundCampaign || {});
    }, [activeCampaignId, campaigns]);

    useEffect(() => {
        if (activeCampaignId && activeCampaign.name !== activeTab.name) {
            setActiveTab(findCampaignById(activeCampaignId).name);
            setBudgetTransfer(false);
        }
    }, [activeCampaignId, activeCampaign]);

    const updateActivites = async () => {
        const refreshedActivity = await getActivityDetails(activityData.id);
        refreshTab('activities');
        refreshTab('approvals');
        updateActivitiesShortStop(null, refreshedActivity);
    };

    const createCampaignUrl = (campaignId) => {
        const campaignUrl = props.match.url
            .split('/')
            .slice(0, 5)
            .join('/')
            .toString();

        return campaignUrl + (campaignId ? `/${campaignId}` : '');
    };

    const goToUrl = (campaignId) => {
        setActiveCampaign(campaignId);
        history.push(createCampaignUrl(campaignId));
    };

    const handleCancellationReason = async () => {
        setCanSubmitCancellation(cancellationReason?.current.value.trim().length > 0);
    };

    const fetchCampaigns = async () => {
        setCampaigns([]);
        const data = await getCampaigns(activityData.id);
        setCampaigns(data);

        if (data.length && !activityData.isViewable) {
            window.setTimeout(() => {
                goToUrl(data[0].id);
            }, 500);
        }
        setInnerLoading(false);
    };

    useEffect(() => {
        if (activityData && activityData.id) {
            fetchCampaigns();
        }
    }, [activityData]);

    useBeforeunload(() => {
        if (formChanged) {
            return `You have unsaved changes, are you sure you want to leave?`;
        }

        return null;
    });

    const setSaveActivityCallbackShortStop = (newFunc) => {
        setSaveActivityCallback(() => newFunc);
    };

    const setSaveActivityShortStop = (newBool) => {
        setSaveActivity(() => newBool);
    };

    useEffect(() => {
        if (activityData && activityData.isViewable) {
            setActiveTab('details');
        }
        if (formChanged !== false) {
            setFormChanged(false);
        }
    }, [activityData, formChanged]);

    const canCreateCampaign = () => {
        const selectedActivity = selectedActivities[0];

        const currentActivity = get(marketPlanData, 'activities', []).filter((activity) => activity.id === selectedActivity);
        const meetPreReqs = !loading && activityId && activityId !== 'new' && activityId !== 'search';

        return meetPreReqs && currentActivity && currentActivity[0] && currentActivity[0].canCreateCampaign;
    };

    const canBeCancelled = () => {
        const selectedActivity = selectedActivities[0];
        const currentActivity = get(marketPlanData, 'activities', []).filter((activity) => activity.id === selectedActivity);

        return currentActivity && currentActivity[0] && currentActivity[0].canBeCancelled;
    };

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'Activity',
                }}
            />
            <Prompt when={formChanged} message="You have unsaved changes, are you sure you want to leave?" />
            {loading || innerLoading ? (
                <Row className="flex-grow-1" style={{ height: '400px', marginTop: '100px' }}>
                    <Col className="justify-content-center align-items-stretch flex-grow-1">
                        <Loading style={{ height: '400px', marginTop: '100px' }} height={' '} />
                    </Col>
                </Row>
            ) : (
                <Row className="justify-content-center align-items-stretch flex-grow-1">
                    <Col className="pt-3">
                        <Row>
                            <Col>
                                <div className="align-content-center d-flex">
                                    <div className="d-inline-flex mr-3">
                                        <div
                                            className="rounded"
                                            style={{
                                                backgroundColor: '#BDD5EC',
                                                width: '3.75rem',
                                                height: '3.75rem',
                                                fontSize: '3rem',
                                                textAlign: 'center',
                                                lineHeight: '3.5rem',
                                                color: 'white',
                                            }}
                                        >
                                            {activityName(activityData.name)[0]}
                                        </div>
                                    </div>

                                    <div className="d-inline-flex flex-column">
                                        <div
                                            className="clickable"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => window.open(activityData.details.taskUrl)}
                                            onKeyDown={() => window.open(activityData.details.taskUrl)}
                                        >
                                            {activityData.status.includes('DED') || activityData.status.includes('IFV') ? (
                                                <h2 className="mb-2" style={{ textDecoration: 'line-through' }}>
                                                    {activityData.name}
                                                </h2>
                                            ) : (
                                                <h2 className="mb-2">{activityData.name}</h2>
                                            )}
                                        </div>

                                        <Breadcrumb>
                                            <BreadcrumbItem>{activityData.sourceMarketLabel}</BreadcrumbItem>
                                            <BreadcrumbItem>{activityData.activityOwner}</BreadcrumbItem>
                                            <BreadcrumbItem>{activityData.activityType}</BreadcrumbItem>
                                        </Breadcrumb>
                                    </div>
                                    <Col>
                                        {activityData.details.createdBy ? (
                                            <div className="text-right col">Created By: {activityData.details.createdBy}</div>
                                        ) : null}
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <Row id="navigation">
                            <Col>
                                <Nav tabs>
                                    {activityData.isViewable ? (
                                        <NavItem className="mr-1 flex-grow-0">
                                            <NavLink
                                                className={!budgetTransfer && activeTab === 'details' ? 'active' : ''}
                                                onClick={() => {
                                                    setActiveTab('details');
                                                    goToUrl();
                                                    setBudgetTransfer(false);
                                                }}
                                            >
                                                Activity Details
                                            </NavLink>
                                        </NavItem>
                                    ) : null}

                                    {campaigns.map((campaign) => (
                                        <NavItem className="flex-grow-0" key={campaign.name}>
                                            <NavLink
                                                className={!budgetTransfer && activeTab === campaign.name ? 'active' : ''}
                                                onClick={() => {
                                                    setBudgetTransfer(false);
                                                    goToUrl(campaign.id);
                                                }}
                                            >
                                                {campaign.name}
                                            </NavLink>
                                        </NavItem>
                                    ))}

                                    {activityData.isViewable ? (
                                        <NavItem className="flex-grow-0">
                                            <NavLink
                                                className={!budgetTransfer && activeTab === 'closeout' ? 'active' : ''}
                                                onClick={() => {
                                                    setActiveTab('closeout');
                                                    setBudgetTransfer(false);
                                                    goToUrl();
                                                }}
                                            >
                                                Closeout Form
                                            </NavLink>
                                        </NavItem>
                                    ) : null}
                                </Nav>
                            </Col>
                        </Row>
                        {budgetTransfer ? <Budget activityData={activityData} setBudgetTransfer={setBudgetTransfer} /> : null}
                        {!budgetTransfer && activeCampaign && activeTab === activeCampaign.name ? (
                            <Campaign
                                campaign={activeCampaign}
                                activityData={activityData}
                                setActivityData={setActivityData}
                                updateActivitiesShortStop={updateActivitiesShortStop}
                                history={history}
                                fetchCampaigns={fetchCampaigns}
                                match={match}
                            />
                        ) : null}
                        {!budgetTransfer && activeTab === 'details' ? (
                            <Row>
                                <Col className="py-3">
                                    {activityData.details && activityData.details.forms
                                        ? activityData.details.forms.map((form, count) => (
                                              <Form
                                                  key={form.name}
                                                  form={form}
                                                  activityId={activityData.id}
                                                  travelPeriods={count === 0 ? activityData.details.travelPeriods : null}
                                                  subActivities={count === 0 ? activityData.details.subActivities : null}
                                                  marketBudgetAllocations={count === 0 ? activityData.details.marketBudgetAllocations : null}
                                                  setActivityData={setActivityData}
                                                  isEditable={activityData.isEditable}
                                                  areIssuesEditable={activityData.areIssuesEditable}
                                                  setCanSubmitApproval={setCanSubmitApproval}
                                                  setFormChanged={setFormChanged}
                                                  showRequiredOutline={showRequiredOutline}
                                                  saveActivity={saveActivity}
                                                  saveActivityCallback={saveActivityCallback}
                                                  updateActivitiesShortStop={updateActivitiesShortStop}
                                              />
                                          ))
                                        : null}
                                </Col>
                                <Col id="rightColumn" className="my-3" style={{ flex: '0 0 345px' }}>
                                    <Card className="mb-4">
                                        <CardBody>
                                            <h4 className="mb-0 d-inline-block mr-2">Actions</h4>
                                            {activityData.id && activeTab === 'details' ? (
                                                <Button
                                                    className={`mt-3 w-100 align-self-center border-0${downloading ? ' thinking' : ''}`}
                                                    color="primary"
                                                    onClick={() => {
                                                        window.print();
                                                        // if (!downloading) {
                                                        //     downloadActivitiesClick();
                                                        // }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={['fa', 'download']} />
                                                    <span>Download</span>
                                                </Button>
                                            ) : null}
                                            {activityData.id && activeTab === 'details' && activityData.canCreateVersion ? (
                                                <>
                                                    <Button
                                                        id="NewVersionButton"
                                                        className="mt-3 w-100 align-self-center border-0"
                                                        color="primary"
                                                        onClick={toggleNewVersion}
                                                    >
                                                        <FontAwesomeIcon icon={['fa', 'plus']} />
                                                        <span>New Version</span>
                                                    </Button>
                                                    <Popover
                                                        placement="bottom"
                                                        isOpen={popoverNewVersionOpen}
                                                        target="NewVersionButton"
                                                        toggle={toggleNewVersion}
                                                    >
                                                        <PopoverHeader>Are you sure?</PopoverHeader>
                                                        <PopoverBody>
                                                            You are now creating a new version of the existing activity. This will make the activity
                                                            editable and will reset all approvals. This cannot be undone. Are you sure you want to go
                                                            ahead?
                                                            <div className="text-center my-3">
                                                                <Button
                                                                    className="mr-5"
                                                                    color="success"
                                                                    onClick={
                                                                        creatingNewVersion
                                                                            ? () => {}
                                                                            : async () => {
                                                                                  setActivityLoading(true);
                                                                                  setCreatingNewVersion(true);
                                                                                  const response = await createActivityVersion(activityData.id);
                                                                                  toggleNewVersion();
                                                                                  window.setTimeout(() => {
                                                                                      setCreatingNewVersion(false);
                                                                                      history.push(
                                                                                          `/marketPlan/${marketPlanId}/activity/${response.id}`
                                                                                      );
                                                                                      refreshTab('activities');
                                                                                      refreshTab('approvals');
                                                                                  }, 1000);
                                                                              }
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={['fa', 'check']} />
                                                                    <span>Yes</span>
                                                                </Button>
                                                                <Button color="danger" onClick={toggleNewVersion}>
                                                                    <FontAwesomeIcon icon={['fa', 'ban']} />
                                                                    <span>No</span>
                                                                </Button>
                                                            </div>
                                                        </PopoverBody>
                                                    </Popover>
                                                </>
                                            ) : null}
                                            {selectedActivities.length === 1 && canCreateCampaign() ? (
                                                <Button
                                                    className="mt-4 w-100 align-self-center border-0"
                                                    color="primary"
                                                    onClick={() => {
                                                        setBudgetTransfer(false);
                                                        history.push(`/marketPlan/${marketPlanId}/activity/${activityId}/campaign/new`);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={['fa', 'plus']} />
                                                    <span>New Campaign</span>
                                                </Button>
                                            ) : null}
                                            {activityData.canBeCancelled ? (
                                                <>
                                                    <Button
                                                        id="CancelActivityButton"
                                                        className="mt-4 w-100 align-self-center border-0"
                                                        color="danger"
                                                        onClick={() => {
                                                            setBudgetTransfer(false);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={['fa', 'ban']} />
                                                        <span>Cancel OP</span>
                                                    </Button>
                                                    <Popover
                                                        placement="bottom"
                                                        isOpen={popoverCancelOpen}
                                                        target="CancelActivityButton"
                                                        toggle={toggleCancel}
                                                    >
                                                        <PopoverHeader>Are you sure?</PopoverHeader>
                                                        <PopoverBody>
                                                            This activity will be start the cancellation approval process, continue?
                                                            <div className="text-center my-3">
                                                                <Input
                                                                    type="textarea"
                                                                    innerRef={cancellationReason}
                                                                    onChange={handleCancellationReason}
                                                                    placeholder="Add a reason..."
                                                                    className="mb-2"
                                                                />
                                                                <Button
                                                                    className="mr-5"
                                                                    color="success"
                                                                    disabled={!canSubmitCancellation}
                                                                    onClick={async () => {
                                                                        setActivityLoading(true);

                                                                        await cancelActivity(
                                                                            activityData.id,
                                                                            cancellationReason.current.value.trim()
                                                                        );
                                                                        await updateActivites();
                                                                        toggleCancel();
                                                                        window.setTimeout(() => {
                                                                            setCreatingNewVersion(false);
                                                                            history.push(`/marketPlan/${marketPlanId}/activity/${activityData.id}`);
                                                                            refreshTab('activities');
                                                                            refreshTab('approvals');
                                                                            refreshActivity();

                                                                            setActivityLoading(false);
                                                                        }, 1000);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={['fa', 'check']} />
                                                                    <span>Yes</span>
                                                                </Button>
                                                                <Button color="danger" onClick={toggleCancel}>
                                                                    <FontAwesomeIcon icon={['fa', 'ban']} />
                                                                    <span>No</span>
                                                                </Button>
                                                            </div>
                                                        </PopoverBody>
                                                    </Popover>
                                                </>
                                            ) : null}
                                            {activityData.id &&
                                            activeTab === 'details' &&
                                            activityData.canDeleteActivity &&
                                            activityData.status === 'DRA' ? (
                                                <>
                                                    <Button
                                                        id="DeleteActivityButton"
                                                        className="mt-4 w-100 align-self-center border-0"
                                                        color="danger"
                                                        onClick={toggleDelete}
                                                    >
                                                        <FontAwesomeIcon icon={['fa', 'trash-alt']} />
                                                        <span>Delete</span>
                                                    </Button>
                                                    <Popover
                                                        placement="bottom"
                                                        isOpen={popoverDeleteOpen}
                                                        target="DeleteActivityButton"
                                                        toggle={toggleDelete}
                                                    >
                                                        <PopoverHeader>Are you sure?</PopoverHeader>
                                                        <PopoverBody>
                                                            This activity will be deleted immediately, continue?
                                                            <div className="text-center my-3">
                                                                <Button
                                                                    className="mr-5"
                                                                    color="success"
                                                                    onClick={
                                                                        creatingNewVersion
                                                                            ? () => {}
                                                                            : async () => {
                                                                                  setActivityLoading(true);
                                                                                  setCreatingNewVersion(true);
                                                                                  await deleteActivity(activityData.id);
                                                                                  updateActivites();
                                                                                  toggleDelete();
                                                                                  window.setTimeout(() => {
                                                                                      setCreatingNewVersion(false);
                                                                                      history.push(
                                                                                          `/marketPlan/${marketPlanId}/activity/${activityData.id}`
                                                                                      );
                                                                                      refreshActivity();
                                                                                      setActivityLoading(false);
                                                                                  }, 1000);
                                                                              }
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={['fa', 'check']} />
                                                                    <span>Yes</span>
                                                                </Button>
                                                                <Button color="danger" onClick={toggleDelete}>
                                                                    <FontAwesomeIcon icon={['fa', 'ban']} />
                                                                    <span>No</span>
                                                                </Button>
                                                            </div>
                                                        </PopoverBody>
                                                    </Popover>
                                                </>
                                            ) : null}
                                        </CardBody>
                                    </Card>
                                    {activityData.isViewable && !budgetTransfer && activeTab === 'details' ? (
                                        <>
                                            <Approval
                                                setShowRequiredOutline={setShowRequiredOutline}
                                                activityData={activityData}
                                                canSubmitApproval={canSubmitApproval}
                                                refreshActivity={refreshActivity}
                                                setSaveActivity={setSaveActivityShortStop}
                                                setSaveActivityCallback={setSaveActivityCallbackShortStop}
                                                updateActivitiesShortStop={updateActivitiesShortStop}
                                                refreshTab={refreshTab}
                                                taskStatus={activityData.status}
                                            />
                                            <div className="mt-4" />
                                            <Comments
                                                commentObj={activityData}
                                                commentTaskID={activityData.id}
                                                commentTaskLink={activityData.details.taskUpdatesTabUrl}
                                            />
                                            <div className="mt-4" />
                                            <Documents activityData={activityData} />
                                        </>
                                    ) : null}
                                </Col>
                            </Row>
                        ) : null}
                        {!budgetTransfer && activeTab === 'closeout' ? (
                            <Closeout
                                activityData={activityData}
                                setShowRequiredOutline={setShowRequiredOutline}
                                canSubmitApproval={canSubmitApproval}
                                refreshActivity={refreshActivity}
                                refreshTab={refreshTab}
                                saveActivity={saveActivity}
                                setSaveActivity={setSaveActivityShortStop}
                                setSaveActivityCallback={setSaveActivityCallbackShortStop}
                                updateActivitiesShortStop={updateActivitiesShortStop}
                                taskStatus={activityData.status}
                            />
                        ) : null}
                    </Col>
                </Row>
            )}
        </>
    );
};

Activity.propTypes = {
    loading: PropTypes.bool,
    activityData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    marketPlanData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    setActivityData: PropTypes.func.isRequired,
    selectedActivities: PropTypes.arrayOf(PropTypes.any),
    budgetTransfer: PropTypes.bool,
    setBudgetTransfer: PropTypes.func,
    setActivityLoading: PropTypes.func,
    refreshActivity: PropTypes.func,
    refreshTab: PropTypes.func,
    removeActivity: PropTypes.func,
    updateActivitiesShortStop: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    marketPlanId: PropTypes.string,
    activityId: PropTypes.string,
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
};
Activity.defaultProps = {
    loading: true,
    selectedActivities: [],
    activityData: {},
    marketPlanData: {},
    budgetTransfer: false,
    marketPlanId: '',
    activityId: '',
    setBudgetTransfer: () => {},
    removeActivity: () => {},
    setActivityLoading: () => {},
    refreshActivity: () => {},
    refreshTab: () => {},
    activeTab: () => '',
};

export default Activity;
