import React, { Fragment } from 'react';
import { Row, Col, Input, Button, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';
import moment from 'moment';
import DateTime from './datetime';

const Periods = (props) => {
    const { periods, onChange, title, isEditable, showRequiredOutline, showRequiredStars } = props;

    const addRow = () => {
        periods.push({
            renderId: shortid.generate(),
            name: null,
            startDate: null,
            endDate: null,
            note: null,
            valid: false,
        });
        onChange(periods);
    };

    const removeRow = (count) => {
        periods.splice(count, 1);
        onChange(periods);
    };

    if (periods.length === 0 && isEditable) {
        addRow();

        return null;
    }

    const checkIfInvalid = (period, booking) => {
        let className = '';
        const { startDate, endDate, bookingWindowStartDate, bookingWindowEndDate } = period;

        if (!booking && startDate && endDate && moment(startDate, 'YYYY-MM-DD').isAfter(moment(endDate, 'YYYY-MM-DD'))) {
            className = ' is-invalid';
        }

        if (
            booking &&
            bookingWindowStartDate &&
            bookingWindowEndDate &&
            moment(bookingWindowStartDate, 'YYYY-MM-DD').isAfter(moment(bookingWindowEndDate, 'YYYY-MM-DD'))
        ) {
            className = ' is-invalid';
        }

        return className;
    };

    return (
        <Row>
            <Col>
                <fieldset>
                    <h5 className="mt-3 mb-2 form-header">{title.toUpperCase()}</h5>
                    <FormGroup>
                        {periods.map((period, count) => (
                            <Fragment key={`period:${period.id || period.renderId}:${count.toString()}`}>
                                <Row form className="mb-3">
                                    <Col>
                                        <Row className="mb-2 align-items-end">
                                            <Col className="pr-0">
                                                <Label className="mb-0">
                                                    Name{showRequiredStars ? <span className="text-danger ml-1">*</span> : null}
                                                </Label>
                                                {isEditable ? (
                                                    <Input
                                                        type="text"
                                                        defaultValue={period.name}
                                                        onBlur={(e) => {
                                                            const newPeriods = [...periods];
                                                            const newPeriod = { ...period };
                                                            newPeriod.name = e.target.value.trim();
                                                            newPeriods.splice(count, 1, newPeriod);
                                                            onChange(newPeriods);
                                                        }}
                                                        className={showRequiredOutline && !period.name ? 'is-invalid' : ''}
                                                    />
                                                ) : (
                                                    <div>{period.name}</div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-2 align-items-end">
                                            <Col className="pr-0">
                                                <Label className="mb-0">
                                                    Start Date{showRequiredStars ? <span className="text-danger ml-1">*</span> : null}
                                                </Label>
                                                {isEditable ? (
                                                    <DateTime
                                                        value={period.startDate}
                                                        onChange={(date) => {
                                                            const newPeriods = [...periods];
                                                            const newPeriod = { ...period };
                                                            newPeriod.startDate = date;
                                                            newPeriods.splice(count, 1, newPeriod);
                                                            onChange(newPeriods);
                                                        }}
                                                        className={`input-datetime${showRequiredOutline && !period.startDate ? ' is-invalid' : ''}`}
                                                        dateFormat="DATE"
                                                    />
                                                ) : (
                                                    <div>{period.startDate}</div>
                                                )}
                                            </Col>
                                            <Col className="pr-0">
                                                <Label className="mb-0">
                                                    End Date{showRequiredStars ? <span className="text-danger ml-1">*</span> : null}
                                                </Label>
                                                {isEditable ? (
                                                    <DateTime
                                                        value={period.endDate}
                                                        onChange={(date) => {
                                                            const newPeriods = [...periods];
                                                            const newPeriod = { ...period };
                                                            newPeriod.endDate = date;
                                                            newPeriods.splice(count, 1, newPeriod);
                                                            onChange(newPeriods);
                                                        }}
                                                        className={`input-datetime${checkIfInvalid(period)}${
                                                            showRequiredOutline && !period.endDate ? ' is-invalid' : ''
                                                        }`}
                                                        dateFormat="DATE"
                                                    />
                                                ) : (
                                                    <div>{period.endDate}</div>
                                                )}
                                            </Col>
                                            <Col className="pr-0">
                                                <Label className="mb-0">Booking Window Start Date</Label>
                                                {isEditable ? (
                                                    <DateTime
                                                        value={period.bookingWindowStartDate}
                                                        onChange={(date) => {
                                                            const newPeriods = [...periods];
                                                            const newPeriod = { ...period };
                                                            newPeriod.bookingWindowStartDate = date;
                                                            newPeriods.splice(count, 1, newPeriod);
                                                            onChange(newPeriods);
                                                        }}
                                                        className="input-datetime"
                                                        dateFormat="DATE"
                                                    />
                                                ) : (
                                                    <div>{period.bookingWindowStartDate}</div>
                                                )}
                                            </Col>
                                            <Col className="pr-0">
                                                <Label className="mb-0">Booking Window End Date</Label>
                                                {isEditable ? (
                                                    <DateTime
                                                        value={period.bookingWindowEndDate}
                                                        onChange={(date) => {
                                                            const newPeriods = [...periods];
                                                            const newPeriod = { ...period };
                                                            newPeriod.bookingWindowEndDate = date;
                                                            newPeriods.splice(count, 1, newPeriod);
                                                            onChange(newPeriods);
                                                        }}
                                                        className={`input-datetime${checkIfInvalid(period, true)}`}
                                                        dateFormat="DATE"
                                                    />
                                                ) : (
                                                    <div>{period.bookingWindowEndDate}</div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-0">
                                                {isEditable ? (
                                                    <Input
                                                        defaultValue={period.notes}
                                                        onBlur={(e) => {
                                                            const newPeriods = [...periods];
                                                            const newPeriod = { ...period };
                                                            newPeriod.notes = e.target.value.trim();
                                                            newPeriods.splice(count, 1, newPeriod);
                                                            onChange(newPeriods);
                                                        }}
                                                        type="TextArea"
                                                        placeholder="Add Note..."
                                                    />
                                                ) : (
                                                    <p>{period.notes}</p>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    {isEditable ? (
                                        <Col className="align-items-center d-flex flex-grow-0 pl-4 pr-0 flex-column">
                                            <Row>
                                                <Col>
                                                    <Label className="mb-0 invisible">R</Label>
                                                </Col>
                                            </Row>
                                            <Row className="flex-grow-1">
                                                <Col className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        icon={['far', 'times-circle']}
                                                        className="clickable hover-primary"
                                                        type="button"
                                                        onClick={() => removeRow(count)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    ) : null}
                                </Row>
                            </Fragment>
                        ))}
                        {periods.length === 0 ? (
                            <div>
                                <em className="invisible">Empty</em>
                            </div>
                        ) : null}
                        {isEditable ? (
                            <Row>
                                <Col>
                                    <Button color="secondary" outline size="sm" onClick={addRow} className="mt-2">
                                        + Add
                                    </Button>
                                </Col>
                            </Row>
                        ) : null}
                    </FormGroup>
                </fieldset>
            </Col>
        </Row>
    );
};

Periods.propTypes = {
    onChange: PropTypes.func,
    periods: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    isEditable: PropTypes.bool,
    showRequiredOutline: PropTypes.bool,
    showRequiredStars: PropTypes.bool,
};
Periods.defaultProps = {
    onChange: () => {},
    periods: [],
    title: null,
    isEditable: true,
    showRequiredOutline: false,
    showRequiredStars: false,
};

export default Periods;
