import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Card, CardBody, CardText, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getApprovalWorkflow, submitActivity, submitTaskApproval, recallActivity, appJect } from '../utils/api';
import Loading from '../pages/loading';
import { FormField } from '../components/forms';

const TaskApproval = (props) => {
    const {
        activityData,
        approvalTaskID,
        fetchSubTab,
        canSubmitApproval,
        taskApprovalsTabUrl,
        setShowRequiredOutline,
        setSaveActivity,
        setSaveActivityCallback,
        campaignTabData,
    } = props;
    const [approvalWorkflow, setApprovalWorkflow] = useState(null);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [commentSubmitting, setCommentSubmitting] = useState(false);
    const [fieldsByKey, setFieldsByKey] = useState({});
    const [canSubmit, setCanSubmit] = useState(false);
    const [showRequiredWarning, setShowRequiredWarning] = useState(false);
    const [checkedCanSubmit, setCheckedCanSubmit] = useState(false);
    const [docsComplete, setDocsComplete] = useState(false);

    useEffect(() => {
        let passed = true;
        if (campaignTabData && campaignTabData.documents && campaignTabData.documents.length) {
            campaignTabData.documents.map((doc) => {
                if (doc.isRequired && (!doc.containsUploadedFile || doc.containsUploadedFile.length === 0)) {
                    passed = false;
                }

                return null;
            });
        }
        setDocsComplete(passed);
        if (passed) {
            setCheckedCanSubmit(false);
        }
    }, [campaignTabData]);

    const checkCanSubmit = (passedData) => {
        let canUserSubmit = true;
        const data = passedData || fieldsByKey;
        Object.keys(data).map((key) => {
            if (canUserSubmit) {
                canUserSubmit = data[key].value !== null;
            }

            return null;
        });
        setCanSubmit(canUserSubmit);
    };

    const workflowCallback = useCallback(
        async () => {
            const approvalID = approvalTaskID || activityData.id;

            const data = await getApprovalWorkflow(approvalID);
            const initFieldsByKey = {};
            if (data && data.approvalPaths) {
                data.approvalPaths.map((path) => {
                    path.approvalStages.map((stage) => {
                        stage.fields.map((field) => {
                            if (field.value) {
                                // initFieldsByKey[field.name] = field.value;
                            }
                            initFieldsByKey[field.name] = field;

                            return null;
                        });

                        return null;
                    });

                    return null;
                });
            }
            if (data.isApproved) {
                fetchSubTab();
            }
            setWidgetLoading(false);
            checkCanSubmit(initFieldsByKey);
            setFieldsByKey(initFieldsByKey);
            setApprovalWorkflow(data);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setApprovalWorkflow, checkCanSubmit]
    );

    useEffect(
        () => {
            setSaveActivityCallback(() => {
                // finish the save here
                window.setTimeout(async () => {
                    await submitActivity(activityData.id);
                    await workflowCallback();
                    setSubmitting(false);
                    setSaveActivity(false);
                    setShowRequiredOutline(false);
                    setShowRequiredWarning(false);
                    setCheckedCanSubmit(false);
                }, 100);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const activate = () => {
        if (canSubmitApproval && docsComplete) {
            setSubmitting(true);
            window.setTimeout(async () => {
                await submitTaskApproval(approvalTaskID);
                await workflowCallback();
                setSubmitting(false);
            }, 100);
        } else {
            setShowRequiredWarning(true);
            setCheckedCanSubmit(true);
            setShowRequiredOutline(true);
            window.setTimeout(() => {
                toast.dismiss();
                toast.error(
                    !docsComplete ? 'Please upload all required documents before submitting for approval.' : 'Required form fields are missing.'
                );
                const errors = document.getElementsByClassName('is-invalid');
                if (errors.length) {
                    errors[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 100);
        }
    };

    useEffect(
        () => {
            workflowCallback();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [approvalTaskID]
    );

    const onFormChange = (updatedField) => {
        setFieldsByKey((prev) => {
            const updatedFormValues = { ...prev };
            updatedFormValues[updatedField.name] = updatedField;

            return updatedFormValues;
        });
        setTimeout(() => checkCanSubmit(), 100);
    };

    const commentText = useRef(null);

    const appJectThis = (type) => {
        setCommentSubmitting(true);
        window.setTimeout(async () => {
            const fieldData = {};
            Object.keys(fieldsByKey).map((key) => {
                fieldData[`DE:${key}`] = fieldsByKey[key].value;

                return null;
            });
            await appJect(approvalTaskID, commentText.current.value.trim(), fieldData, type);
            await workflowCallback();

            setSubmitting(false);
            setCommentSubmitting(false);
        }, 100);
    };

    const recall = () => {
        setSubmitting(true);
        window.setTimeout(async () => {
            await recallActivity(approvalTaskID);
            await workflowCallback();
            setSubmitting(false);
        }, 100);
    };

    return (
        <Card>
            <CardBody>
                <div
                    className="d-flex justify-content-center align-items-center mb-3 clickable no-outline"
                    onClick={() => window.open(taskApprovalsTabUrl)}
                    onKeyDown={() => window.open(taskApprovalsTabUrl)}
                    role="button"
                    tabIndex={0}
                >
                    <div className="w-100">
                        <h4 className="mb-0">Approval Workflow</h4>
                    </div>
                    <div className="hover-primary">
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                    </div>
                </div>
                {widgetLoading ? (
                    <div style={{ height: '150px', position: 'relative' }}>
                        <Loading />
                    </div>
                ) : null}
                {approvalWorkflow && approvalWorkflow.approvalPaths && approvalWorkflow.approvalPaths.length ? (
                    <div className="workflow">
                        {approvalWorkflow.approvalPaths.map((path) => (
                            <div className="approval-path" key={`path:${path.name}`}>
                                <h6 className="mt-3">{path.name}</h6>
                                {path.approvalStages.map((stage) => (
                                    <div className="d-flex approval-stage flex-column" key={`stage:${stage.name}`}>
                                        <div className="d-flex">
                                        <div>
                                                <div className={`rounded-circle approval-circle status-${stage.approvalStatus}`} />
                                            </div>
                                            <div className="text-secondary pl-2 w-100">
                                                <h6 className="mb-0 pb-2">{stage.name}</h6>
                                                {stage.approvalType === 'AM' ?
                                                    stage.approvers.map((approver) => (
                                                        <div className="d-flex">
                                                            <div>
                                                                <div className={`rounded-circle approval-circle-small status-${approver.status}`} />
                                                            </div>
                                                            <small className="text-secondary pl-1 w-100">{approver.name}<br></br></small></div>
                                                    ))
                                                    :
                                                    (
                                                        <div className="d-flex"> <small className="text-secondary pl-1 w-100">{stage.approvers.map((approver) => (approver.name)).join(', ')}<br></br></small></div>
                                                    )}
                                            </div>
                                        </div>
                                        {stage.approvableByUser ? (
                                            <div className="mt-2" style={{ position: 'relative' }}>
                                                {commentSubmitting ? (
                                                    <Loading
                                                        style={{ position: 'absolute', backgroundColor: 'white', opacity: '0.9', zIndex: '2' }}
                                                    />
                                                ) : null}
                                                <form>
                                                    {stage.fields.map((field) => (
                                                        <FormField
                                                            field={field}
                                                            key={`${field.id}`}
                                                            onChange={onFormChange}
                                                            fieldsByKey={fieldsByKey}
                                                        />
                                                    ))}
                                                </form>
                                                <Input type="textarea" innerRef={commentText} placeholder="Add a comment..." className="mb-2" />
                                                <Button
                                                    disabled={!canSubmit}
                                                    size="sm"
                                                    className="mr-2 approve-btn mb-3"
                                                    onClick={() => appJectThis('approve')}
                                                >
                                                    Approve
                                                </Button>
                                                <Button size="sm" className="mr-2 reject-btn mb-3" onClick={() => appJectThis('reject')}>
                                                    Reject
                                                </Button>
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {approvalWorkflow.submittableByUser ? (
                            <>
                                <Button
                                    disabled={showRequiredWarning && checkedCanSubmit && !canSubmitApproval}
                                    onClick={activate}
                                    color="primary"
                                    outline
                                    size="sm"
                                    className={`mt-3${submitting ? ' thinking' : ''}`}
                                >
                                    {approvalWorkflow.resubmittableByUser ? <span>Resubmit</span> : <span>Submit</span>}
                                </Button>
                                {showRequiredWarning && !canSubmitApproval ? (
                                    <CardText className="text-left text-danger mt-3">Required form fields are missing.</CardText>
                                ) : null}
                            </>
                        ) : null}
                        {approvalWorkflow.recallableByUser ? (
                            <Button onClick={recall} color="primary" outline size="sm" className={`mt-3${submitting ? ' thinking' : ''}`}>
                                <span>Recall</span>
                            </Button>
                        ) : null}
                    </div>
                ) : !widgetLoading ? (
                    <h6 className="mt-3">None</h6>
                ) : null}
            </CardBody>
        </Card>
    );
};

TaskApproval.propTypes = {
    activityData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    campaignTabData: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]).isRequired,
    canSubmitApproval: PropTypes.bool,
    setShowRequiredOutline: PropTypes.func,
    setSaveActivity: PropTypes.func,
    setSaveActivityCallback: PropTypes.func,
    updateActivitiesShortStop: PropTypes.func.isRequired,
};
TaskApproval.defaultProps = {
    canSubmitApproval: false,
    setShowRequiredOutline: () => {},
    setSaveActivity: () => {},
    setSaveActivityCallback: () => {},
};

export default TaskApproval;
