import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { createCampaign } from '../utils/api';
import Loading from './loading';
import DateTime from '../components/forms/datetime';

const NewCampaign = (props) => {
    const { marketPlanId, history, activityId, activityData, setActivitiesTab, refreshActivity } = props;
    const [step] = useState('start');
    const [loading, setLoading] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [campaignName, setCampaignName] = useState({});
    const [plannedStartDate, setPlannedStartDate] = useState(new Date());

    const campaignNameChange = (name) => {
        setCampaignName(name.target.value);
        setCanSubmit(campaignName);
    };

    const plannedStartDateChange = (date) => {
        setPlannedStartDate(date);
    };

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'Create a New Campaign',
                }}
            />
            <div className="d-flex flex-column" style={{ position: 'relative', minHeight: '500px' }}>
                {loading ? <Loading style={{ position: 'absolute', zIndex: '1', opacity: '0.6', backgroundColor: 'white' }} /> : null}

                {step === 'start' ? (
                    <>
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <h2 className="mt-5">Create a New Campaign for {activityData ? activityData.name : ''}</h2>
                                <p />
                            </Col>
                        </Row>
                        <Row style={{ paddingBottom: '50px' }} />
                        <Row className="justify-content-center">
                            <Col sm={1} />
                            <Col sm={5}>
                                <FormGroup>
                                    <Label for="campaign">Campaign Name</Label>
                                    <Input innerRef={campaignName} type="text" name="campaign" id="campaign" onChange={campaignNameChange} />
                                    <p />
                                    <Label for="campaign">Planned Start Date</Label>
                                    <DateTime
                                        value={plannedStartDate}
                                        name="plannedStartDate"
                                        id="plannedStartDate"
                                        onChange={plannedStartDateChange}
                                    />
                                </FormGroup>
                                <Button
                                    disabled={!canSubmit}
                                    color="primary"
                                    outline
                                    onClick={() => {
                                        setLoading(true);
                                        const newCampaign = {
                                            name: campaignName,
                                            plannedStartDate,
                                            activityId,
                                        };

                                        createCampaign(newCampaign).then(() => {
                                            setActivitiesTab('new');
                                            history.push(`/marketPlan/${marketPlanId}/activity/${activityId}`);
                                            setLoading(false);
                                            setTimeout(refreshActivity, 500);
                                        });
                                    }}
                                >
                                    Generate New
                                </Button>
                            </Col>
                            <Col sm={5} />
                        </Row>
                    </>
                ) : null}
            </div>
        </>
    );
};

NewCampaign.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
    marketPlanId: PropTypes.string.isRequired,
    activityId: PropTypes.string.isRequired,
    setActivitiesTab: PropTypes.func,
    activityData: PropTypes.objectOf(PropTypes.any),
    refreshActivity: PropTypes.func,
};
NewCampaign.defaultProps = {
    setActivitiesTab: () => {},
    activityData: {},
    refreshActivity: () => {},
};

export default NewCampaign;
