import React from 'react';
import Helmet from 'react-helmet';

const NotFound = () => (
    <>
        <Helmet
            htmlAttributes={{
                'data-page': '404',
            }}
            title="404 - Page Not Found"
            meta={[
                {
                    name: 'robots',
                    content: 'noindex',
                },
            ]}
        />
        <div className="container h-100">
            <div className="row justify-content-center align-items-center h-100">
                <div className="col-12 col-md-8 text-center">
                    <h1 style={{ fontSize: '10rem' }}>404</h1>
                    <h2>These are not the droids you are looking for.</h2>
                </div>
            </div>
        </div>
    </>
);

export default NotFound;
