import React, { forwardRef } from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const { allToggleState, onToggle, onToggleAll, checked, toggleAll, data } = rest;
    let checkedIcon = checked ? ['fa', 'check-square'] : ['far', 'square'];
    let onChange = onToggle;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    if (toggleAll) {
        onChange = onToggleAll;
        const selectedLength = allToggleState();
        if (selectedLength === data.length) {
            checkedIcon = ['fa', 'check-square'];
        } else if (selectedLength) {
            checkedIcon = ['fa', 'minus-square'];
        } else {
            checkedIcon = ['far', 'square'];
        }
    }

    return (
        <div type="checkbox" ref={resolvedRef} onClick={onChange} checked={checked} onKeyDown={onChange} role="none">
            <FontAwesomeIcon icon={checkedIcon} className="clickable" />
        </div>
    );
});
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';
IndeterminateCheckbox.propTypes = {
    indeterminate: PropTypes.bool.isRequired,
};

const DataTable = ({ columns, data, onToggle, onToggleAll, allToggleState, setSortedColumn, canToggleAll, selectedActivityIds }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            autoResetSortBy: false,
        },
        useSortBy,
        useRowSelect,
        (hooks) => {
            // eslint-disable-next-line no-shadow
            (hooks.visibleColumns || hooks.flatColumns || []).push((columns) => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    disableSortBy: true,
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    // eslint-disable-next-line react/display-name
                    Header: ({ getToggleAllRowsSelectedProps }) =>
                        canToggleAll ? (
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                                toggleAll
                                data={data}
                                onToggleAll={onToggleAll}
                                allToggleState={allToggleState}
                            />
                        ) : null,
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    // eslint-disable-next-line react/display-name
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                                onToggle={() => onToggle(row.original.id, !row.original.isSelected)}
                            />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );

    return (
        <Table {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup, count) => {
                    const newCount = count;

                    return (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup:${newCount}`}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="text-left"
                                    key={`headerGroup:${column.id || column.accessor}`}
                                    onMouseDown={() => setSortedColumn(column)}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <FontAwesomeIcon icon={['fa', 'caret-down']} className="ml-2" />
                                            ) : (
                                                <FontAwesomeIcon icon={['fa', 'caret-up']} className="ml-2" />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    );
                })}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    const key = row.original.id;

                    return (
                        <tr
                            {...row.getRowProps()}
                            key={key}
                            className={row.isSelected || selectedActivityIds.indexOf(row.original.id) > -1 ? 'selected-row' : ''}
                        >
                            {row.cells.map((cell) => (
                                <td {...cell.getCellProps()} className="text-left" key={`data-column-${cell.column.id || cell.column.accessor}`}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

DataTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    onToggle: PropTypes.func,
    onToggleAll: PropTypes.func,
    allToggleState: PropTypes.func,
    setSortedColumn: PropTypes.func,
    sortedColumn: PropTypes.objectOf(PropTypes.any),
    row: PropTypes.objectOf(PropTypes.any),
    canToggleAll: PropTypes.bool,
    selectedActivityIds: PropTypes.arrayOf(PropTypes.string),
};

DataTable.defaultProps = {
    columns: [],
    data: [],
    onToggle: () => {},
    onToggleAll: () => {},
    allToggleState: () => {},
    setSortedColumn: () => {},
    sortedColumn: null,
    row: {},
    canToggleAll: true,
    selectedActivityIds: [],
};

export default DataTable;
