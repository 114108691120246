import React, { useState } from 'react';
import { Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Toolbar = (props) => {
    const {
        selectedActivities,
        marketPlanId,
        history,
        marketPlans,
        marketPlanData,
        externalLinks,
        activityId,
        setBudgetTransfer,
        budgetTransfer,
        setSelectedActivities,
    } = props;

    const [marketPlanDropdownOpen, setCampiagnDropDownOpen] = useState(false);
    const [externalLinkDropDownOpen, setExternalLinkDropDownOpen] = useState(false);
    const marketPlanToggle = () => setCampiagnDropDownOpen(!marketPlanDropdownOpen);
    const externalLinkToggle = () => setExternalLinkDropDownOpen(!externalLinkDropDownOpen);

    return (
        <Row className="flex-shrink-1 py-2" id="header">
            <Col>
                <Button
                    className="mr-3 align-self-center border-0"
                    color={activityId === 'new' ? 'primary' : 'secondary'}
                    outline
                    onClick={() => {
                        setBudgetTransfer(false);
                        setSelectedActivities([]);
                        history.push(`/marketPlan/${marketPlanId}/activity/new`);
                    }}
                >
                    <FontAwesomeIcon icon={['fa', 'plus']} />
                    <span>New Activity</span>
                </Button>
                <Button
                    className="mr-3 align-self-center border-0"
                    color={activityId === 'search' ? 'primary' : 'secondary'}
                    outline
                    onClick={() => {
                        setBudgetTransfer(false);
                        setSelectedActivities([]);
                        history.push(`/marketPlan/${marketPlanId}/activity/search`);
                    }}
                >
                    <FontAwesomeIcon icon={['fas', 'search']} />
                    <span>Find Activities</span>
                </Button>
                {1 === 2 && activityId !== null && activityId !== 'new' && activityId !== 'search' && selectedActivities.length === 0 ? (
                    <Button
                        className="align-self-center border-0"
                        color={budgetTransfer ? 'primary' : 'secondary'}
                        outline
                        onClick={() => setBudgetTransfer(!budgetTransfer)}
                    >
                        <FontAwesomeIcon icon={['fas', 'dollar-sign']} />
                        <span>Budget Transfer</span>
                    </Button>
                ) : null}
            </Col>
            <Col className="text-right">
                <ButtonDropdown isOpen={marketPlanDropdownOpen} toggle={marketPlanToggle}>
                    <DropdownToggle caret color="secondary" outline className="border-0">
                        {marketPlanData ? marketPlanData.name : ''}
                    </DropdownToggle>
                    <DropdownMenu right>
                        {marketPlans.map((marketPlan) => (
                            <DropdownItem
                                key={`marketPlan:${marketPlan.id}`}
                                onClick={() => {
                                    setBudgetTransfer(false);
                                    history.push(`/marketPlan/${marketPlan.id}`);
                                }}
                                className={`list-group-item-action${marketPlan.id === marketPlanId ? ' active' : ''}`}
                            >
                                {marketPlan.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown isOpen={externalLinkDropDownOpen} toggle={externalLinkToggle} className="ml-2">
                    <DropdownToggle caret color="secondary" outline className="border-0">
                        <FontAwesomeIcon icon={['fas', 'bars']} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {externalLinks.map((externalLink) => (
                            <DropdownItem key={`externalLink:${externalLink.label}`} onClick={() => window.open(externalLink.url)}>
                                {externalLink.label}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
            </Col>
        </Row>
    );
};

Toolbar.propTypes = {
    selectedActivities: PropTypes.arrayOf(PropTypes.string).isRequired,
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
    marketPlanData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    marketPlans: PropTypes.arrayOf(PropTypes.object),
    externalLinks: PropTypes.arrayOf(PropTypes.object),
    marketPlanId: PropTypes.string,
    activityId: PropTypes.string,
    setBudgetTransfer: PropTypes.func,
    setSelectedActivities: PropTypes.func,
    budgetTransfer: PropTypes.bool,
};
Toolbar.defaultProps = {
    marketPlans: [],
    marketPlanData: {},
    externalLinks: {},
    marketPlanId: '',
    activityId: null,
    setBudgetTransfer: () => {},
    setSelectedActivities: () => {},
    budgetTransfer: false,
};

export default Toolbar;
