import React from 'react';
import { Col, Row } from 'reactstrap';

const NotFound = () => (
    <Row className="flex-grow-1">
        <Col className="flex-column d-flex justify-content-center align-items-stretch text-center">
            <h2 className="text-danger">Error Loading Page</h2>
            <h5>Please refresh to try again</h5>
        </Col>
    </Row>
);

export default NotFound;
