import React, { useContext, useState } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, ListGroup, ListGroupItem, Button, FormGroup, Label, Input, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import PropTypes from 'prop-types';
import { AppContext } from '../context';

const tabs = ['activities', 'approvals', 'new'];

const ActivitiesList = (props) => {
    const {
        setSelectedActivities,
        selectedActivities,
        marketPlanId,
        marketPlanData,
        activityId,
        setActivityId,
        newActivities,
        approvals,
        refreshTab,
        setBudgetTransfer,
        tab,
    } = props;
    const defaultFilters = {
        activityName: '',
        activityRegion: '',
        activitySourceMarket: '',
        activityFunctionalArea: '',
        activityStatus: '',
        activityType: '',
    };
    const regions = ['Africa', 'Americas', 'Asia Pac', 'Europe', 'Proximity Markets'];
    const [activeTab, setActiveTab] = useState(tab);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState(defaultFilters);
    const appData = useContext(AppContext);

    const toggleTab = (newTab) => {
        if (activeTab !== newTab) {
            setActiveTab(newTab);
            setSelectedActivities([]);
            setBudgetTransfer(false);
            props.history.replace(`/marketplan/${marketPlanId}`);
            if (newTab === 'activities' || newTab === 'approvals') {
                refreshTab(newTab);
            }
        }
    };

    if (activityId && activeTab !== 'new') {
        newActivities.map((newActivity) => {
            if (activityId === newActivity.id) {
                setActiveTab('new');
            }

            return null;
        });
    }

    const toggleActivity = (id, select) => {
        const selectedClone = selectedActivities.slice(0);
        if (activityId) {
            selectedClone.push(activityId);
        }
        props.history.replace(`/marketplan/${marketPlanId}`);
        if (!activityId && select) {
            selectedClone.push(id);
        } else {
            selectedClone.splice(selectedActivities.indexOf(id), 1);
        }
        setSelectedActivities(selectedClone);
    };

    // good spot to see if the filters should be active
    let displayActivities = [];
    switch (activeTab) {
        case 'activities':
            displayActivities = get(marketPlanData, 'activities', []).filter((activity) => {
                let pass = true;

                if (pass && filters.activityName !== '' && activity.name.toLowerCase().indexOf(filters.activityName.toLowerCase()) === -1) {
                    pass = false;
                }
                if (pass && filters.activityRegion !== '' && activity.regions.indexOf(filters.activityRegion) === -1) {
                    pass = false;
                }
                if (pass && filters.activitySourceMarket !== '' && activity.sourceMarkets.indexOf(filters.activitySourceMarket) === -1) {
                    pass = false;
                }
                if (pass && filters.activityFunctionalArea !== '' && activity.activityOwner !== filters.activityFunctionalArea) {
                    pass = false;
                }
                if (pass && filters.activityType !== '' && activity.activityType !== filters.activityType) {
                    pass = false;
                }
                if (pass && filters.activityStatus !== '' && activity.statusLabel !== filters.activityStatus) {
                    pass = false;
                }

                return pass ? activity : null;
            });
            break;
        case 'approvals':
            displayActivities = approvals;
            break;
        case 'new':
            displayActivities = newActivities;
            break;
        default:
            break;
    }

    const updateFilter = (name, value) => {
        setFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            newFilters[name] = value;
            if (name === 'activityFunctionalArea') {
                newFilters.activityType = '';
            }

            return newFilters;
        });
    };

    let hasFilters = false;
    Object.keys(defaultFilters).map((filterName) => {
        if (!hasFilters && filters[filterName] !== '') {
            hasFilters = true;
        }

        return null;
    });

    const clickedActivity = (activity) => {
        const selected = [];
        let link = `/marketplan/${props.marketPlanId}/activity/${activity.id}`;
        selected.push(activity.id);
        setSelectedActivities(selected);
        setActivityId(activity.id);
        setBudgetTransfer(false);

        if (activity.approvalUrls) {
            // eslint-disable-next-line prefer-destructuring
            link = activity.approvalUrls[Object.keys(activity.approvalUrls)[0]];
        }
        props.history.push(link);
    };

    const renderActivity = (activity, count) => (
        <ListGroupItem
            className={`border-left-0 border-right-0 p-0 list-group-item-action rounded-0${
                (selectedActivities.length > 0 && selectedActivities.indexOf(activity.id) > -1) ||
                (selectedActivities.length === 0 && activity.id === activityId)
                    ? ' active'
                    : ''
            }${count + 1 === (displayActivities.activities || []).length ? ' border-bottom-0' : ''}`}
            key={`${activity.id}`}
        >
            <div className="d-flex">
                <div className="p-3">
                    {selectedActivities.indexOf(activity.id) === -1 && activity.id !== activityId ? (
                        <FontAwesomeIcon
                            icon={['far', 'square']}
                            onClick={() => toggleActivity(activity.id, true)}
                            className="clickable align-top mt-1"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={['fa', 'check-square']}
                            onClick={() => (activity.id !== activityId ? toggleActivity(activity.id, false) : null)}
                            className="clickable align-top mt-1"
                        />
                    )}
                </div>
                <div
                    onClick={() => clickedActivity(activity)}
                    onKeyDown={() => clickedActivity(activity)}
                    role="button"
                    tabIndex={0}
                    className="clickable pt-3 pr-3 pb-3 w-100 no-outline"
                >
                    <h6 style={{textDecoration: activity.status === 'IFV' ?  'line-through' : 'none'}} className="mb-0 d-inline">{activity.name}</h6>
                    <small className="d-inline ml-3 float-right">{activity.statusLabel}</small>
                    <br />
                    <small>{activity.sourceMarketLabel}</small>
                    <br />
                    <small>{activity.activityOwner}</small>
                </div>
            </div>
        </ListGroupItem>
    );

    return (
        <>
            <Nav tabs style={{ backgroundColor: 'white', zIndex: 3 }}>
                {tabs.map((mappedTab) => (
                    <NavItem key={`${mappedTab}`}>
                        <NavLink className={activeTab === mappedTab ? 'active' : ''} onClick={() => toggleTab(mappedTab)}>
                            {mappedTab === 'new' ? 'Recent' : mappedTab.toUpperCase()}
                            {mappedTab !== 'activities' &&
                            ((mappedTab === 'new' && newActivities.length > 0) || (mappedTab === 'approvals' && approvals.length > 0)) ? (
                                <Badge color="primary" className="align-text-top ml-2">
                                    {mappedTab === 'new' ? newActivities.length : mappedTab === 'approvals' ? approvals.length : 0}
                                </Badge>
                            ) : null}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <Row className="mx-0 py-2 flex-shrink-1" style={{ zIndex: 2, boxShadow: '0px 0.5px 5px 0 rgba(0,0,0,0.25)' }}>
                <Col sm="12" className="p-0">
                    {!showFilters ? (
                        <div className="d-flex">
                            <div className="px-3 d-flex align-self-center">
                                {selectedActivities.length ? (
                                    <FontAwesomeIcon icon={['fa', 'minus-square']} onClick={() => setSelectedActivities([])} className="clickable" />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={['far', 'square']}
                                        onClick={() => props.setSelectedActivities(displayActivities.map((activity) => activity.id))}
                                        className={displayActivities.length ? 'clickable' : ''}
                                        style={{ opacity: displayActivities.length ? '1' : '.25' }}
                                    />
                                )}
                            </div>
                            <div className="w-100  d-flex align-self-center">
                                {`${displayActivities.length} Item${displayActivities.length !== 1 ? 's' : ''}`}
                            </div>
                            <div className="px-3">
                                <Button
                                    className={`border-0 text-nowrap no-outline${activeTab !== 'activities' ? ' invisible' : ''}`}
                                    color={hasFilters ? 'primary' : 'secondary'}
                                    outline
                                    onClick={() => setShowFilters(true)}
                                >
                                    <FontAwesomeIcon icon={['fa', 'filter']} />
                                    <span>Filter</span>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex">
                            <div className="px-3 d-flex align-self-center mr-auto">
                                <Button className="border-0 text-nowrap px-0" color="link" onClick={() => setShowFilters(false)}>
                                    <FontAwesomeIcon icon={['fa', 'chevron-left']} />
                                    <span>List</span>
                                </Button>
                            </div>
                            <div className="px-3 d-flex align-self-center">
                                <Button className="border-0 text-nowrap" color="secondary" outline onClick={() => setFilters(defaultFilters)}>
                                    <span>Reset</span>
                                </Button>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
            {!showFilters ? (
                <TabContent activeTab={activeTab} className="d-flex flex-grow-1 d-flex">
                    <TabPane tabId="activities" className="flex-grow-1 flex-column">
                        <Row className="flex-grow-1 h-100 m-0">
                            <Col className="flex-grow-1 d-flex flex-column position-relative p-0">
                                <ListGroup
                                    className="border-0"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        bottom: '0',
                                        overflowY: 'auto',
                                        right: '0',
                                        left: '0',
                                        overscrollBehavior: 'contain',
                                    }}
                                >
                                    {displayActivities.map(renderActivity)}
                                </ListGroup>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="approvals" className="flex-grow-1 flex-column">
                        <Row className="flex-grow-1 h-100 m-0">
                            <Col className="flex-grow-1 d-flex flex-column position-relative p-0">
                                <ListGroup
                                    className="border-0"
                                    style={{ position: 'absolute', top: '0', bottom: '0', overflowY: 'auto', right: '0', left: '0' }}
                                >
                                    {displayActivities.map(renderActivity)}
                                </ListGroup>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="new" className="flex-grow-1 flex-column">
                        <Row className="flex-grow-1 h-100 m-0">
                            <Col className="flex-grow-1 d-flex flex-column position-relative p-0">
                                <ListGroup
                                    className="border-0"
                                    style={{ position: 'absolute', top: '0', bottom: '0', overflowY: 'auto', right: '0', left: '0' }}
                                >
                                    {displayActivities.map(renderActivity)}
                                </ListGroup>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            ) : (
                <Row className="flex-grow-1 h-100 m-0">
                    <Col className="flex-grow-1 d-flex flex-column position-relative">
                        <div className="px-3 pt-3" style={{ position: 'absolute', top: '0', bottom: '0', right: '0', left: '0', overflowY: 'auto' }}>
                            <FormGroup>
                                <Label for="activityName">Activity Name</Label>
                                <Input
                                    type="text"
                                    name="activityName"
                                    id="activityName"
                                    placeholder="Search for name"
                                    onChange={(e) => updateFilter('activityName', e.target.value)}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowFilters(false);
                                        }
                                    }}
                                    value={filters.activityName}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="activityRegion">Region</Label>
                                <Input
                                    type="select"
                                    name="activityRegion"
                                    id="activityRegion"
                                    onChange={(e) => updateFilter('activityRegion', e.target.value)}
                                    value={filters.activityRegion}
                                >
                                    <option value=""> </option>
                                    {regions.map((region) => (
                                        <option key={region} value={region}>
                                            {region}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="activitySourceMarket">Source Market</Label>
                                <Input
                                    type="select"
                                    name="activitySourceMarket"
                                    id="activitySourceMarket"
                                    onChange={(e) => updateFilter('activitySourceMarket', e.target.value)}
                                    value={filters.activitySourceMarket}
                                >
                                    <option value=""> </option>
                                    {appData.sourceMarkets.map((sourceMarket) => (
                                        <option key={sourceMarket.name} value={sourceMarket.name}>
                                            {sourceMarket.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="activityFunctionalArea">Functional Area</Label>
                                <Input
                                    type="select"
                                    name="activityFunctionalArea"
                                    id="activityFunctionalArea"
                                    onChange={(e) => updateFilter('activityFunctionalArea', e.target.value)}
                                    value={filters.activityFunctionalArea}
                                >
                                    <option value=""> </option>
                                    {appData.functionalAreas.map((functionalArea) => (
                                        <option key={functionalArea.name} value={functionalArea.name}>
                                            {functionalArea.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            {filters.activityFunctionalArea !== '' ? (
                                <FormGroup>
                                    <Label for="activityType">Activity Type</Label>
                                    <Input
                                        type="select"
                                        name="activityType"
                                        id="activityType"
                                        onChange={(e) => updateFilter('activityType', e.target.value)}
                                        value={filters.activityType}
                                    >
                                        <option value=""> </option>
                                        {keyBy(appData.functionalAreas, 'name')[filters.activityFunctionalArea].activityTypes.map((activityType) => (
                                            <option key={activityType.name} value={activityType.name}>
                                                {activityType.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            ) : null}
                            <FormGroup>
                                <Label for="activityStatus">Status</Label>
                                <Input
                                    type="select"
                                    name="activityStatus"
                                    id="activityStatus"
                                    onChange={(e) => updateFilter('activityStatus', e.target.value)}
                                    value={filters.activityStatus}
                                >
                                    <option value=""> </option>
                                    {appData.statusList.map((filterStatus) => (
                                        <option key={filterStatus.label} value={filterStatus.label}>
                                            {filterStatus.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <div className="text-right">
                                <Button className="text-nowrap" color="primary" outline onClick={() => setShowFilters(false)}>
                                    <span>Apply</span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

ActivitiesList.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
    setSelectedActivities: PropTypes.func.isRequired,
    selectedActivities: PropTypes.arrayOf(PropTypes.string).isRequired,
    marketPlanData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    marketPlanId: PropTypes.string.isRequired,
    activityId: PropTypes.string,
    newActivities: PropTypes.arrayOf(PropTypes.object),
    approvals: PropTypes.arrayOf(PropTypes.object),
    refreshTab: PropTypes.func,
    setBudgetTransfer: PropTypes.func,
    tab: PropTypes.string,
};
ActivitiesList.defaultProps = {
    activityId: '',
    newActivities: [],
    approvals: [],
    refreshTab: () => {},
    setBudgetTransfer: () => {},
    tab: 'activities',
};

export default ActivitiesList;
