/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Row, Col, Table as BSTable, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EditorJs from 'react-editor-js';
// eslint-disable-next-line no-unused-vars
import Table from '@editorjs/table';
import Header from '@editorjs/header';
import EditorJsTable from '../editorjs-table/plugin';

const StyledEditorContainer = styled.div`
    .codex-editor--narrow .codex-editor__redactor {
        margin-right: 0;
    }
    .ce-toolbar .ce-toolbar__plus {
        display: none !important;
    }
    .ce-paragraph.cdx-block {
        display: none !important;
    }
    .codex-editor__redactor {
        padding-bottom: 0 !important;
    }
    .ce-block__content {
        max-width: 100%;
    }
    .ce-toolbar__settings-btn {
        margin-right: -15px;
    }
    .ce-toolbar--opened {
        display: none !important;
    }
    &.no-tools {
        .tc-toolbar--ver,
        .tc-toolbar--hor {
            display: none;
        }
    }
`;

const EmbeddedContent = (props) => {
    const { embeddedContent, onChange, isEditable } = props;

    const [localEmbeddedContent, setLocalEmbeddedContent] = useState(embeddedContent);

    useEffect(() => {
        setLocalEmbeddedContent(embeddedContent);
    }, [embeddedContent]);

    useEffect(() => {
        onChange(localEmbeddedContent);
    }, [localEmbeddedContent]);

    return embeddedContent.length ? (
        <Row>
            <Col>
                <fieldset>
                    <FormGroup>
                        <Row form className="mb-3">
                            <Col>
                                {embeddedContent.map((content, count) => (
                                    <div key={`embeddedContent:${count.toString()}`}>
                                        <h5 className="mt-3 mb-2 form-header text-uppercase">{content.name}</h5>
                                        <p className="mb-2">{content.description}</p>
                                        {isEditable ? (
                                            <StyledEditorContainer className={content.name.toLowerCase() === 'customer journey' ? 'no-tools' : ''}>
                                                <EditorJs
                                                    data={content.contentJson}
                                                    tools={{ table: { class: EditorJsTable, inlineToolbar: false }, header: Header }}
                                                    onChange={(api, newData) => {
                                                        setLocalEmbeddedContent((prevContent) =>
                                                            prevContent.map((subContent, index) =>
                                                                index === count ? { ...subContent, updated: true, contentJson: newData } : subContent
                                                            )
                                                        );
                                                    }}
                                                />
                                            </StyledEditorContainer>
                                        ) : (
                                            <BSTable>
                                                {content.contentJson.blocks.map((block) =>
                                                    block.data.content.map((row, rowIndex) => (
                                                        <tr key={`row:${rowIndex.toString()}`}>
                                                            {row.map((col, colIndex) => (
                                                                <td key={`col:${colIndex.toString()}`}>{col}</td>
                                                            ))}
                                                        </tr>
                                                    ))
                                                )}
                                            </BSTable>
                                        )}
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </FormGroup>
                </fieldset>
            </Col>
        </Row>
    ) : null;
};

EmbeddedContent.propTypes = {
    onChange: PropTypes.func,
    isEditable: PropTypes.bool,
    embeddedContent: PropTypes.arrayOf(PropTypes.object),
};
EmbeddedContent.defaultProps = {
    onChange: () => {},
    isEditable: true,
    embeddedContent: [],
};

export default EmbeddedContent;
