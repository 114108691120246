import React from 'react';
import { ListGroup, ListGroupItem, Card, CardBody, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const Documents = (props) => {
    const { activityData } = props;

    return (
        <Card>
            <CardBody>
                <div
                    className="d-flex justify-content-center align-items-center mb-3 clickable no-outline"
                    onClick={() => window.open(activityData.details.taskDocumenetsTabUrl)}
                    onKeyDown={() => window.open(activityData.details.taskDocumenetsTabUrl)}
                    role="button"
                    tabIndex={0}
                >
                    <div className="w-100">
                        <h4 className="mb-0 d-inline-block mr-2">Documents</h4>
                        <Badge color="primary" className="align-text-top">
                            {activityData.details && activityData.details.documents ? activityData.details.documents.length : 0}
                        </Badge>
                    </div>
                    <div className="hover-primary">
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                    </div>
                </div>

                <ListGroup>
                    {activityData.details && activityData.details.documents
                        ? activityData.details.documents.map((document) => (
                              <ListGroupItem
                                  key={`document:${document.id}`}
                                  className="d-flex px-0 justify-content-center align-items-center document"
                              >
                                  <div
                                      className="d-flex justify-content-center align-items-center hover-primary clickable no-outline"
                                      onClick={() => window.open(document.viewUrl)}
                                      onKeyDown={() => window.open(document.viewUrl)}
                                      role="button"
                                      tabIndex={0}
                                  >
                                      <div>
                                          <FontAwesomeIcon icon={['fa', 'file']} size="2x" />
                                      </div>
                                      <div className="w-100 pl-2 pr-3" style={{ lineHeight: 1.25 }}>
                                          <small>{document.name}</small>
                                      </div>
                                  </div>
                                  <div
                                      className="clickable no-outline d-flex hover-primary ml-auto"
                                      onClick={() => {
                                          window.location.href = document.downloadUrl;
                                      }}
                                      onKeyDown={() => {
                                          window.location.href = document.downloadUrl;
                                      }}
                                      role="button"
                                      tabIndex={0}
                                  >
                                      <FontAwesomeIcon icon={['fa', 'download']} size="lg" />
                                  </div>
                              </ListGroupItem>
                          ))
                        : null}
                </ListGroup>
            </CardBody>
        </Card>
    );
};

Documents.propTypes = {
    activityData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default Documents;
