import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import CampaignMenuItem from './campaignMenuItem';

const CampaignNav = (props) => {
    const { campaign, fetchCampaigns, activityData, updateActivitiesShortStop, setActivityData, history, match } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('');

    const activeSubTab = match && match.params && match.params.subTab ? match.params.subTab : null;

    const toggle = () => setIsOpen(!isOpen);

    const hasSubTab = (searchName) => campaign.subTabNames.indexOf(searchName) > -1;

    useEffect(() => setActiveTab(activeSubTab || campaign.subTabNames[0]), [activeSubTab, campaign]);

    const createCampaignSubTabUrl = (subTab) => {
        const campaignUrl = props.match.url
            .split('/')
            .slice(0, 6)
            .join('/')
            .toString();

        return campaignUrl + (subTab ? `/${subTab}` : '');
    };

    const goToUrl = (subTab) => {
        history.push(createCampaignSubTabUrl(subTab));
    };

    return (
        <div>
            <Navbar color="light" light expand="md" className="px-2">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto subtab-nav" pills>
                        {hasSubTab('Campaign Strategy') ? (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={activeTab === 'Campaign Strategy' ? 'active rounded-pill' : 'rounded-pill'}
                                    onClick={() => {
                                        setActiveTab('Campaign Strategy');
                                        goToUrl('Campaign Strategy');
                                    }}
                                >
                                    Campaign Strategy
                                </NavLink>
                            </NavItem>
                        ) : null}

                        {hasSubTab('Creative') ? (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={activeTab === 'Creative' ? 'active rounded-pill' : 'rounded-pill'}
                                    onClick={() => {
                                        setActiveTab('Creative');
                                        goToUrl('Creative');
                                    }}
                                >
                                    Creative
                                </NavLink>
                            </NavItem>
                        ) : null}

                        {hasSubTab('Media') ? (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={activeTab === 'Media' ? 'active rounded-pill' : 'rounded-pill'}
                                    onClick={() => {
                                        setActiveTab('Media');
                                        goToUrl('Media');
                                    }}
                                >
                                    Media
                                </NavLink>
                            </NavItem>
                        ) : null}

                        {hasSubTab('Technical') ? (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={activeTab === 'Technical' ? 'active rounded-pill' : 'rounded-pill'}
                                    onClick={() => {
                                        setActiveTab('Technical');
                                        goToUrl('Technical');
                                    }}
                                >
                                    Technical
                                </NavLink>
                            </NavItem>
                        ) : null}
                        {hasSubTab('Search') ? (
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={activeTab === 'Search' ? 'active rounded-pill' : 'rounded-pill'}
                                    onClick={() => {
                                        setActiveTab('Search');
                                        goToUrl('Search');
                                    }}
                                >
                                    Search
                                </NavLink>
                            </NavItem>
                        ) : null}
                    </Nav>
                </Collapse>
            </Navbar>

            {activeTab === 'Campaign Strategy' ? (
                <CampaignMenuItem
                    campaign={campaign}
                    activityData={activityData}
                    name="Campaign Strategy"
                    isEditable
                    updateActivitiesShortStop={updateActivitiesShortStop}
                    setActivityData={setActivityData}
                    fetchCampaigns={fetchCampaigns}
                />
            ) : null}
            {activeTab === 'Media' ? (
                <CampaignMenuItem
                    campaign={campaign}
                    activityData={activityData}
                    name="Media"
                    updateActivitiesShortStop={updateActivitiesShortStop}
                    setActivityData={setActivityData}
                />
            ) : null}
            {activeTab === 'Creative' ? (
                <CampaignMenuItem
                    campaign={campaign}
                    activityData={activityData}
                    name="Creative"
                    updateActivitiesShortStop={updateActivitiesShortStop}
                    setActivityData={setActivityData}
                />
            ) : null}
            {activeTab === 'Technical' ? (
                <CampaignMenuItem
                    campaign={campaign}
                    activityData={activityData}
                    name="Technical"
                    updateActivitiesShortStop={updateActivitiesShortStop}
                    setActivityData={setActivityData}
                />
            ) : null}
            {activeTab === 'Search' ? (
                <CampaignMenuItem
                    campaign={campaign}
                    activityData={activityData}
                    name="Search"
                    updateActivitiesShortStop={updateActivitiesShortStop}
                    setActivityData={setActivityData}
                />
            ) : null}
        </div>
    );
};

CampaignNav.propTypes = {
    campaign: PropTypes.objectOf(PropTypes.any).isRequired,
    activityData: PropTypes.objectOf(PropTypes.any).isRequired,
    updateActivitiesShortStop: PropTypes.func.isRequired,
    setActivityData: PropTypes.func.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    fetchCampaigns: ReactRouterPropTypes.history.isRequired,
};
CampaignNav.defaultProps = {};

export default CampaignNav;
