import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

const AppBundle = (
    <CookiesProvider>
        <BrowserRouter basename="/dtcm">
            <App />
        </BrowserRouter>
    </CookiesProvider>
);

window.onload = () => {
    Loadable.preloadReady().then(() => {
        const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
        renderMethod(AppBundle, document.getElementById('root'));
    });
};

serviceWorker.unregister();
