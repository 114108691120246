import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const Loading = (props) => {
    const { height, style } = props;

    return (
        <>
            <Helmet
                htmlAttributes={{
                    'data-page': 'loading',
                    'data-page-banner': 'false',
                }}
                title="Loading Test"
            />
            <div
                className={`container loading ${height || 'h-100'}`}
                style={{
                    background: 'transparent url(/img/loading.svg) no-repeat center/contain',
                    backgroundSize: 'auto 50%',
                    maxWidth: '100%',
                    ...(style || {}),
                }}
            />
        </>
    );
};

Loading.propTypes = {
    height: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.string),
};
Loading.defaultProps = {
    height: undefined,
    style: undefined,
};

export default Loading;
