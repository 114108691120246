/* eslint-disable no-underscore-dangle */
import './styles/table-constructor.pcss';
import { create, getCoords, getSideByCoords } from './documentUtils';
import { HorizontalBorderToolBar, VerticalBorderToolBar } from './borderToolBar';
import { Table } from './table';

const CSS = {
    editor: 'tc-editor',
    toolBarHor: 'tc-toolbar--hor',
    toolBarVer: 'tc-toolbar--ver',
    inputField: 'tc-table__inp',
};

/**
 * Entry point. Controls table and give API to user
 */
export class TableConstructor {
    /**
     * Creates
     * @param {TableData} data - previously saved data for insert in table
     * @param {object} config - configuration of table
     * @param {object} api - Editor.js API
     */
    constructor(data, config, api) {
        /** creating table */
        this._table = new Table();
        const size = this._resizeTable(data, config);

        this._fillTable(data, size);

        /** creating container around table */
        this._container = create('div', [CSS.editor, api.styles.block], null, [this._table.htmlElement]);

        /** creating ToolBars */
        this._verticalToolBar = new VerticalBorderToolBar();
        this._horizontalToolBar = new HorizontalBorderToolBar();
        this._table.htmlElement.appendChild(this._horizontalToolBar.htmlElement);
        this._table.htmlElement.appendChild(this._verticalToolBar.htmlElement);

        /** Activated elements */
        this._hoveredCell = null;
        this._activatedToolBar = null;
        this._hoveredCellSide = null;

        /** Timers */
        this._plusButDelay = null;
        this._toolbarShowDelay = null;

        this._hangEvents();
    }

    /**
     * returns html element of TableConstructor;
     * @return {HTMLElement}
     */
    get htmlElement() {
        return this._container;
    }

    /**
     * @private
     *
     *  Fill table data passed to the constructor
     * @param {TableData} data - data for insert in table
     * @param {{rows: number, cols: number}} size - contains number of rows and cols
     */
    _fillTable(data, size) {
        if (data.content !== undefined) {
            for (let i = 0; i < size.rows && i < data.content.length; i++) {
                for (let j = 0; j < size.cols && j < data.content[i].length; j++) {
                    // get current cell and her editable part
                    const input = this._table.body.rows[i].cells[j].querySelector(`.${CSS.inputField}`);

                    input.innerHTML = data.content[i][j];
                }
            }
        }
    }

    /**
     * @private
     *
     * resize to match config or transmitted data
     * @param {TableData} data - data for inserting to the table
     * @param {object} config - configuration of table
     * @param {number|string} config.rows - number of rows in configuration
     * @param {number|string} config.cols - number of cols in configuration
     * @return {{rows: number, cols: number}} - number of cols and rows
     */
    _resizeTable(data, config) {
        const isValidArray = Array.isArray(data.content);
        const isNotEmptyArray = isValidArray ? data.content.length : false;
        const contentRows = isValidArray ? data.content.length : undefined;
        const contentCols = isNotEmptyArray ? data.content[0].length : undefined;
        const parsedRows = Number.parseInt(config.rows, 10);
        const parsedCols = Number.parseInt(config.cols, 10);
        // value of config have to be positive number
        const configRows = !Number.isNaN(parsedRows) && parsedRows > 0 ? parsedRows : undefined;
        const configCols = !Number.isNaN(parsedCols) && parsedCols > 0 ? parsedCols : undefined;
        const defaultRows = 2;
        const defaultCols = 2;
        const rows = contentRows || configRows || defaultRows;
        const cols = contentCols || configCols || defaultCols;

        for (let i = 0; i < rows; i++) {
            this._table.addRow();
        }
        for (let i = 0; i < cols; i++) {
            this._table.addColumn();
        }

        return {
            rows,
            cols,
        };
    }

    /**
     * @private
     *
     * Show ToolBar
     * @param {BorderToolBar} toolBar - which toolbar to show
     * @param {number} coord - where show. x or y depending on the grade of the toolbar
     */
    _showToolBar(toolBar, coord) {
        this._hideToolBar();
        this._activatedToolBar = toolBar;
        toolBar.showIn(coord);
    }

    /**
     * @private
     *
     * Hide all of toolbars
     */
    _hideToolBar() {
        if (this._activatedToolBar !== null) {
            this._activatedToolBar.hide();
        }
    }

    /**
     * @private
     *
     * hang necessary events
     */
    _hangEvents() {
        this._container.addEventListener('mouseInActivatingArea', (event) => {
            this._toolbarCalling(event);
        });

        this._container.addEventListener('click', (event) => {
            this._clickToolbar(event);
        });

        this._container.addEventListener('input', () => {
            this._hideToolBar();
        });

        this._container.addEventListener('keydown', (event) => {
            this._containerKeydown(event);
        });

        this._container.addEventListener('mouseout', (event) => {
            this._leaveDetectArea(event);
        });

        this._container.addEventListener('mouseover', (event) => {
            this._mouseEnterInDetectArea(event);
        });
    }

    /**
     * @private
     *
     * detects a mouseenter on a special area
     * @param {MouseEvent} event
     */
    _mouseInActivatingAreaListener(event) {
        this._hoveredCellSide = event.detail.side;
        const areaCoords = getCoords(event.target);
        const containerCoords = getCoords(this._table.htmlElement);

        this._hoveredCell = event.target.closest('TD');

        if (this._hoveredCell === null) {
            const paddingContainer = 11;
            this._hoveredCell = this._container;
            areaCoords.x1 += paddingContainer;
            areaCoords.y1 += paddingContainer;
            areaCoords.x2 -= paddingContainer;
            areaCoords.y2 -= paddingContainer;
        }

        if (this._hoveredCellSide === 'top') {
            this._showToolBar(this._horizontalToolBar, areaCoords.y1 - containerCoords.y1 - 2);
        }
        if (this._hoveredCellSide === 'bottom') {
            this._showToolBar(this._horizontalToolBar, areaCoords.y2 - containerCoords.y1 - 1);
        }
        if (this._hoveredCellSide === 'left') {
            this._showToolBar(this._verticalToolBar, areaCoords.x1 - containerCoords.x1 - 2);
        }
        if (this._hoveredCellSide === 'right') {
            this._showToolBar(this._verticalToolBar, areaCoords.x2 - containerCoords.x1 - 1);
        }
    }

    /**
     * @private
     *
     * Checks elem is toolbar
     * @param {HTMLElement} elem - element
     * @return {boolean}
     */
    _isToolbar(elem) {
        if (!elem || elem === null) {
            return false;
        }

        return !!(elem.closest(`.${CSS.toolBarHor}`) || elem.closest(`.${CSS.toolBarVer}`));
    }

    /**
     * @private
     *
     * Hide toolbar, if mouse left area
     * @param {MouseEvent} event
     */
    _leaveDetectArea(event) {
        if (this._isToolbar(event.relatedTarget)) {
            return;
        }
        clearTimeout(this._toolbarShowDelay);
        this._hideToolBar();
    }

    /**
     * @private
     *
     * Show toolbar when mouse in activation area
     * Showing
     * @param {MouseEvent} event
     */
    _toolbarCalling(event) {
        if (this._isToolbar(event.target)) {
            return;
        }
        clearTimeout(this._toolbarShowDelay);
        this._toolbarShowDelay = setTimeout(() => {
            this._mouseInActivatingAreaListener(event);
        }, 125);
    }

    _getSiblings = (elm) => elm && elm.parentNode && [...elm.parentNode.children].filter((node) => node !== elm);

    /**
     * @private
     *
     * handling clicks on toolbars
     * @param {MouseEvent} event
     */
    _clickToolbar(event) {
        if (!this._isToolbar(event.target)) {
            return;
        }
        let typeCoord;

        if (event.detail.button === 'plus') {
            if (this._activatedToolBar === this._horizontalToolBar) {
                this._addRow();
                typeCoord = 'y';
            } else {
                this._addColumn();
                typeCoord = 'x';
            }
        }
        if (event.detail.button === 'minus') {
            if (this._activatedToolBar === this._horizontalToolBar) {
                this._removeRow();
                typeCoord = 'y';
            } else {
                this._removeColumn();
                typeCoord = 'x';
            }
        }

        /** If event has transmitted data (coords of mouse) */
        const detailHasData = Number.isNaN(event.detail) && event.detail !== null;

        if (detailHasData) {
            const containerCoords = getCoords(this._table.htmlElement);
            let coord;

            if (typeCoord === 'x') {
                coord = event.detail.x - containerCoords.x1;
            } else {
                coord = event.detail.y - containerCoords.y1;
            }
            this._delayAddButtonForMultiClickingNearMouse(coord);
        } else {
            this._hideToolBar();
        }
    }

    /**
     * @private
     *
     * detects button presses when editing a table's content
     * @param {KeyboardEvent} event
     */
    _containerKeydown(event) {
        if (event.keyCode === 13) {
            this._containerEnterPressed(event);
        }
    }

    /**
     * @private
     *
     * Leaves the PlusButton active under mouse
     * The timer gives time to press the button again, before it disappears.
     * While the button is being pressed, the timer will be reset
     * @param {number} coord - coords of mouse. x or y depending on the grade of the toolbar
     */
    _delayAddButtonForMultiClickingNearMouse(coord) {
        this._showToolBar(this._activatedToolBar, coord);
        this._activatedToolBar.hideLine();
        clearTimeout(this._plusButDelay);
        this._plusButDelay = setTimeout(() => {
            this._hideToolBar();
        }, 500);
    }

    /**
     * @private
     *
     * Check if the addition is initiated by the container and which side
     * @returns {number} - -1 for left or top; 0 for bottom or right; 1 if not container
     */
    _getHoveredSideOfContainer() {
        if (this._hoveredCell === this._container) {
            return this._isBottomOrRight() ? 0 : -1;
        }

        return 1;
    }

    /**
     * @private
     *
     * check if hovered cell side is bottom or right. (lefter in array of cells or rows than hovered cell)
     * @returns {boolean}
     */
    _isBottomOrRight() {
        return this._hoveredCellSide === 'bottom' || this._hoveredCellSide === 'right';
    }

    /**
     * Adds row in table
     * @private
     */
    _addRow() {
        const indicativeRow = this._hoveredCell.closest('TR');
        let index = this._getHoveredSideOfContainer();
        if (index === 1) {
            index = indicativeRow.sectionRowIndex;
            // if inserting after hovered cell
            index += this._isBottomOrRight();
        }
        if (this._hoveredCell && this._hoveredCell.className.indexOf('cdx-block') >= 0) {
            index = index === 0 ? -1 : index < 0 ? 0 : index;
        }
        this._table.addRow(index);
    }

    /**
     * @private
     *
     * Adds column in table
     */
    _addColumn() {
        let index = this._getHoveredSideOfContainer();

        if (index === 1) {
            index = this._hoveredCell.cellIndex;
            // if inserting after hovered cell
            index += this._isBottomOrRight();
        }

        if (this._hoveredCell && this._hoveredCell.className.indexOf('cdx-block') >= 0) {
            index = index >= 0 ? -1 : index < 0 ? 0 : index;
        }

        this._table.addColumn(index);
    }

    /**
     * Removes row in table
     * @private
     */
    _removeRow() {
        const indicativeRow = this._hoveredCell.closest('TR');
        let index = 0;

        console.log(indicativeRow);

        if (!indicativeRow) {
            console.log(this._hoveredCellSide);
            index = this._isBottomOrRight() ? this._table._numberOfRows - 1 : index;
        } else {
            index = indicativeRow.sectionRowIndex + (this._isBottomOrRight() ? 1 : 0);
        }

        console.log(index, this._table._numberOfRows);

        index = index === this._table._numberOfRows ? index - 1 : index;

        if (index > -1 && index < this._table._numberOfRows) {
            this._table.removeRow(index);
        }
    }

    /**
     * @private
     *
     * Removes column in table
     */
    _removeColumn() {
        const indicativeRow = this._hoveredCell.closest('TR');
        let index = 0;

        if (!indicativeRow) {
            index = this._isBottomOrRight() ? -1 : index;
        } else {
            index = this._hoveredCell.cellIndex + (this._isBottomOrRight() ? 1 : 0);
        }

        if (index > -1 && index < this._table._numberOfColumns) {
            this._table.removeColumn(index);
        }
    }

    /**
     * @private
     *
     * if "cntrl + Eneter" is pressed then create new line under current and focus it
     * @param {KeyboardEvent} event
     */
    _containerEnterPressed(event) {
        if (!(this._table.selectedCell !== null && !event.shiftKey)) {
            return;
        }
        const indicativeRow = this._table.selectedCell.closest('TR');
        let index = this._getHoveredSideOfContainer();

        if (index === 1) {
            index = indicativeRow.sectionRowIndex + 1;
        }
        const newstr = this._table.addRow(index);

        newstr.cells[0].click();
    }

    /**
     * @private
     *
     * When the mouse enters the detection area
     * @param {MouseEvent} event
     */
    _mouseEnterInDetectArea(event) {
        const coords = getCoords(this._container);
        const side = getSideByCoords(coords, event.pageX, event.pageY);

        event.target.dispatchEvent(
            new CustomEvent('mouseInActivatingArea', {
                detail: {
                    side,
                },
                bubbles: true,
            })
        );
    }
}
