import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Card, CardBody, Button, Input, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createComment, getComments } from '../utils/api';
import Loading from '../pages/loading';

const Comments = (props) => {
    const { commentObj, commentTaskID, commentTaskLink } = props;
    const [comments, setComments] = useState([]);
    const [commentCount, setCommentCount] = useState(0);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const [commentSubmitting, setCommentSubmitting] = useState(false);

    useEffect(() => {
        const fetchComments = async () => {
            const data = await getComments(commentTaskID);
            setWidgetLoading(false);
            setComments(data);
        };
        fetchComments();
    }, [commentObj]);

    useEffect(() => {
        let count = 0;
        const countIt = (comment) => {
            count++;
            if (comment.replies && comment.replies.length) {
                comment.replies.map(countIt);
            }
        };
        comments.map(countIt);
        setCommentCount(count);
    }, [comments]);

    const submitComment = async (text, replyId) => {
        setCommentSubmitting(true);
        window.setTimeout(async () => {
            const newComments = await createComment(commentTaskID, text, replyId, false);
            setComments(newComments);
            setCommentSubmitting(false);
        }, 100);
    };

    const RenderComment = (comment, isReply, parentId) => {
        let showReplyDiv = false;
        const showReply = (state) => {
            showReplyDiv = state || !showReplyDiv;
            document.getElementById(`replyDiv:${comment.id}`).style.display = showReplyDiv ? 'block' : 'none';
            document.getElementById(`replyInput:${comment.id}`).value = '';
            if (showReplyDiv) {
                document.getElementById(`replyInput:${comment.id}`).focus();
            }
        };

        // So why no useState or hooks? Because it changes to the number of hooks and states used and I can't figure it out quick enough :)

        return (
            <Fragment key={`comment:${comment.id}`}>
                <div className={`d-flex comment${isReply ? '-reply' : ''}`}>
                    <div>
                        <div
                            className="rounded-circle comment-circle"
                            style={{
                                backgroundImage: `url(${comment.ownerAvatarUrl})`,
                            }}
                        />
                    </div>
                    <div className="text-secondary pl-2 w-100">
                        <h6 className="mb-0 ">{comment.ownerName}</h6>
                        <p className="mb-0" style={{ lineHeight: 1.25 }}>
                            {comment.noteText}
                        </p>
                        <small style={{ fontSize: '.8rem' }}>
                            {comment.lastUpdateDate ? moment(comment.lastUpdateDate,'YYYY-MM-DDTHH:mm:ss:SSS[Z]').format('YYYY-MM-DD HH:mm') : ''}
                            <span className="mx-1">&middot;</span>{' '}
                            <Button className="align-baseline p-0 m-0" size="sm" color="link" onClick={showReply}>
                                Reply
                            </Button>
                        </small>
                        <div className="mt-2" id={`replyDiv:${comment.id}`} style={{ display: 'none' }}>
                            <Input type="textarea" bsSize="sm" id={`replyInput:${comment.id}`} className="mb-2" />
                            <Button
                                size="sm"
                                outline
                                color="primary"
                                onClick={() => {
                                    submitComment(document.getElementById(`replyInput:${comment.id}`).value.trim(), parentId || comment.id);
                                    showReply(false);
                                }}
                            >
                                Comment
                            </Button>
                            <Button size="sm" color="link" onClick={() => showReply(false)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
                {(comment.replies || []).map((reply) => RenderComment(reply, true, comment.id))}
            </Fragment>
        );
    };

    const commentText = useRef(null);

    return (
        <Card>
            <CardBody>
                <div
                    className="d-flex justify-content-center align-items-center mb-3 clickable no-outline"
                    onClick={() => window.open(commentTaskLink)}
                    onKeyDown={() => window.open(commentTaskLink)}
                    role="button"
                    tabIndex={0}
                >
                    <div className="w-100 align-items-center justify-content-center">
                        <h4 className="mb-0 d-inline-block mr-2">Comments</h4>
                        <Badge color="primary" className="align-text-top">
                            {commentCount}
                        </Badge>
                    </div>
                    <div className="hover-primary">
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                    </div>
                </div>
                {widgetLoading ? (
                    <div style={{ height: '150px', position: 'relative' }}>
                        <Loading />
                    </div>
                ) : null}
                {comments.map((comment) => RenderComment(comment))}
                <div id="add-comment">
                    <h6 className="mt-0">Add Comment</h6>
                    <div className="mt-2" style={{ position: 'relative' }}>
                        {commentSubmitting ? (
                            <Loading style={{ position: 'absolute', backgroundColor: 'white', opacity: '0.9', zIndex: '2' }} />
                        ) : null}
                        <Input type="textarea" bsSize="sm" innerRef={commentText} className="mb-2" />
                        <Button
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                                submitComment(commentText.current.value.trim());
                                commentText.current.value = '';
                            }}
                        >
                            Comment
                        </Button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

Comments.propTypes = {
    commentObj: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    commentTaskID: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    commentTaskLink: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default Comments;
