import React, { useState } from 'react';
import { Card, CardBody, Button, PopoverHeader, PopoverBody, Popover } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { unlockCampaign } from '../utils/api';

const CanUnlockButton = (props) => {
    const { fetchSubTab, fetchCampaigns, campaignId, setLoading } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);

    const submitUnlock = () => {
        setLoading(true);
        window.setTimeout(async () => {
            await unlockCampaign(campaignId);
            await fetchCampaigns();
            await fetchSubTab();
            setLoading(false);
        }, 100);
    };

    return (
        <Card>
            <CardBody>
                <div className="w-100">
                    <h4 className="mb-0">Locked Campaign</h4>
                </div>
                <br />
                <div>This campaign is locked.</div>

                <Button onClick={toggle} color="primary" outline size="sm" id="UnlockCampaign" className="mt-3">
                    <span>Unlock</span>
                </Button>

                <Popover placement="bottom" isOpen={popoverOpen} target="UnlockCampaign" toggle={toggle}>
                    <PopoverHeader>Are you sure?</PopoverHeader>
                    <PopoverBody>
                        Are you sure you want to unlock this campaign? By doing this ALL pending approvals will be revoked.
                        <div className="text-center my-3">
                            <Button
                                className="mr-5"
                                color="success"
                                onClick={() => {
                                    submitUnlock();
                                }}
                            >
                                <FontAwesomeIcon icon={['fa', 'check']} />
                                <span>Yes</span>
                            </Button>
                            <Button color="danger" onClick={toggle}>
                                <FontAwesomeIcon icon={['fa', 'ban']} />
                                <span>No</span>
                            </Button>
                        </div>
                    </PopoverBody>
                </Popover>
            </CardBody>
        </Card>
    );
};
CanUnlockButton.propTypes = {
    fetchSubTab: PropTypes.func,
    fetchCampaigns: PropTypes.func,
    setLoading: PropTypes.func,
    campaignId: PropTypes.string,
};
CanUnlockButton.defaultProps = {
    fetchSubTab: () => {},
    fetchCampaigns: () => {},
    setLoading: () => {},
    campaignId: '',
};

export default CanUnlockButton;
